import {
  RenewalFrequency,
  RenewalStatus,
  SubscriptionDiscountType,
  SubscriptionSource,
  SubscriptionStatus
} from '@speechifyinc/multiplatform-sdk/api/services';
import type { IUser } from 'interfaces';
import { isEmpty } from 'lodash';

import { LIFETIME_SUBSCRIPTION_PLAN_NAMES } from 'utils/constants';
import type { Nullable } from 'utils/types';

export const canUpgrade = (user: Nullable<IUser>) => {
  return !hasSubscription(user) || !isPremium(user);
};

export const hasSubscription = (user: Nullable<IUser>): user is Omit<IUser, 'subscription'> & { subscription: Exclude<IUser['subscription'], undefined> } => {
  return !isEmpty(user?.subscription);
};

export const getSubscriptionStatus = (user: Nullable<IUser>) => {
  if (!hasSubscription(user)) return null;
  return typeof user.subscription.status === 'string' ? user.subscription.status : (user.subscription.status.status as Lowercase<SubscriptionStatus['name']>);
};

export const getSubscriptionRenewalStatus = (user: Nullable<IUser>) => {
  if (!hasSubscription(user)) return null;
  return typeof user.subscription.renewalStatus === 'string'
    ? user.subscription.renewalStatus
    : (user.subscription.renewalStatus.status as Lowercase<RenewalStatus['name']>);
};

export const getSubscriptionSource = (user: Nullable<IUser>) => {
  if (!hasSubscription(user)) return null;
  return typeof user.subscription.plan.source === 'string'
    ? user.subscription.plan.source
    : (user.subscription.plan.source.source as Lowercase<SubscriptionSource['name']>);
};

export const getSubscriptionRenewalFrequency = (user: Nullable<IUser>) => {
  if (!hasSubscription(user)) return null;
  return typeof user.subscription.plan.renewalFrequency === 'string'
    ? user.subscription.plan.renewalFrequency
    : (user.subscription.plan.renewalFrequency.frequency as Lowercase<RenewalFrequency['name']>);
};

export const getSubscriptionCurrency = (user: Nullable<IUser>, defaultCurrency = 'usd') => {
  if (!hasSubscription(user)) return defaultCurrency;
  return (typeof user.subscription.status === 'string' ? user.subscription.localCurrency : user.subscription.currency) ?? defaultCurrency;
};

export const isApple = (user: Nullable<IUser>) => {
  return getSubscriptionSource(user) === 'apple';
};

export const isCanceled = (user: Nullable<IUser>) => {
  return getSubscriptionStatus(user) === 'canceled';
};

export const isExpired = (user: Nullable<IUser>) => {
  return getSubscriptionStatus(user) === 'expired';
};

export const isOnTrial = (user: Nullable<IUser>) => {
  return hasSubscription(user) && user.subscription.isOnTrial === true;
};

export const isLifetime = (currentUser: Nullable<IUser>) =>
  currentUser?.subscription?.plan?.name && LIFETIME_SUBSCRIPTION_PLAN_NAMES.includes(currentUser.subscription.plan.name);

export const isPayPal = (user: Nullable<IUser>) => {
  return getSubscriptionSource(user) === 'paypal';
};

export const isPlayStore = (user: Nullable<IUser>) => {
  return getSubscriptionSource(user) === 'play_store';
};

export const isPremium = (user: Nullable<IUser>) => {
  return hasSubscription(user) && user.entitlements?.isPremium === true;
};

export const isStripe = (user: Nullable<IUser>) => {
  return getSubscriptionSource(user) === 'stripe';
};

export const getSubscriptionDiscountType = (user: Nullable<IUser>) => {
  if (!hasSubscription(user)) return undefined;
  const discount = user.subscription.discounts?.[0];
  if (!discount) return undefined;

  return typeof discount.type === 'string' ? discount.type : (discount.type.status as Lowercase<SubscriptionDiscountType['name']>);
};

export const isAmountDiscountSubscription = (user: Nullable<IUser>) => {
  const discountType = getSubscriptionDiscountType(user);
  return discountType === 'amount';
};

export const isPercentageDiscountSubscription = (user: Nullable<IUser>) => {
  const discountType = getSubscriptionDiscountType(user);
  return discountType === 'percentage';
};

export const getSubscriptionDiscountPercentageValue = (user: Nullable<IUser>) => {
  return hasSubscription(user) && isPercentageDiscountSubscription(user) ? user.subscription.discounts?.[0]?.value : undefined;
};

export const getSubscriptionDiscountAmountValue = (user: Nullable<IUser>) => {
  return hasSubscription(user) && isAmountDiscountSubscription(user) ? user.subscription.discounts?.[0]?.value : undefined;
};

export const isMonthlySubscription = (user: Nullable<IUser>) => getSubscriptionRenewalFrequency(user) === 'monthly';

export const isYearlySubscription = (user: Nullable<IUser>) => {
  const renewalFrequency = getSubscriptionRenewalFrequency(user);
  return renewalFrequency === 'annually' || renewalFrequency === 'yearly';
};
