import { ExtensionSettings, IUser } from 'interfaces';

import * as extension from 'utils/extension';

import { useAuthStore } from '..';

export const setExtensionPlaybackSpeed = async (playbackSpeed: ExtensionSettings['playbackSpeed']) => {
  extension.setStreakGoal(playbackSpeed!);
  useAuthStore.setState(state => ({
    ...state,
    user: {
      ...state.user,
      extensionSettings: {
        ...state.user?.extensionSettings,
        playbackSpeed
      }
    } as IUser
  }));
};
