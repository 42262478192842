export const BASE_FACTOR_SPEED_WPM = 200;

export const MINIMUM_RELATIVE_SPEED = 0.4;
export const MAXIMUM_FREE_RELATIVE_SPEED = 1.5;
export const MAXIMUM_PREMIUM_RELATIVE_SPEED = 4.5;
export const MAXIMUM_FREE_VOICE_RELATIVE_SPEED = 2;
export const INITIAL_PLAYBACK_RELATIVE_SPEED = 1.1;

/** @deprecated let's use relative speeds instead */
export const MINIMUM_WPM = BASE_FACTOR_SPEED_WPM * MINIMUM_RELATIVE_SPEED;
/** @deprecated let's use relative speeds instead */
export const MAXIMUM_FREE_WPM = BASE_FACTOR_SPEED_WPM * MAXIMUM_FREE_RELATIVE_SPEED;
/** @deprecated let's use relative speeds instead */
export const MAXIMUM_PREMIUM_WPM = BASE_FACTOR_SPEED_WPM * MAXIMUM_PREMIUM_RELATIVE_SPEED;
