import type { Entitlements, Subscription } from '@speechifyinc/multiplatform-sdk';

import { logError } from 'lib/observability';
import { PlatformEntitlements } from 'lib/platform';
import { findTtsSubscription, hasStudioSubscription } from 'modules/subscription/utils/subscriptionUtils';
import type { Nullable } from 'utils/types';

import { MultiplatformSDKInstance } from '../sdk';
import { SDKFacade } from './_base';

export type EntitlementsInfo = {
  isPremium: boolean;
  hdWordsLeft: number;
  nextHdWordsGrant: number;
  nextHdWordsGrandDate?: Nullable<Date>;
  lastHdWordsGrantDate?: Nullable<Date>;
};

export class SDKSubscriptionFacade extends SDKFacade {
  private static _singleton: SDKSubscriptionFacade;

  constructor(sdk: MultiplatformSDKInstance) {
    super(sdk);
    SDKSubscriptionFacade._singleton = this;
  }

  static override get singleton(): SDKSubscriptionFacade {
    if (!SDKSubscriptionFacade._singleton) {
      throw new Error('SDKSubscriptionFacade is not initialized');
    }
    return SDKSubscriptionFacade._singleton;
  }

  logHdWordsListened = this.sdk.promisify(this.sdk.client.subscriptionService.logHdWordsListened.bind(this.sdk.client.subscriptionService));

  public mapSDKEntitlementsToEntitlementsInfo = (entitlements: Entitlements | PlatformEntitlements | undefined): EntitlementsInfo => {
    if (!entitlements) {
      return {
        isPremium: false,
        hdWordsLeft: 0,
        nextHdWordsGrant: 0,
        nextHdWordsGrandDate: null,
        lastHdWordsGrantDate: null
      };
    }

    // Check for sdk entitlements
    if ('hashCode' in entitlements) {
      return {
        isPremium: entitlements.isPremium,
        hdWordsLeft: entitlements.hdWordsLeft,
        nextHdWordsGrant: entitlements.nextHDWordsGrant,
        nextHdWordsGrandDate: entitlements.nextHDWordsGrantDate ? new Date(entitlements.nextHDWordsGrantDate) : null,
        lastHdWordsGrantDate: entitlements.lastHdWordsGrantDate ? new Date(entitlements.lastHdWordsGrantDate) : null
      };
    }

    return {
      isPremium: entitlements.isPremium,
      hdWordsLeft: entitlements.hdWordsLeft,
      nextHdWordsGrant: entitlements.nextHdWordsGrant,
      nextHdWordsGrandDate: entitlements.nextHdWordsGrantDate ? new Date(entitlements.nextHdWordsGrantDate) : null,
      lastHdWordsGrantDate: entitlements.lastHdWordsGrantDate ? new Date(entitlements.lastHdWordsGrantDate) : null
    };
  };

  public listenToEntitlementsChange = (callback: (entitlements: Entitlements) => void) => {
    return this.sdk.client.subscriptionService.addEntitlementsChangeListener(result => {
      if (result instanceof this.sdk.sdkModule.Result.Success) {
        callback(result.value);
      } else if (result instanceof this.sdk.sdkModule.Result.Failure) {
        logError(new Error(`Failed to listen to entitlements change: ${result.error}`));
        return;
      }
    });
  };

  public listenToSubscriptionChange = (
    callback: (
      change:
        | {
            hasStudioSubscription: boolean;
          }
        | {
            ttsSubscription: Subscription;
          }
    ) => void
  ) => {
    return this.sdk.client.subscriptionService.addSubscriptionChangeListener(result => {
      result
        .ifSuccessful(subscriptions => {
          const ttsSubscription = findTtsSubscription<Subscription>(subscriptions ?? []);

          if (ttsSubscription) {
            callback({ ttsSubscription });
          } else if (hasStudioSubscription(subscriptions ?? [])) {
            callback({ hasStudioSubscription: true });
          }
        })
        .mapFailure(error => {
          logError(new Error(`Failed to listen to subscription change: ${error}`));
          return error;
        });
    });
  };

  public fetchSubscriptions = async (): Promise<{
    subscription: Subscription | undefined;
    entitlements: Entitlements | undefined;
    hasStudioSubscription: boolean;
  }> => {
    const sdkGetAllSubscriptions = this.sdk.promisify(this.sdk.client.subscriptionService.getAllSubscriptions.bind(this.sdk.client.subscriptionService));
    const sdkSubscriptionAndEntitlements = await sdkGetAllSubscriptions();

    if (!sdkSubscriptionAndEntitlements) {
      return {
        subscription: undefined,
        entitlements: undefined,
        hasStudioSubscription: false
      };
    }
    return {
      subscription: findTtsSubscription(sdkSubscriptionAndEntitlements?.subscriptions),
      entitlements: sdkSubscriptionAndEntitlements?.entitlements,
      hasStudioSubscription: hasStudioSubscription(sdkSubscriptionAndEntitlements?.subscriptions)
    };
  };

  public getBillingDashboardUrl = async (id: string) => {
    const sdkGetBillingDashboardUrl = this.sdk.promisify(this.sdk.client.subscriptionService.getBillingDashboardUrl.bind(this.sdk.client.subscriptionService));
    const parameters = new this.sdk.sdkModule.BillingDashboardOptions(id);
    return sdkGetBillingDashboardUrl(parameters);
  };
}
