import type { ExtensionSettings } from 'interfaces';

import { emitUserUpdate } from './emitUserUpdate';
import { useAuthStore } from '..';

export const setExtensionSettings = (extensionSettings: ExtensionSettings) => {
  useAuthStore.setState(state => {
    if (!state.user) return state;
    return {
      user: {
        ...state.user,
        extensionSettings
      }
    };
  });
  emitUserUpdate();
};
