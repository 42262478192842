import { signInAnonymously } from 'firebase/auth';

import { firebaseAuth } from 'lib/firebase/firebase.client';

import { getSessionCookie } from '../utils/cookies';

export const createAnonymousUser = async () => {
  const { user } = await signInAnonymously(firebaseAuth);
  await getSessionCookie(await user.getIdToken());
  return user;
};
