import { Buffer } from 'buffer';

import { OCRTextContent } from '@speechifyinc/multiplatform-sdk/api/adapters/ocr';
import { BoundingBox, CoordinateTransform, Viewport } from '@speechifyinc/multiplatform-sdk/api/util/images';
import axios from 'axios';

import { auth } from 'lib/speechify';
import { getRequiredStringEnv } from 'utils/safeEnvParsing';

const OCR_API_URL = getRequiredStringEnv('NEXT_PUBLIC_OCR_API_URL');

interface Vertex {
  x: number;
  y: number;
}

interface DetectedLanguage {
  confidence: number;
  languageCode: string;
}

interface Property {
  detectedLanguages: DetectedLanguage[];
}

interface Block {
  property?: Property;
  boundingBox: {
    vertices: Vertex[];
  };
  paragraphs: Paragraph[];
  blockType: string;
}

interface Paragraph extends Omit<Block, 'paragraphs'> {
  property: Property;
  words: Word[];
}

interface Word extends Omit<Block, 'paragraphs'> {
  property: Property;
  // eslint-disable-next-line
  symbols: Symbol[];
  boundingBox: {
    vertices: Vertex[];
  };
}

interface Symbol extends Omit<Block, 'paragraphs'> {
  property: {
    detectedLanguages: DetectedLanguage[];
    detectedBreak: {
      type: string;
    };
  };
  text: string;
}

interface Page {
  blocks: Block[];
  width: number;
  height: number;
  property: {
    detectedLanguages: DetectedLanguage[];
  };
}

export interface BoundingPoly {
  vertices: Vertex[];
}

interface TextAnnotation {
  boundingPoly: BoundingPoly;
  description: string;
  locale?: string;
}

export type OCRResponseOrEmptyOrError = OCRResponse & {
  error?: Status;
};

/**
 * See https://cloud.google.com/vision/docs/reference/rest/v1/AnnotateImageResponse
 */
export type OCRResponse = {
  fullTextAnnotation: {
    text: string;
    pages: Page[];
  };
  textAnnotations: TextAnnotation[];
  /** Some errors may still be there, as per [_"Note that filled-in image annotations are guaranteed to be correct, even when error is set."](https://cloud.google.com/vision/docs/reference/rest/v1/AnnotateImageResponse)_ */
  error?: Status;
};

/**
 * See https://cloud.google.com/vision/docs/reference/rest/v1/Status
 */
export type Status = {
  code: number;
  message: string;
  details: Array<
    {
      type: string;
    } & Record<string, unknown>
  >;
};

export const runOcrFromFile = async (img: ArrayBuffer): Promise<OCRResponse | null> =>
  await getResponseOrNullThrowingOnError(Buffer.from(img).toString('base64'));

async function getResponseOrNullThrowingOnError(content: string): Promise<OCRResponse | null> {
  const idToken = await auth.currentUser?.getIdToken();
  const ocr = await axios.post(
    OCR_API_URL,
    { content },
    {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${idToken}`
      }
    }
  );
  const response: OCRResponseOrEmptyOrError = ocr.data[0];

  if (!response.fullTextAnnotation && !response.textAnnotations) {
    if (response.error)
      throw Error(
        `Google Cloud Vision Error: code=${response.error.code}, message=${response.error.message}, details=${JSON.stringify(response.error.details)}`
      );
    else return null;
  }

  return response;
}

export const getWidth = (poly: BoundingPoly): number => {
  const max = Math.max(...poly.vertices.map(v => v.x));
  const min = Math.min(...poly.vertices.map(v => v.x));
  return max - min;
};

export const getHeight = (poly: BoundingPoly): number => {
  const max = Math.max(...poly.vertices.map(v => v.y));
  const min = Math.min(...poly.vertices.map(v => v.y));
  return max - min;
};

export const ocrResponseToOCRTextContent = (response: OCRResponse): Array<OCRTextContent> => {
  const page = response.fullTextAnnotation.pages[0];
  const result: OCRTextContent[] = response.textAnnotations?.slice(1).map(annotation => {
    const wordHeight = getHeight(annotation.boundingPoly);
    const wordWidth = getWidth(annotation.boundingPoly);
    const { x, y } = annotation.boundingPoly.vertices[3];

    const transform = new CoordinateTransform(wordHeight, 0, 0, wordHeight, x, page.height - y);
    const boundingBox: BoundingBox = BoundingBox.Companion.fromTransformedBoxInFlippedViewport(
      wordWidth,
      wordHeight,
      transform,
      new Viewport(page.width, page.height)
    );
    return new OCRTextContent(boundingBox, annotation.description);
  });
  return result;
};
