import React from 'react';

export default function DramaIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M56 17C46.2429 19.3578 40.6886 19.0042 30.6185 15.161L29.2392 34.1971C28.7314 41.206 34.0015 47.2995 41.0105 47.8074C48.0194 48.3152 54.1129 43.045 54.6207 36.0361L56 17Z"
        fill="#3B4EFF"
      />
      <path
        d="M0 17C9.75707 19.3578 15.3114 19.0042 25.3815 15.161L26.7608 34.1971C27.2686 41.206 21.9985 47.2995 14.9895 47.8074C7.98063 48.3152 1.8871 43.045 1.37927 36.0361L0 17Z"
        fill="#3B4EFF"
      />
      <path
        d="M11 7C23.7743 11.0359 31.2099 11.1002 45 7V26.3575C45 33.9918 41.3679 41.1706 35.2174 45.6931L28 51L20.7826 45.6931C14.6321 41.1706 11 33.9918 11 26.3575L11 7Z"
        fill="#FF4B40"
      />
      <path d="M28 31.4998L28 36.2647C25.8943 36.2647 22.0104 36.824 20.0104 38C20.4104 34.7607 23.3876 31.4998 28 31.4998Z" fill="white" />
      <path d="M28 31.4998L28 36.2647C30.1057 36.2647 33.9896 36.824 35.9896 38C35.5896 34.7607 32.6124 31.4998 28 31.4998Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2404 23.6658C23.1582 23.6842 25.6812 21.3125 25.6291 18.5367C25.6271 18.4277 25.6213 18.3195 25.6118 18.2124C23.6835 18.2304 21.6973 18.7452 19.8639 19.8037C18.6096 20.5279 17.5398 21.4413 16.6755 22.478C17.6611 23.2162 18.9189 23.6574 20.2404 23.6658Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.7596 23.6658C32.8418 23.6842 30.3188 21.3125 30.3709 18.5367C30.3729 18.4277 30.3787 18.3195 30.3882 18.2124C32.3165 18.2304 34.3027 18.7452 36.1361 19.8037C37.3904 20.5279 38.4602 21.4413 39.3245 22.478C38.3389 23.2162 37.0811 23.6574 35.7596 23.6658Z"
        fill="white"
      />
    </svg>
  );
}
