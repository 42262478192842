import type { Entitlements } from '@speechifyinc/multiplatform-sdk';

import type { PlatformEntitlements } from 'lib/platform';

import { useAuthStore } from '../';
import { emitUserUpdate } from './emitUserUpdate';

export const setEntitlements = (entitlements: Entitlements | PlatformEntitlements) => {
  useAuthStore.setState(state => {
    if (!state.user) return state;
    return {
      user: {
        ...state.user,
        entitlements
      }
    };
  });
  emitUserUpdate();
};
