import { createIndexedDbStore } from 'lib/zustand/store';
import {
  LibraryFilterType,
  LibraryFolderSubscriptionConfig,
  LibraryItemToAction,
  LibraryShowFolderType,
  LibrarySortBy,
  LibrarySortOrder
} from 'modules/library/constants';
import { LibraryViewType } from 'modules/library/constants/view';
import type { LibraryFolderSubscription } from 'modules/sdk/lib/facade/library';

import { ILibraryItem } from '../../types/item';
import { SearchState } from './actions/search';

export enum LoadingState {
  NONE = 'none',
  LOADING = 'loading',
  LOADING_MORE = 'loadingMore',
  SEARCHING = 'searching'
}

type LibraryFolderSubscriptionState = LibraryFolderSubscription & {
  key: LibraryFolderSubscriptionConfig;
};

export type LibraryStoreState = {
  selectedView: LibraryViewType;
  selectedFilter: LibraryFilterType;
  selectedSortBy: LibrarySortBy;
  selectedSortOrder: LibrarySortOrder;
  itemsByFolderId: Record<string, ILibraryItem[]>;
  subscriptionsByFolderId: Record<string, LibraryFolderSubscriptionState>;
  folderListByFolderId: Record<
    string,
    {
      isOpen: boolean;
      isLoading: boolean;
      items: ILibraryItem[];
    }
  >;
  isPrefetchingByFolderId: Record<string, boolean>;
  currentFolderId: string;
  searchState: SearchState;
  loadingState: LoadingState;
  isCreatingFolder: boolean;
  itemToRename: ILibraryItem | null;
  itemsToDelete: LibraryItemToAction[];
  itemsToMove: LibraryItemToAction[];
  selectedItems: string[];
  isFeedbackDialogOpen: boolean;
  shareRecord: ILibraryItem | null;
  showFolderType: LibraryShowFolderType;
  userRecordItemsCount: number;
};

export type PersistedLibraryStoreState = Pick<
  LibraryStoreState,
  'itemsByFolderId' | 'selectedView' | 'selectedFilter' | 'selectedSortBy' | 'selectedSortOrder' | 'showFolderType'
>;

export const useLibraryStore = createIndexedDbStore<LibraryStoreState, PersistedLibraryStoreState>(
  () => ({
    selectedView: LibraryViewType.grid,
    selectedFilter: LibraryFilterType.ANY,
    selectedSortBy: LibrarySortBy.DATE_ADDED,
    selectedSortOrder: LibrarySortOrder.DESC,
    selectedItems: [],
    itemsByFolderId: {},
    subscriptionsByFolderId: {},
    folderListByFolderId: {},
    isPrefetchingByFolderId: {},
    currentFolderId: '',
    loadingState: LoadingState.LOADING,
    isCreatingFolder: false,
    itemToRename: null,
    shareRecord: null,
    showFolderType: LibraryShowFolderType.TOP,
    itemsToDelete: [],
    itemsToMove: [],
    searchState: {
      query: '',
      results: [],
      hasMoreItems: true
    },
    isFeedbackDialogOpen: false,
    userRecordItemsCount: 0
  }),
  {
    storageName: 'speechify-library',
    partialize: state => {
      return {
        itemsByFolderId: state.itemsByFolderId,
        selectedView: state.selectedView,
        selectedFilter: state.selectedFilter,
        selectedSortBy: state.selectedSortBy,
        selectedSortOrder: state.selectedSortOrder,
        showFolderType: state.showFolderType
      };
    }
  }
);
