import 'client-only';

import { EpubVersion } from '@speechifyinc/multiplatform-sdk';

import { initializeAuthStore } from 'modules/auth/store/authStore/actions/initializeAuthStore';
import { initializeSubscriptionStore } from 'modules/subscription/stores/actions/initializeSubscriptionStore';

import packageJson from '../../../../package.json';
import { getRequiredStringEnv } from '../../../utils/safeEnvParsing';
import { ListeningDependenciesFactory } from '../listeningDependencies';
import { SDKBundleFacade, SDKContentFacade, SDKFacade, SDKLibraryFacade, SDKSubscriptionFacade, SDKVoiceFacade } from './facade';
import { SDKAccountFacade } from './facade/account';
import { SDKSingletonClassicReaderFacade } from './facade/classicReader/SDKSingetonClassicReaderFacade';
import { SDKSingletonCommonReaderFacade } from './facade/classicReader/SDKSingetonCommonReaderFacade';

export const importSDK = async () => {
  const sdkModule = await import('@speechifyinc/multiplatform-sdk');
  // TODO(albertusdev): Determine if we need to call setupDiagnosticReporter here
  return sdkModule.default;
};

const initSDK = async () => {
  const [
    sdkModule,
    { firebaseApp, firebaseAuth },
    { WebAdapterFactory },
    { WebBoundaryMap },
    { promisify },
    { authorizationTokenProvider, setAudioServerUser }
  ] = await Promise.all([
    importSDK(),
    import('lib/firebase/firebase.client'),
    import('lib/speechify/adaptors/index'),
    import('lib/speechify/adaptors/boundarymap'),
    import('lib/speechify/adaptors/promisify'),
    import('lib/speechify/adaptors/audioServer')
  ]);
  const { AudioServerConfiguration, AppEnvironment, ClientConfig, ClientOptions, FirebaseDynamicLinksConfig, SpeechifyClientFactory } = sdkModule;

  const platform = new WebAdapterFactory(firebaseApp);

  const clientOptions = new ClientOptions(new AudioServerConfiguration(authorizationTokenProvider))
    .enableLiveQueryViewV2()
    .setEpubVersionForEPubs(EpubVersion.V2);

  const factory = new SpeechifyClientFactory(
    new ClientConfig(
      AppEnvironment.WEB_APP,
      packageJson.version || '0.0.0',
      getRequiredStringEnv('NEXT_PUBLIC_PAYMENT_SERVER_URL'),
      `https://us-central1-${getRequiredStringEnv('NEXT_PUBLIC_FB_PROJECT_ID')}.cloudfunctions.net`,
      getRequiredStringEnv('NEXT_PUBLIC_AUDIO_SERVER_URL'),
      getRequiredStringEnv('NEXT_PUBLIC_VOICES_SERVER').replace(/\/v\d+/, ''), // exclude version
      getRequiredStringEnv('NEXT_PUBLIC_PLATFORM_CATALOG_URL'),
      getRequiredStringEnv('NEXT_PUBLIC_FB_PROJECT_ID'),
      new FirebaseDynamicLinksConfig(
        getRequiredStringEnv('NEXT_PUBLIC_DL_KEY'),
        'https://speechify.page.link',
        'com.cliffweitzman.speechify2',
        'com.cliffweitzman.speechifyMobile2'
      ),
      getRequiredStringEnv('NEXT_PUBLIC_WEBAPP_URL'),
      getRequiredStringEnv('NEXT_PUBLIC_PLATFORM_ML_PAGE_PARSING_URL'),
      clientOptions,
      getRequiredStringEnv('NEXT_PUBLIC_EBOOK_BACKEND_URL'),
      getRequiredStringEnv('NEXT_PUBLIC_DIAGNOSTICS_SERVICE_URL'),
      getRequiredStringEnv('NEXT_PUBLIC_USER_PROFILE_API_URL')
    ),
    platform
  );

  const client = factory.getClient();

  initializeAuthStore({ setAudioServerUser });

  // this should omit the `getCurrentUser` hack in the previous codebase since SDK should only initialize after auth state is stable.
  await firebaseAuth.authStateReady();

  // TODO(albertusdev): Recheck if this assumption is correct and whether we need to assert for currentUser is not null here for this to be returned.

  return {
    platform,
    factory,
    client,
    promisify,
    sdkModule,
    WebBoundaryMap
  };
};
export type MultiplatformSDKInstance = Awaited<ReturnType<typeof initSDK>>;
let sdkInstance: MultiplatformSDKInstance;

let sdkFacade: SDKFacade;

export const getSDK = async (): Promise<SDKFacade> => {
  if (sdkFacade) {
    return sdkFacade;
  }
  sdkInstance = await initSDK();

  sdkFacade = {
    sdk: sdkInstance,
    account: new SDKAccountFacade(sdkInstance),
    content: new SDKContentFacade(sdkInstance),
    library: new SDKLibraryFacade(sdkInstance),
    bundle: new SDKBundleFacade(sdkInstance),
    subscription: new SDKSubscriptionFacade(sdkInstance),
    voice: new SDKVoiceFacade(sdkInstance),
    singletonClassicReader: new SDKSingletonClassicReaderFacade(sdkInstance),
    singletonCommonReader: new SDKSingletonCommonReaderFacade(sdkInstance)
  };

  initializeSubscriptionStore(sdkFacade.subscription);

  // Initialize ListeningDependenciesFactory singleton
  ListeningDependenciesFactory.initialize(sdkFacade);

  return sdkFacade;
};
