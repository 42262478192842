import {
  AbstractFile,
  BinaryContentReadableRandomly,
  BinaryContentReadableSequentially,
  BinaryContentReadableSequentiallyMultiplatformAPI,
  BinaryContentWithMimeTypeFromNative,
  BinaryContentWithMimeTypeFromNativeReadableInChunks,
  BinaryContentWithMimeTypeReadableInChunks,
  Result,
  asyncIterableFromBinaryContentReadableSequentially
} from '@speechifyinc/multiplatform-sdk';
import {
  AbstractAdapterFactory,
  AbstractSqlDriverFactory,
  ArchiveFilesAdapter,
  BlobStorageAdapter,
  BlobStorageKey,
  BrowserIdentityUserAgentProvider,
  EncryptionAdapter,
  EventsTrackerAdapter,
  HTMLParser,
  IndexDbBlobStorage,
  W3CHTMLParser,
  W3CXMLParser,
  WebViewAdapter,
  XMLParser
} from '@speechifyinc/multiplatform-sdk/api/adapters';
import { FirebaseService } from '@speechifyinc/multiplatform-sdk/api/adapters/firebase';
import { HttpClientAdapter } from '@speechifyinc/multiplatform-sdk/api/adapters/http';
import { LocalKeyValueStorageAdapter } from '@speechifyinc/multiplatform-sdk/api/adapters/keyvalue';
import { LocalSpeechSynthesisAdapter } from '@speechifyinc/multiplatform-sdk/api/adapters/localsynthesis';
import { LocalMediaPlayerAdapter } from '@speechifyinc/multiplatform-sdk/api/adapters/mediaplayer';
import { OCRAdapter } from '@speechifyinc/multiplatform-sdk/api/adapters/ocr';
import { OfflineModeStatusProvider } from '@speechifyinc/multiplatform-sdk/api/adapters/offlineMode';
import { PDFAdapterFactory } from '@speechifyinc/multiplatform-sdk/api/adapters/pdf';
import { Nullable } from '@speechifyinc/multiplatform-sdk/multiplatform-sdk-multiplatform-sdk';
import { FirebaseApp } from 'firebase/app';

import { WebAnalyticsEventsTrackerAdapter } from './analytics';
import { WebArchiveFilesAdapter } from './archiveFiles';
import { WebFirebaseService } from './firebase';
import { WebHttpClientAdapter } from './http';
import { WebImageConverter } from './imageConverter';
import { callbackFromAsync } from './lib/callbackFromAsync';
import { Callback } from './lib/typeAliases';
import { WebLocalKeyValueStore } from './localstorage';
import { WebLocalSpeechSynthesisAdapter } from './localsynthesis';
import { WebLocalMediaPlayerAdapter } from './mediaplayer';
import { WebOcrAdapter } from './ocr';
import { WebPDFAdapterFactory } from './pdf';
import { InMemorySqlDriverFactory } from './sqlite/sqlite';
import { createBlobFromAsyncIterableOfUint8Arrays } from './utils/io/createBlobFromAsyncIterable';

export class WebAdapterFactory extends AbstractAdapterFactory {
  constructor(private app: FirebaseApp) {
    super();
  }

  override getBlobStorage(): BlobStorageAdapter {
    if (typeof indexedDB == 'undefined') {
      return new InMemoryBlobStorageAdapter();
    }
    return new IndexDbBlobStorage();
  }

  override getLocalKeyValueStorage(): LocalKeyValueStorageAdapter {
    return new WebLocalKeyValueStore();
  }

  override getHttpClient(): HttpClientAdapter {
    return new WebHttpClientAdapter();
  }

  override getFirebaseService(): FirebaseService {
    return new WebFirebaseService(this.app);
  }

  override getLocalSpeechSynthesis(): LocalSpeechSynthesisAdapter {
    return new WebLocalSpeechSynthesisAdapter();
  }

  override getLocalMediaPlayer(): LocalMediaPlayerAdapter {
    return new WebLocalMediaPlayerAdapter();
  }

  override getPDFAdapterFactory(): PDFAdapterFactory {
    return new WebPDFAdapterFactory();
  }

  override getOcrAdapter(): OCRAdapter {
    return new WebOcrAdapter();
  }

  override getHTMLParser(): HTMLParser {
    return new W3CHTMLParser();
  }

  override getImageConverter() {
    return new WebImageConverter();
  }

  override getBrowserIdentityUserAgentProvider(): BrowserIdentityUserAgentProvider {
    return new BrowserIdentityUserAgentProvider();
  }

  override getArchiveFilesAdapter(): ArchiveFilesAdapter {
    return new WebArchiveFilesAdapter();
  }

  override getXMLParser(): XMLParser {
    return new W3CXMLParser();
  }

  override getSqlDriverFactory(): AbstractSqlDriverFactory {
    return new InMemorySqlDriverFactory();
  }

  override getOfflineModeStatusProvider(): LocalOfflineModeStatusProvider {
    return new LocalOfflineModeStatusProvider();
  }

  override getEventsTrackerAdapter(): EventsTrackerAdapter {
    return new WebAnalyticsEventsTrackerAdapter();
  }

  override getEncryptionAdapter(): EncryptionAdapter {
    // @ts-expect-error TODO enable encryption adapter once ebook is supported on web app
    return undefined;
  }

  override getWebViewAdapter(): WebViewAdapter {
    // @ts-expect-error TODO enable webview adapter once webview is supported on web app
    return undefined;
  }
}

class LocalOfflineModeStatusProvider extends OfflineModeStatusProvider {
  constructor() {
    super();
  }

  override getCurrentValueAndSubscribeToUpdatesAndGetCancel() {
    return () => {};
  }
}

class InMemoryBlobStorageAdapter extends BlobStorageAdapter {
  private blobMap = new Map<string, BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>();

  protected override putBlob = (
    key: BlobStorageKey,
    contentToMove: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
    callback: Callback<BinaryContentReadableRandomly>
  ): void =>
    callbackFromAsync(async () => {
      this.blobMap.set(key.originalKey, contentToMove);
      return contentToMove.binaryContent;
    }, callback);

  protected override putBlobByMove = (
    key: BlobStorageKey,
    contentToMove: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>,
    callback: Callback<BinaryContentReadableRandomly>
  ): void =>
    /**
     * Using [putBlob] because in JavaScript the storage always serializes the payload to new form (unlike on
     * filesystems, where files can be put in a new place just by moving), so here there's no 'move', just copy.
     */
    this.putBlob(key, contentToMove, callback);

  protected override putSequenceGetReadableRandomly = (
    key: BlobStorageKey,
    content: BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableSequentially>,
    callback: Callback<BinaryContentReadableRandomly>
  ): void =>
    callbackFromAsync(async () => {
      const binaryContentToPut = new BinaryContentWithMimeTypeFromNative<BinaryContentReadableRandomly>(
        /* mimeType: */ content.mimeType,
        /* binaryContent: */ new BinaryContentReadableRandomly(
          /* blob: */ await createBlobFromAsyncIterableOfUint8Arrays(content.binaryContent.readableStreamOfBytes, content.mimeType?.fullString)
        )
      );
      this.blobMap.set(key.originalKey, binaryContentToPut);
      return binaryContentToPut.binaryContent;
    }, callback);

  protected override putBytes = (
    key: BlobStorageKey,
    content: BinaryContentWithMimeTypeReadableInChunks<BinaryContentReadableSequentiallyMultiplatformAPI>,
    callback: Callback<void>
  ): void =>
    callbackFromAsync(async () => {
      const binaryContentToPut = new BinaryContentWithMimeTypeFromNative<BinaryContentReadableRandomly>(
        /* mimeType: */ content.mimeType,
        /* binaryContent: */ new BinaryContentReadableRandomly(
          /* blob: */ await createBlobFromAsyncIterableOfUint8Arrays(
            /* asyncIterable: */ asyncIterableFromBinaryContentReadableSequentially(content.binaryContent),
            /* mimeType: */ content.mimeType?.fullString
          )
        )
      );
      this.blobMap.set(key.originalKey, binaryContentToPut);
    }, callback);

  protected override getBlob = (
    key: BlobStorageKey,
    callback: Callback<Nullable<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>>
  ): void =>
    callbackFromAsync(async () => {
      const binaryContent = this.blobMap.get(key.originalKey);
      if (!binaryContent) return new Result.Success(null);

      return new Result.Success(binaryContent);
    }, callback);

  protected override deleteBlob(key: BlobStorageKey, callback: Callback<boolean>): void {
    callback(new Result.Success(this.blobMap.delete(key.originalKey)));
  }
}

// ESLint: 'ArrayFile' is defined but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ArrayFile extends AbstractFile {
  private readonly _contentType: string;

  constructor(
    public readonly data: Int8Array,
    contentType: string
  ) {
    super();
    this._contentType = contentType;
  }

  override get contentType(): string {
    return this._contentType;
  }

  override getSizeInBytes(c: Callback<number>) {
    c(new Result.Success(this.data.length));
  }

  override getBytes(start: number, end: number, callback: Callback<Int8Array>) {
    callback(new Result.Success(this.data.slice(start, end)));
  }
}
