import { getHasHdWords } from './getHasHdWords';
import { getHdWordsGrant } from './getHdWordsGrant';
import { getIsFreeUser } from './getIsFreeUser';
import { getIsLoading } from './getIsLoading';
import { getIsNewUser } from './getIsNewUser';
import { getIsOnTrial } from './getIsOnTrial';
import { getIsPremium } from './getIsPremium';
import { getIsSkipContentUnlocked } from './getIsSkipContentUnlocked';
import { getIsStripe } from './getIsStripe';
import { getMobileAppInstalled } from './getMobileAppInstalled';
import { getNextHdWordsGrantReadableDate } from './getNextHdWordsGrantReadableDate';
import { getShouldSwitchToFree } from './getShouldSwitchToFree';

export const subscriptionStoreSelectors = {
  getHasHdWords,
  getHdWordsGrant,
  getIsFreeUser,
  getIsLoading,
  getIsNewUser,
  getIsOnTrial,
  getIsPremium,
  getMobileAppInstalled,
  getIsSkipContentUnlocked,
  getIsStripe,
  getNextHdWordsGrantReadableDate,
  getShouldSwitchToFree
};
