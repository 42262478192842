import { ApiError } from 'next/dist/server/api-utils';
import { v4 } from 'uuid';

import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import { ContentMetaType } from 'modules/sdk/lib';

import { getRequiredStringEnv, getStringEnvWithDefault } from '../../utils/safeEnvParsing';
import { convertFromApiLength, convertFromApiSummaryFormat, validateChatRequest, validateChatResponse } from './helpers';
import {
  APIMessage,
  ChatMessage,
  ChatRecord,
  ChatResponse,
  CreateChatRequest,
  DocumentType,
  FirebaseTimestamp,
  ListenerAction,
  PartialChatMessageWithId,
  Quiz,
  SummaryFeedback,
  SummaryFormatTuple,
  SummaryFormatType,
  SummaryLengthTuple,
  SummaryLengthType,
  whileCondition
} from './types';

export class APIAskAiFacade {
  private static instance: APIAskAiFacade | null = null;

  private readonly apiBaseUrl: string;
  private chat: ChatRecord | null = null;
  private docId: string = '';
  private docType: DocumentType | null = null;
  private docQuizIds: string[] = [];
  private idToken: string;
  private messagesChangeListener: ((action: ListenerAction, messages?: PartialChatMessageWithId[]) => void) | null = null;

  constructor(idToken: string, apiBaseUrl: string = getRequiredStringEnv('NEXT_PUBLIC_LLM_API_URL')) {
    this.apiBaseUrl = apiBaseUrl;
    this.idToken = idToken;
  }

  async answerQuizQuestion(quizId: string, questionKey: string, answer: number): Promise<void> {
    try {
      const body = { questionKey, answer };

      const response = await this.fetchWithAuth(`/ai/quizzes/${quizId}/answers`, {
        method: 'PUT',
        body: JSON.stringify(body)
      });

      if (!response?.ok) {
        throw new Error('Failed to answer question');
      }
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async createChat(): Promise<ChatResponse> {
    if (!this.docId || !this.docType) {
      throw new Error('No document ID provided');
    }

    try {
      const chatRequest: CreateChatRequest = {
        title: 'Document Chat',
        source: {
          type: 'libraryItem',
          id: this.docId,
          documentType: this.docType
        }
      };

      validateChatRequest(chatRequest);

      const response = await this.fetchWithAuth('/v2/chats', {
        method: 'POST',
        body: JSON.stringify(chatRequest)
      });

      const chatResponse: ChatResponse = await response?.json();

      validateChatResponse(chatResponse);

      this.chat = {
        id: chatResponse.chatId,
        subject: chatResponse.subject
          ? {
              type: chatResponse.subject.type,
              source: {
                type: chatResponse.subject.source.type,
                id: chatResponse.subject.source.id,
                documentType: this.docType
              }
            }
          : {
              type: 'default',
              source: {
                type: 'libraryItem',
                id: this.docId,
                documentType: this.docType
              }
            },
        createdAt: chatResponse.createdAt || '',
        lastActiveAt: chatResponse.lastActiveAt || ''
      };

      this.notifyListeners('create-chat');

      return chatResponse;
    } catch (error) {
      console.error('Failed to create chat:', error);
      throw this.handleError(error);
    }
  }

  async createQuiz(numQuestions: number, pages?: number[]): Promise<Quiz> {
    if (!this.chat?.id) {
      await this.createChat();
    }

    const source = {
      documentType: this.docType,

      id: this.docId,
      type: 'libraryItem'
    };

    const subject = pages
      ? {
          type: 'pages',
          options: {
            source,
            pages
          }
        }
      : {
          type: 'document',
          options: {
            source
          }
        };

    try {
      const quizRequest = {
        subject,
        numQuestions
      };

      const response = await this.fetchWithAuth('/ai/quizzes', {
        method: 'POST',
        body: JSON.stringify(quizRequest)
      });

      if (!response || !response.ok) {
        throw new Error('Failed to create quiz');
      }

      const quiz: Quiz = await response.json();

      const quizChatMessage: ChatMessage = {
        id: quiz.id,
        createdAt: quiz.createdAt,
        type: 'quiz',
        message: {
          id: quiz.id,
          questions: quiz.questions,
          attempts: quiz.attempts
        }
      };

      this.notifyListeners('create-quiz', [quizChatMessage]);
      return quiz;
    } catch (error) {
      console.error('Failed to create quiz:', error);
      throw this.handleError(error);
    }
  }

  async deleteChat(): Promise<void> {
    if (!this.chat?.id) {
      return;
    }

    try {
      await this.fetchWithAuth(
        `/ai/chats/${this.chat.id}`,
        {
          method: 'DELETE'
        },
        'text/plain;charset=UTF-8'
      );

      // delete any quizzes associated with this chat
      await this.deleteChatQuizzes();

      this.chat = null;

      if (this.docId) {
        await this.initializeChatForDocument(this.docId);
      }

      this.notifyListeners('delete-chat');
    } catch (error) {
      console.error('Failed to delete chat:', error);
      throw this.handleError(error);
    }
  }

  async deleteChatQuizzes(): Promise<void> {
    if (this.docQuizIds.length === 0) {
      return;
    }

    try {
      await Promise.all(
        this.docQuizIds.map(async quizId => {
          await this.fetchWithAuth(`/ai/quizzes/${quizId}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'text/plain;charset=UTF-8'
            }
          });
        })
      );

      this.notifyListeners(
        'delete-quizzes',
        this.docQuizIds.map(id => ({ id }))
      );

      this.docQuizIds = [];
    } catch (error) {
      console.error('Failed to delete all quizzes:', error);
      throw this.handleError(error);
    }
  }

  async deleteQuiz(quizId: string): Promise<void> {
    if (!quizId) {
      return;
    }

    try {
      await this.fetchWithAuth(`/ai/quizzes/${quizId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8'
        }
      });

      this.docQuizIds = this.docQuizIds.filter(id => id !== quizId);

      this.notifyListeners('delete-quiz', [{ id: quizId }]);
    } catch (error) {
      console.error('Failed to delete quiz:', error);
      throw this.handleError(error);
    }
  }

  destroy(): void {
    this.chat = null;
    this.docId = '';
    this.docType = null;
    this.docQuizIds = [];
    this.messagesChangeListener = null;

    APIAskAiFacade.instance = null;
  }

  public static getInstance(idToken: string, apiBaseUrl?: string): APIAskAiFacade {
    if (!APIAskAiFacade.instance) {
      APIAskAiFacade.instance = new APIAskAiFacade(idToken, apiBaseUrl);
    } else {
      APIAskAiFacade.instance.updateIdToken(idToken);
    }

    return APIAskAiFacade.instance;
  }

  async initializeChatForDocument(docId: string, since: number = 0): Promise<void> {
    this.docId = docId;
    this.docQuizIds = [];

    const currentListenableContent = usePlaybackStore.getState().currentListenableContent;

    switch (currentListenableContent?.metaType) {
      case ContentMetaType.EPUB:
        this.docType = 'EPUB';
        break;

      case ContentMetaType.HTML:
        this.docType = 'HTML';
        break;

      case ContentMetaType.PDF:
        this.docType = 'PDF';
        break;

      case ContentMetaType.SCAN:
        this.docType = 'SCAN';
        break;

      case ContentMetaType.TXT:
        this.docType = 'TXT';
        break;

      default:
        this.docType = null;
    }

    try {
      let docChatMessages: ChatMessage[] = [];

      const docChatResponse = await this.fetchWithAuth(`/ai/chats/lookup/libraryitem/${docId}`);
      const chat: ChatRecord | null = await docChatResponse?.json();

      if (chat) {
        this.chat = chat;

        const docMessages: APIMessage[] = await this.fetchWithAuth(`/ai/chats/${chat.id}/messages?since=${since}`)
          .then(res => res?.json() ?? [])
          .catch(() => []);

        docChatMessages = this.transformMessages(docMessages);

        if (docMessages.some((m: APIMessage) => m.message.type === 'reference' && m.message.referenceEntity === 'quiz')) {
          const quizzes: Quiz[] = await this.fetchWithAuth(`/ai/quizzes/lookup/libraryItem/${docId}`)
            .then(res => res?.json() ?? [])
            .catch(() => []);

          docChatMessages = this.mergeQuizMessages(docChatMessages, quizzes);
        }
      }

      this.notifyListeners(since === 0 ? 'init' : 'update-messages-and-delete-temp-messages', docChatMessages);
    } catch (error) {
      console.error('Failed to initialize chat:', error);
      throw this.handleError(error);
    }
  }

  private mergeQuizMessages(chatMessages: ChatMessage[], quizzes: Quiz[]): ChatMessage[] {
    const messageMap = new Map(chatMessages.map((msg, i) => [msg.id, i]));

    quizzes.forEach(quiz => {
      this.docQuizIds.push(quiz.id);

      const quizMessage: ChatMessage = {
        id: quiz.id,
        createdAt: quiz.createdAt,
        type: 'quiz',
        message: {
          id: quiz.id,
          questions: quiz.questions,
          attempts: quiz.attempts
        }
      };

      const idx = messageMap.get(quiz.id);

      if (typeof idx !== 'undefined') {
        chatMessages[idx] = quizMessage;
      } else {
        chatMessages.push(quizMessage);
        messageMap.set(quiz.id, chatMessages.length - 1);
      }
    });

    return chatMessages;
  }

  async refreshQuiz(quizId: string): Promise<void> {
    if (!quizId) return;

    try {
      const response = await this.fetchWithAuth(`/ai/quizzes/${quizId}`, { method: 'GET' });
      if (!response?.ok) throw new Error('Failed to refresh quiz');

      const updatedQuiz: Quiz = await response.json();

      const quizMessage: ChatMessage = {
        id: updatedQuiz.id,
        createdAt: updatedQuiz.createdAt,
        type: 'quiz',
        message: {
          id: updatedQuiz.id,
          questions: updatedQuiz.questions,
          attempts: updatedQuiz.attempts
        }
      };

      this.notifyListeners('refresh-quiz', [quizMessage]);
    } catch (error) {
      console.error('Failed to refresh quiz:', error);
      throw this.handleError(error);
    }
  }

  async retakeQuiz(quizId: string): Promise<Quiz> {
    if (!quizId) {
      throw new Error('Quiz ID is required to retake the quiz');
    }

    try {
      const response = await this.fetchWithAuth(`/ai/quizzes/${quizId}/retake`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8'
        }
      });

      if (!response?.ok) {
        throw new Error('Failed to retake quiz');
      }

      const quiz: Quiz = await response.json();

      const quizMessage: ChatMessage = {
        id: quiz.id,
        createdAt: quiz.createdAt,
        type: 'quiz',
        message: {
          id: quiz.id,
          questions: quiz.questions,
          attempts: quiz.attempts
        }
      };

      this.notifyListeners('retake-quiz', [quizMessage]);
      return quiz;
    } catch (error) {
      console.error('Failed to retake quiz:', error);
      throw this.handleError(error);
    }
  }

  async regenerateMessage(messageToRegenerate: ChatMessage): Promise<void> {
    if (!this.chat?.id) {
      await this.createChat();
    }

    if (messageToRegenerate.type !== 'summary') return;

    try {
      const response = await this.fetchWithAuth(`/v2/chats/${this.chat?.id}/messages/${messageToRegenerate.id}/regenerate`, { method: 'GET' });

      const reader = response?.body?.getReader();

      if (!reader) {
        throw new Error('No response body received from API');
      }

      // stream

      await this.readStreamedResponse(reader, (responseText: string) => {
        messageToRegenerate.message.streaming = true;
        messageToRegenerate.message.body = responseText;

        this.notifyListeners('regenerate-message', [messageToRegenerate]);
      });

      messageToRegenerate.message.streaming = false;

      this.notifyListeners('regenerate-message', [messageToRegenerate]);
    } catch (error) {
      console.error('Failed to regenerate message:', error);
      throw this.handleError(error);
    }
  }

  async sendMessage(message: string): Promise<void> {
    if (!this.chat?.id) {
      await this.createChat();
    }

    try {
      const userMessage: ChatMessage = {
        id: `${v4()}-temp`,
        createdAt: this.createFirebaseTimestamp(),
        type: 'user',
        message: {
          body: message,
          loading: true
        }
      };

      this.notifyListeners('new-messages', [userMessage]);

      // send message

      const response = await this.fetchWithAuth(`/v2/chats/${this.chat?.id}/message?response=markdown&text=${encodeURIComponent(message)}`, { method: 'GET' });

      const reader = response?.body?.getReader();

      if (!reader) {
        throw new Error('No response body received from API');
      }

      const replyMessage: ChatMessage = {
        id: `${v4()}-temp`,
        createdAt: this.createFirebaseTimestamp(),
        type: 'reply',
        message: {
          body: ''
        }
      };

      // stream response

      await this.readStreamedResponse(reader, (responseText: string, streamingMessageId: string) => {
        if (userMessage.message.loading === true) {
          replyMessage.message.streaming = true;
          userMessage.message.loading = false;
        }

        replyMessage.id = streamingMessageId;
        replyMessage.message.body = responseText;

        this.notifyListeners('update-messages', [replyMessage, userMessage]);
      });

      // finalize

      replyMessage.message.streaming = false;
      this.notifyListeners('update-messages', [replyMessage]);
    } catch (error) {
      console.error('Failed to send message:', error);
      throw this.handleError(error);
    }
  }

  setMessagesChangeListener(listener: (action: ListenerAction, messages?: PartialChatMessageWithId[]) => void): void {
    this.messagesChangeListener = listener;
  }

  async summarizeDocument(summaryLength: SummaryLengthTuple, summaryFormat: SummaryFormatTuple, onStreamingStart?: () => void): Promise<void> {
    return this.summarizePages([], summaryLength, summaryFormat, onStreamingStart);
  }

  async summarizePages(
    pageNumbersForSummary: number[],
    summaryLength: SummaryLengthTuple,
    summaryFormat: SummaryFormatTuple,
    onStreamingStart?: () => void
  ): Promise<void> {
    if (!this.chat?.id) {
      await this.createChat();
    }

    try {
      const apiFormat: SummaryFormatType = summaryFormat === 'keypoints' ? 'Outline' : 'Paragraph';
      const apiLength: SummaryLengthType = (summaryLength.charAt(0).toUpperCase() + summaryLength.slice(1)) as SummaryLengthType;

      const createdAtTemp = this.createFirebaseTimestamp();

      // add loading message
      const loadingMessage = {
        id: v4(),
        createdAt: createdAtTemp,
        type: 'summary_loading' as const,
        options: {
          mode: summaryFormat,
          length: summaryLength,
          pageIndexes: pageNumbersForSummary
        }
      } satisfies ChatMessage;

      this.notifyListeners('new-messages', [loadingMessage]);

      const queryParams = new URLSearchParams({
        format: apiFormat,
        length: apiLength,
        pageIndexes: JSON.stringify(pageNumbersForSummary),
        response: 'markdown'
      });

      // request summary

      const response = await this.fetchWithAuth(`/v2/chats/${this.chat?.id}/summary?${queryParams}`, { method: 'GET' });

      const reader = response?.body?.getReader();

      if (!reader) {
        throw new Error('No response body received from API');
      }

      const messageId = v4();

      const summaryMessage: ChatMessage = {
        id: messageId,
        createdAt: createdAtTemp,
        type: 'summary',
        message: {
          body: '',
          length: summaryLength,
          mode: summaryFormat,
          pageIndexes: pageNumbersForSummary,
          feedback: null,
          setFeedback: async (feedback: SummaryFeedback): Promise<void> => {
            if (!this.chat?.id) {
              throw new Error('Cannot set feedback: No active chat');
            }

            await this.provideFeedback(this.chat.id, messageId, feedback);
          },
          streaming: false
        }
      };

      // stream summary
      const isLoading = true;

      await this.readStreamedResponse(reader, (summaryText: string, streamingMessageId: string, streamingMessageCreatedAt: FirebaseTimestamp) => {
        if (onStreamingStart) {
          onStreamingStart();
        }

        if (isLoading) {
          this.notifyListeners('delete-summary-loading-messages');
          summaryMessage.message.streaming = true;
          this.notifyListeners('new-messages', [summaryMessage]);
        }

        summaryMessage.createdAt = streamingMessageCreatedAt;
        summaryMessage.id = streamingMessageId;
        summaryMessage.message.body = summaryText;

        summaryMessage.message.setFeedback = async (feedback: SummaryFeedback): Promise<void> => {
          if (!this.chat?.id) {
            throw new Error('Cannot set feedback: No active chat');
          }

          await this.provideFeedback(this.chat.id, streamingMessageId, feedback);
        };

        this.notifyListeners('update-messages', [summaryMessage]);
      });

      // finalize

      summaryMessage.message.streaming = false;
      this.notifyListeners('update-messages', [summaryMessage]);
    } catch (error) {
      this.notifyListeners('delete-summary-loading-messages');

      console.error('Failed to summarize pages:', error);
      throw this.handleError(error);
    }
  }

  public updateIdToken(newIdToken: string): void {
    this.idToken = newIdToken;
  }

  // private methods

  private createFirebaseTimestamp(secondsOffset: number = 0): FirebaseTimestamp {
    const now = new Date();
    return {
      _seconds: Math.floor(now.getTime() / 1000) + secondsOffset,
      _nanoseconds: (now.getTime() % 1000) * 1e6
    };
  }

  private async refreshAndRetry(endpoint: string, options: RequestInit, contentType: string): Promise<Response | null> {
    const { firebaseAuth } = await import('lib/firebase/firebase.client');
    const newIdToken = await firebaseAuth.currentUser?.getIdToken(true);

    if (!newIdToken) {
      throw new Error('Unable to refresh token');
    }

    this.updateIdToken(newIdToken);

    return this.fetchWithAuthInternal(endpoint, options, contentType, false);
  }

  private async fetchWithAuth(endpoint: string, options: RequestInit = {}, contentType: string = 'application/json'): Promise<Response | null> {
    return this.fetchWithAuthInternal(endpoint, options, contentType, true);
  }

  private async fetchWithAuthInternal(
    endpoint: string,
    options: RequestInit = {},
    contentType: string,
    retryOnUnauthorized: boolean = true
  ): Promise<Response | null> {
    const headers = new Headers({
      'Content-Type': contentType,
      Authorization: `Bearer ${this.idToken}`,
      'X-Speechify-Client': 'WebApp',
      'X-Speechify-Client-Version': getStringEnvWithDefault('version', '0.0.0'),
      ...(options.headers || {})
    });

    const response = await fetch(`${this.apiBaseUrl}${endpoint}`, {
      ...options,
      headers
    });

    if (response.status === 401 && retryOnUnauthorized) {
      return this.refreshAndRetry(endpoint, options, contentType);
    }

    if (response.status === 404) {
      return null;
    }

    if (!response.ok) {
      let errorData: ApiError;

      try {
        errorData = await response.json();
      } catch (err) {
        errorData = { message: response.statusText, statusCode: response.status, name: 'ApiError' };
      }

      throw new Error(`API request failed: ${errorData.message}`);
    }

    return response;
  }

  private filterSummaryLoadingMessages(messages: ChatMessage[]): ChatMessage[] {
    return messages.filter((message, index) => {
      const nextMessage = messages[index + 1];

      return !(message.type === 'summary_loading' && nextMessage?.type === 'summary');
    });
  }

  private handleError(error: unknown): Error {
    if (error instanceof Error) {
      return error;
    }

    return new Error('An unknown error occurred');
  }

  private async provideFeedback(chatId: string, messageId: string, feedback: SummaryFeedback): Promise<void> {
    const convertedFeedback = {
      feedback: {
        type: feedback.type === 'positive' ? 'ThumbUp' : 'ThumbDown',
        text: feedback.text
      }
    };

    await this.fetchWithAuth(`/ai/feedback/${chatId}/${messageId}`, {
      method: 'POST',
      body: JSON.stringify(convertedFeedback)
    });
  }

  private notifyListeners(action: ListenerAction, messages?: PartialChatMessageWithId[]): void {
    if (this.messagesChangeListener) {
      this.messagesChangeListener(action, messages);
    }
  }

  private async readStreamedResponse(
    reader: ReadableStreamDefaultReader<Uint8Array>,
    onMessageCallback?: (responseText: string, streamingMessageId: string, streamingMessageCreatedAt: FirebaseTimestamp) => void
  ): Promise<string> {
    let fullText = '';

    while (whileCondition) {
      const { done, value } = await reader.read();
      if (done) break;

      const chunk = new TextDecoder().decode(value);
      const lines = chunk.split('\n');

      for (const line of lines) {
        if (line.startsWith('data: ')) {
          const jsonString = line.slice('data: '.length);

          try {
            const parsed = JSON.parse(jsonString);
            const createdAt = parsed.message.createdAt;

            fullText = parsed.message.body;

            let createdAtTimestamp: FirebaseTimestamp = this.createFirebaseTimestamp();

            if (createdAt) {
              const date = new Date(createdAt);

              createdAtTimestamp = {
                _seconds: Math.floor(date.getTime() / 1000),
                _nanoseconds: (date.getTime() % 1000) * 1e6
              };
            }

            if (onMessageCallback) {
              onMessageCallback(fullText, parsed.message.id, createdAtTimestamp);
            }
          } catch (e) {
            // partial data, keep buffering if needed
          }
        }
      }
    }

    return fullText;
  }

  private transformMessages(apiMessages: APIMessage[]): ChatMessage[] {
    if (!apiMessages) {
      return [];
    }

    const chatMessages = apiMessages
      .map((msg): ChatMessage | null => {
        if (msg.message.type === 'prompt' && msg.message.prompt?.type === 'text') {
          const { body = '' } = msg.message.prompt;

          return {
            id: msg.id,
            createdAt: msg.createdAt,
            type: 'user',
            message: {
              body,
              loading: false
            }
          };
        }

        if (
          msg.message.type === 'prompt' &&
          (msg.message.prompt?.type === 'generateSummaryForDocument' || msg.message.prompt?.type === 'generateSummaryForPages')
        ) {
          const { options, type } = msg.message.prompt;

          return {
            id: msg.id,
            createdAt: msg.createdAt,
            type: 'summary_loading',
            options: {
              length: convertFromApiLength(options?.length || 'Short'),
              mode: convertFromApiSummaryFormat(options?.format || 'Paragraph'),

              ...(type === 'generateSummaryForPages' && {
                pageIndexes: options?.pageIndexes
              })
            }
          };
        }

        if (msg.message.type === 'response' && msg.message.response && msg.message.response.type === 'summary') {
          const { body = '', feedback, options } = msg.message.response;

          return {
            id: msg.id,
            createdAt: msg.createdAt,
            type: 'summary',
            message: {
              body,
              feedback,
              length: convertFromApiLength(options?.length || 'Short'),
              mode: convertFromApiSummaryFormat(options?.format || 'Paragraph'),
              pageIndexes: options?.pageIndexes,
              setFeedback: async (feedback: SummaryFeedback): Promise<void> => {
                if (!this.chat?.id) {
                  throw new Error('Cannot set feedback: No active chat');
                }

                await this.provideFeedback(this.chat.id, msg.id, feedback);
              }
            }
          };
        }

        if (msg.message.type === 'response' && msg.message.response) {
          const { body = '' } = msg.message.response;

          return {
            id: msg.id,
            createdAt: msg.createdAt,
            type: 'reply',
            message: {
              body
            }
          };
        }

        return null;
      })
      .filter((msg): msg is ChatMessage => msg !== null);

    const filteredChatMessages = this.filterSummaryLoadingMessages(chatMessages);
    return filteredChatMessages;
  }
}

export async function getAskAI() {
  const { firebaseAuth } = await import('lib/firebase/firebase.client');
  const idToken = await firebaseAuth.currentUser?.getIdToken();

  if (!idToken) {
    throw new Error('No authentication token available');
  }

  const askAi = APIAskAiFacade.getInstance(idToken);
  return { askAi };
}
