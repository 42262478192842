import assert from 'assert';

import {
  AbstractPDFAdapterFactory,
  AbstractPDFDocumentAdapter,
  AbstractPDFPageAdapter,
  PDFDocumentAdapter,
  PDFDocumentMetadata,
  PDFOutline,
  PDFPageAdapter,
  PDFPageImageOptions,
  PDFPageMetadata,
  PDFPageTextContentItem,
  PdfSearchOptions,
  PdfSearchResult,
  TextInBoundingBoxResult
} from '@speechifyinc/multiplatform-sdk/api/adapters/pdf';
import {
  BinaryContentReadableRandomly,
  BinaryContentWithMimeTypeFromNativeReadableInChunks,
  type BoundingBox,
  Result,
  SDKError,
  binaryContentWithMimeTypeFromNativeReadableInChunksFromBlob
} from '@speechifyinc/multiplatform-sdk/api/util';
import { BoundingBoxUtils, Viewport } from '@speechifyinc/multiplatform-sdk/api/util/images';
import type { Instance, OutlineElement } from 'pspdfkit';

import { ErrorSource } from 'config/constants/errors';
import { logError } from 'lib/observability';
import { PSPDFKitFacade } from 'lib/pdf/pspdfkit';
import { Callback } from 'lib/speechify/adaptors/lib/typeAliases';
import { Nullable } from 'utils/types';

const getPageThumbnail = async (
  pdfDocument: Instance,
  pageIndex: number,
  scale = 1,
  width = 1024
): Promise<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>> => {
  const blobUrl: string = await pdfDocument.renderPageAsImageURL({ width: width * scale }, pageIndex);
  const binaryContent = binaryContentWithMimeTypeFromNativeReadableInChunksFromBlob(await urlToBlob(blobUrl));
  return binaryContent;
};

const getPageOutline = async (pspdfKitFacade: PSPDFKitFacade): Promise<PDFOutline> => {
  if (!pspdfKitFacade.instance) {
    throw new Error('PSPDFKit instance not loaded');
  }
  const outline = await pspdfKitFacade.instance.getDocumentOutline();
  const entries = convertOutlineToEntries(outline.toArray());
  return new PDFOutline(entries);
};

function convertOutlineToEntries(outline: OutlineElement[], level: number = 0): PDFOutline.Entry[] {
  return outline.flatMap(element => {
    const entry = new PDFOutline.Entry(element.title, level, new PDFOutline.Entry.Attributes(element.action?.pageIndex ?? 0));

    const children = element.children ? convertOutlineToEntries(element.children.toArray(), level + 1) : [];
    return [entry, ...children];
  });
}

const urlToBlob = async (blobURL: string): Promise<Blob> => {
  try {
    // Fetch the Blob data from the URL
    const response = await fetch(blobURL);

    if (!response.ok) {
      throw new Error('Failed to fetch Blob data');
    }

    // Get the Blob data
    const blobData = await response.blob();

    return blobData;
  } catch (error) {
    logError(error, ErrorSource.READING_EXPERIENCE, {
      context: {
        url: blobURL
      }
    });
    return new Blob([], { type: '' });
  }
};

class WebPDFDocumentAdapter extends AbstractPDFDocumentAdapter {
  private pages: PDFPageAdapter[] = [];

  constructor(private pspdfKitFacade: PSPDFKitFacade) {
    super();
  }

  override search = async (
    text: string,
    startPageIndex: number,
    endPageIndex: number,
    searchOptions: PdfSearchOptions,
    callback: (result: Result<Array<PdfSearchResult>>) => void
  ): Promise<void> => {
    try {
      const pspdfKitResults = await this.pspdfKitFacade.instance.search(text, { startPageIndex, endPageIndex, caseSensitive: searchOptions.caseSensitive });
      const resultsWithBoundingBoxes = pspdfKitResults.map(pspdfKitResult => ({
        pspdfKitResult,
        boundingBoxes: pspdfKitResult.rectsOnPage.map(rect => {
          return BoundingBoxUtils.fromDimensionsAndCoordinates(rect.width, rect.height, rect.left, rect.top);
        })
      }));
      const pdfSearchResults = resultsWithBoundingBoxes.map(result => new PdfSearchResult(result.pspdfKitResult.pageIndex, result.boundingBoxes.toArray()));
      callback(new Result.Success(pdfSearchResults.toArray()));
    } catch (error) {
      callback(new Result.Failure(new SDKError.OtherException(error as Error)));
    }
  };

  async getPages(pageIndexes: number[], callback: Callback<PDFPageAdapter[]>) {
    const pages = pageIndexes.map(index => {
      if (this.pages[index]) {
        return this.pages[index];
      }

      const adapter = new WebPDFPageAdapter(this.pspdfKitFacade, index);
      this.pages[index] = adapter;

      return adapter;
    });

    callback(new Result.Success(pages));
  }

  getMetadata(): PDFDocumentMetadata {
    return new PDFDocumentMetadata(this.pspdfKitFacade.instance.totalPageCount);
  }

  override get title(): string {
    // TODO(albertusdev): Pass on the title either from PSPDFKit instance or the global item store here.
    return 'New Book';
  }

  override getThumbnail = async (callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>): Promise<void> => {
    callback(new Result.Success(await getPageThumbnail(this.pspdfKitFacade.instance, 0)));
  };

  override getOutline = async (callback: Callback<PDFOutline>): Promise<void> => {
    callback(new Result.Success(await getPageOutline(this.pspdfKitFacade)));
  };

  destroy() {
    this.pspdfKitFacade.unload();
  }
}

class WebPDFPageAdapter extends AbstractPDFPageAdapter {
  constructor(
    private readonly pspdfKitFacade: PSPDFKitFacade,
    private readonly index: number
  ) {
    super();
  }

  get pageIndex() {
    return this.index;
  }

  get instance(): Instance {
    const pspdfkitInstance = this.pspdfKitFacade.instance;
    if (!pspdfkitInstance) {
      throw new Error('PSPDFKit instance not loaded');
    }
    return pspdfkitInstance;
  }

  async getTextContent(callback: Callback<PDFPageTextContentItem[]>) {
    const textLines = await this.instance.textLinesForPageIndex(this.index);

    callback(
      new Result.Success(
        textLines
          .map(line => {
            const { height, left, top, width } = line.boundingBox;
            return new PDFPageTextContentItem(
              line.contents, //
              BoundingBoxUtils.fromDimensionsAndCoordinates(width, height, left, top),
              `${line.id}` // TODO(sdk-pain-point): (albertusdev) Request better API from SDK team to pass metadata here for easier mapping
            );
          })
          .toArray()
      )
    );
  }

  async getTextBetween(boxes: BoundingBox[], callback: (p0: Result<TextInBoundingBoxResult>) => void) {
    const result = await this.pspdfKitFacade.getTextBetween(this.index, boxes);
    // based of the slack conversation when this API was proposed, web should be fine to return null for bounding boxes of characters
    callback(new Result.Success(new TextInBoundingBoxResult(result)));
  }

  getMetadata() {
    const pageInfo = this.instance.pageInfoForIndex(this.index);
    if (pageInfo == null) {
      return new PDFPageMetadata(new Viewport(0, 0));
    }

    return new PDFPageMetadata(new Viewport(pageInfo.width, pageInfo.height));
  }

  destroy() {}

  async getImage(options: PDFPageImageOptions, callback: Callback<BinaryContentWithMimeTypeFromNativeReadableInChunks<BinaryContentReadableRandomly>>) {
    callback(
      new Result.Success(
        await getPageThumbnail(this.pspdfKitFacade.instance, this.pageIndex, options.scale, this.getMetadata().viewport.width * window.devicePixelRatio)
      )
    );
  }
}

export class WebPDFAdapterFactory extends AbstractPDFAdapterFactory {
  async getPDFDocumentAdapter(file: BinaryContentReadableRandomly, password: Nullable<string>, callback: Callback<PDFDocumentAdapter>) {
    // We currently don't need support for password protected documents because there is no way for users to upload them in web app
    assert(password == null || password === '', 'password protected documents are not supported');
    try {
      const currentPspdfKitFacade = await PSPDFKitFacade.load({
        contentPromise: file.blob.arrayBuffer()
      });
      callback(new Result.Success(new WebPDFDocumentAdapter(currentPspdfKitFacade)));
    } catch (e) {
      logError(e, ErrorSource.READING_EXPERIENCE);
      callback(new Result.Failure(new SDKError.OtherException(e as Error)));
    }
  }
}
