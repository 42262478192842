import { debounce } from 'lodash';

import { VoiceInfo } from 'modules/sdk/lib';

import { AnalyticsEventProperty } from '../analyticsTypes';
import { AnalyticsEventKey, logAnalyticsEvent } from '../logAnalyticsEvent';

export const logAnalyticsEventOnForwardClick = () => {
  logAnalyticsEvent(AnalyticsEventKey.playerJumpBySentence, { backward: false });
};
export const logAnalyticsEventOnBackwardClick = () => {
  logAnalyticsEvent(AnalyticsEventKey.playerJumpBySentence, { backward: true });
};

export const logAnalyticsEventOnSpeedChange = debounce(({ speed, wpm, source }: AnalyticsEventProperty<AnalyticsEventKey.playerSpeedChanged>) => {
  logAnalyticsEvent(AnalyticsEventKey.playerSpeedChanged, {
    speed,
    wpm,
    source
  });
}, 250);

export const logAnalyticsEventOnSpeedRampChange = ({ value }: AnalyticsEventProperty<AnalyticsEventKey.playerSpeedRampChanged>) => {
  logAnalyticsEvent(AnalyticsEventKey.playerSpeedRampChanged, {
    value
  });
};

export const logAnalyticsEventOnVoiceChange = (voiceInfo: VoiceInfo) => {
  const properties = {
    voiceId: voiceInfo.id,
    voiceName: voiceInfo.displayName,
    isHD: voiceInfo.isPremium,
    engine: voiceInfo.engine
  };
  logAnalyticsEvent(AnalyticsEventKey.playerVoiceChanged, properties);
};
