export const dateToString = (date: Date): string => {
  const year = new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('en-US', { month: '2-digit' }).format(date);
  const day = new Intl.DateTimeFormat('en-US', { day: '2-digit' }).format(date);

  return `${year}${month}${day}`;
};

/**
 * Normalizes text by removing diacritics (accent marks) from characters
 * Makes search more forgiving for accented characters
 */
export const normalizeDiacritics = (text: string): string => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
