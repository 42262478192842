import { getOptionalStringEnv, getRequiredStringEnv } from 'utils/safeEnvParsing';

export const getCurrentDomain = () => {
  let domain: string = 'speechify.com';
  const nodeEnv = getRequiredStringEnv('NODE_ENV');
  const vercelEnv = getOptionalStringEnv('VERCEL_ENV');
  const isDev = getOptionalStringEnv('IS_DEV');

  if (nodeEnv === 'development') {
    domain = 'localhost';
  } else if (vercelEnv === 'preview' || isDev === 'true') {
    domain = 'speechify.dev';
  } else {
    domain = 'speechify.com';
  }

  return domain;
};
