import { UserCredential } from 'firebase/auth';

import { ErrorSource } from 'config/constants/errors';
import { logError } from 'lib/observability';
import { AnalyticsEventKey } from 'modules/analytics/analyticsTypes';
import { logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { extractReason, logAuthAnalyticsEvent } from 'modules/auth/utils/analytic';
import { segmentIdentifyByUserId } from 'utils/analytics';

import { getSessionCookie } from './cookies';
import { createAuthStoreAsyncAction } from './createAuthStoreAsyncAction';

export enum LoginActionProvider {
  EMAIL_PASSWORD = 'email_password',
  GOOGLE = 'google',
  APPLE = 'apple',
  FACEBOOK = 'facebook',
  ANONYMOUS = 'anonymous',
  CUSTOM_TOKEN = 'customToken'
}

const toAnalyticsProvider = (provider: LoginActionProvider) => {
  if (provider === LoginActionProvider.EMAIL_PASSWORD) {
    return 'email';
  }
  if (provider === LoginActionProvider.ANONYMOUS) {
    return 'Anonymous';
  }
  return provider;
};

export const createLoginAction = (
  signInMethod: () => Promise<UserCredential>,
  {
    provider
  }: {
    provider: LoginActionProvider;
  }
) => {
  return createAuthStoreAsyncAction(async () => {
    let uid: string;
    try {
      const result = await signInMethod();
      uid = result.user?.uid;
      await getSessionCookie(await result.user.getIdToken());
      return;
    } catch (error) {
      logAuthAnalyticsEvent(AnalyticsEventKey.userAuthenticationFailed, {
        auth_type: 'sign_in',
        provider: provider,
        reason: extractReason(error)
      });
      logError(error, ErrorSource.AUTH);
      throw error;
    } finally {
      // @ts-expect-error TS(2454): Variable 'uid' is used before being assigned.
      if (uid) {
        segmentIdentifyByUserId(uid);
        logAuthAnalyticsEvent(AnalyticsEventKey.userSignedIn);
        logAnalyticsEvent(AnalyticsEventKey.userAuthenticationLegacy, {
          source: toAnalyticsProvider(provider),
          mode: 'login'
        });
      }
    }
  })();
};
