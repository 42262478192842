import React from 'react';

export default function SciFiIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.002 21.7394C15.1226 13.5754 20.8998 7 28.009 7C34.9459 7 40.6147 13.2606 40.9973 21.1489C37.1549 22.9424 32.4284 24 27.3179 24C22.7966 24 18.5759 23.1722 15.002 21.7394Z"
        fill="#B5E8FF"
      />
      <path d="M34.3975 24C28.7406 24 26.9406 24 21.7979 24C19.998 19.3571 20.8979 11 28.0977 11C35.2975 11 35.7475 19.8214 34.3975 24Z" fill="#22CC97" />
      <ellipse cx="28" cy="28" rx="27" ry="10" fill="#3B4EFF" />
      <path d="M40 28.5H24V54H44L40 28.5Z" fill="#B5E8FF" />
      <path d="M16 28.5H32V54H12L16 28.5Z" fill="#B5E8FF" />
      <ellipse cx="28" cy="28.5" rx="12" ry="3.5" fill="white" />
    </svg>
  );
}
