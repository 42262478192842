import { User as FirebaseUser } from 'firebase/auth';
import { IUser } from 'interfaces';
import { isEqual, pick } from 'lodash';

import { FeatureNameEnum, areFeatureFlagsInitialized, getFeatureVariant, loadFeatureFlags } from 'hooks/useFeatureFlags';
import { PlatformSubscriptionsFacade } from 'lib/platform';
import { SDKSubscriptionFacade, getSDK } from 'modules/sdk/lib';

import { useAuthStore } from '../';
import { emitUserUpdate } from '../actions/emitUserUpdate';

const addCleanupFunction = (fn: () => void) => {
  useAuthStore.setState(state => ({
    cleanupFunctions: [...state.cleanupFunctions, fn]
  }));
};

const setupSubscriptionListeners = (subscriptionFacade: SDKSubscriptionFacade) => {
  const unsubscribeFromEntitlementsChange = subscriptionFacade.listenToEntitlementsChange(entitlements => {
    useAuthStore.setState(state => ({
      user: state.user ? { ...state.user, entitlements } : null
    }));
  });
  addCleanupFunction(unsubscribeFromEntitlementsChange);

  const unsubscribeFromSubscriptionChange = subscriptionFacade.listenToSubscriptionChange(subscriptionChange => {
    if ('ttsSubscription' in subscriptionChange) {
      useAuthStore.setState(state => {
        return {
          user: state.user ? { ...state.user, subscription: subscriptionChange.ttsSubscription } : null
        };
      });
    }
    if ('hasStudioSubscription' in subscriptionChange) {
      useAuthStore.setState(state => {
        return {
          user: state.user ? { ...state.user, hasStudioSubscription: subscriptionChange.hasStudioSubscription } : null
        };
      });
    }
  });
  addCleanupFunction(unsubscribeFromSubscriptionChange);
};

const setupPlatformSubscriptionListeners = () => {
  let timer: number | undefined = undefined;
  const abortController = new AbortController();
  const scheduleFetch = () => {
    window.clearTimeout(timer);
    if (abortController.signal.aborted) return;

    timer = window.setTimeout(async () => {
      if (abortController.signal.aborted) return;
      const subscriptionAndEntitlements: Pick<IUser, 'entitlements' | 'subscription' | 'hasStudioSubscription'> =
        await PlatformSubscriptionsFacade.singleton.getSubscriptionAndEntitlements();
      if (abortController.signal.aborted) return;

      useAuthStore.setState(state => {
        if (!state.user) return state;

        const userSubscriptionFields = pick(state.user, ['entitlements', 'subscription', 'hasStudioSubscription']);
        if (isEqual(userSubscriptionFields, subscriptionAndEntitlements)) return state;

        return {
          user: {
            ...state.user,
            ...subscriptionAndEntitlements
          }
        };
      });
      emitUserUpdate();

      scheduleFetch();
    }, 60_000); // 1 minute refetch
  };

  scheduleFetch();

  addCleanupFunction(() => {
    window.clearTimeout(timer);
    abortController.abort();
  });
};

export async function getSubscriptionAndEntitlements(firebaseUser?: FirebaseUser) {
  const user = firebaseUser ?? useAuthStore.getState().firebaseUser;
  if (user && !areFeatureFlagsInitialized()) loadFeatureFlags({ uid: user.uid, email: user.email ?? '' });

  const isPlatformSdkSubscriptionsEnabled = await getFeatureVariant(FeatureNameEnum.PLATFORM_SDK_SUBSCRIPTIONS);

  if (isPlatformSdkSubscriptionsEnabled) {
    return PlatformSubscriptionsFacade.singleton.getSubscriptionAndEntitlements();
  }

  const sdk = await getSDK();
  return sdk.subscription.fetchSubscriptions();
}

export async function initSubscriptionAndEntitlements(firebaseUser: FirebaseUser) {
  if (!areFeatureFlagsInitialized()) loadFeatureFlags({ uid: firebaseUser.uid, email: firebaseUser.email ?? '' });

  const isPlatformSdkSubscriptionsEnabled = await getFeatureVariant(FeatureNameEnum.PLATFORM_SDK_SUBSCRIPTIONS);
  const subscriptionAndEntitlements = await getSubscriptionAndEntitlements(firebaseUser);

  if (isPlatformSdkSubscriptionsEnabled) {
    setupPlatformSubscriptionListeners();
  } else {
    const sdk = await getSDK();
    setupSubscriptionListeners(sdk.subscription);
  }

  return subscriptionAndEntitlements;
}
