import { FeatureNameEnum, getFeatureVariant } from 'hooks/useFeatureFlags';
import { PlatformSubscriptionsFacade } from 'lib/platform';
import { getSDK } from 'modules/sdk/lib';
import { isStripe } from 'modules/subscription/utils/checkers';
import { getExtensionId } from 'utils/extension';

import { useAuthStore } from '../';

const getBillingDashboardUrl = async () => {
  const extensionId = await getExtensionId();
  const isPlatformSdkSubscriptionsEnabled = await getFeatureVariant(FeatureNameEnum.PLATFORM_SDK_SUBSCRIPTIONS);

  if (!isPlatformSdkSubscriptionsEnabled) {
    const sdk = await getSDK();
    return sdk.subscription.getBillingDashboardUrl(extensionId);
  }

  return PlatformSubscriptionsFacade.singleton.getBillingDashboardUrl(extensionId);
};

let timeout: number | undefined = undefined;

export const setBillingDashboardUrl = async () => {
  clearTimeout(timeout);
  const { user } = useAuthStore.getState();

  if (isStripe(user)) {
    const billingDashboardUrl = await getBillingDashboardUrl();

    useAuthStore.setState(state => {
      if (!state.user) return state;
      return {
        user: { ...state.user, billingDashboardUrl }
      };
    });
  }

  const twentyMinutes = 1000 * 60 * 10 * 2;

  timeout = window.setTimeout(setBillingDashboardUrl, twentyMinutes);
};
