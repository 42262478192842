import { Timestamp } from 'firebase/firestore';

export type UUID = string;

export enum ItemActionType {
  Archive = 'archive',
  ArchiveFolder = 'archive-folder',
  DeleteArchivedItem = 'delete-archived-item',
  Delete = 'delete',
  DeleteFolder = 'delete-folder',
  Move = 'move',
  NewFolder = 'new-folder',
  Open = 'open',
  OpenOriginal = 'original',
  OpenInListeningScreenV2 = 'open-in-listening-screen-v2', // only temporary for internal Speechify user while we are rewriting listening screen
  Rename = 'rename',
  Restore = 'restore',
  Share = 'share',
  CrossSellVoiceOver = 'cross-sell-voice-over',
  Select = 'select',
  Unselect = 'unselect'
}

export enum ItemGroupActionType {
  Delete = 'delete',
  Move = 'move'
}

export enum ItemType {
  Record = 'record',
  Folder = 'folder'
}

export enum LibraryActionType {
  addFolder = 'library/addFolder',
  addInitialFiles = 'library/addInitialFiles',
  addTextDocument = 'library/addTextDocument',
  addWebLink = 'library/addWebLink',
  archiveItem = 'library/archiveItem',
  archiveItems = 'library/archiveItems',
  deleteItem = 'library/deleteItem',
  restoreItem = 'library/restoreItem',
  fetchItem = 'library/fetchItem',
  fetchItemsCount = 'library/fetchItemsCount',
  fetchSharedItem = 'library/fetchSharedItem',
  moveItems = 'library/moveItems',
  searchArticles = 'library/searchArticles',
  setParentFolderId = 'library/setParentFolderId',
  setSearchQuery = 'library/setSearchQuery',
  subscribe = 'library/subscribe'
}

export interface IItem {
  admins: UUID[];
  childrenCount: number;
  createdAt: Timestamp | Date;
  coverImagePath: string | null;
  guests: UUID[];
  id: UUID;
  isArchived: boolean;
  isRemoved: boolean;
  isShared: boolean;
  owner: UUID;
  parentFolderId: UUID | null;
  title: string;
  type: ItemType;
  updatedAt: Timestamp | Date;
  users: UUID[];
}

export enum RecordType {
  DEFAULT = 'DEFAULT',
  FILE = 'FILE',
  PDF = 'PDF',
  SCAN = 'SCAN',
  EPUB = 'EPUB',
  TXT = 'TXT',
  UNKNOWN = 'UNKNOWN',
  WEB = 'WEB'
}

export enum RecordStatus {
  Uninitialized = 'uninitialized',
  Extracting = 'extracting',
  Processing = 'processing',
  Failed = 'failed',
  Success = 'success'
}

export interface IRecord extends IItem {
  characterCount?: number;
  characterIndex?: number;
  excerpt?: string;
  excludedPages?: number[];
  isSDK?: boolean;
  listeningProgress?: IListeningProgress;
  numPages?: number;
  pageIndex?: number;
  listenProgressStatus?: string;
  progressPercent?: number;
  recordType: RecordType;
  status: RecordStatus;
  sourceURL?: string;
  originalSourceURL?: string;
  wordCount?: number;
  originalCopy: string;
  analyticsProperties: Record<string, unknown>;
}

export interface ISearchResult {
  coverImageUrl?: string;
  id: string;
  title: string;
}

export interface IListeningProgress {
  cursor: {
    characterIndex: number;
  };
  fraction: number;
  timestamp: string;
}

export type FolderV2 = IItem;

export interface IBookmarkLocation {
  characterIndex: number;
  numPages?: number;
  pageIndex: number;
  updatedAt?: Timestamp;
}

export interface IPage {
  createdAt: Timestamp;
  html?: string;
  id: UUID;
  index: number;
  length?: number;
  sourceImagePath?: string;
  storagePath: string;
  textContent?: string;
  updatedAt: Timestamp;
  wordCount?: number;
}

export interface IError {
  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [action: string]: any;
}

export enum ViewType {
  LIST = 'list',
  GRID = 'grid'
}
