import { updateProfile as firebaseUpdateProfile } from 'firebase/auth';

import { firebaseAuth } from 'lib/firebase/firebase.client';

import { createAuthStoreAsyncAction } from '../utils/createAuthStoreAsyncAction';
import { useAuthStore } from '..';

export const updateProfile = createAuthStoreAsyncAction(async (profile: { displayName: string; photoURL?: string }) => {
  useAuthStore.setState(state => {
    if (!state.user) return state;
    return {
      ...state,
      user: {
        ...state.user,
        displayName: profile.displayName
      }
    };
  });
  return await firebaseUpdateProfile(firebaseAuth.currentUser!, profile);
});
