import { PlatformClient, PlatformEnvironment } from '@speechifyinc/platform-public';

import { getOptionalStringEnv } from 'utils/safeEnvParsing';

import { PlatformSubscriptionsFacade } from './subscriptions';

export class PlatformFacade {
  private static _platformClient: PlatformClient;

  private get platformClient() {
    if (!PlatformFacade._platformClient) {
      PlatformFacade._platformClient = new PlatformClient({
        environment: getOptionalStringEnv('NEXT_PUBLIC_IS_DEV') === 'true' ? PlatformEnvironment.Development : PlatformEnvironment.Production,
        caller: 'web'
      });
    }
    return PlatformFacade._platformClient;
  }

  public subscriptions = new PlatformSubscriptionsFacade(this.platformClient);
}
