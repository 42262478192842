import { IUser } from 'interfaces/user';

export const isNewUser = (user: IUser, newUserThreshold: number = new Date('2024-08-01').getTime()) => {
  const { metadata } = user;

  if (!metadata?.creationTime) {
    return false;
  }

  const creationTime = new Date(metadata.creationTime).getTime();

  if (isNaN(creationTime)) {
    return false;
  }

  return creationTime >= newUserThreshold;
};
