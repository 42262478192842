import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  inMemoryPersistence,
  setPersistence,
  signInAnonymously,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup
} from 'firebase/auth';

import { firebaseAuth } from 'lib/firebase/firebase.client';

import { LoginActionProvider, createLoginAction } from '../utils/createLoginAction';

export const login = ({ email, password }: { email: string; password: string }) =>
  createLoginAction(
    () => signInWithEmailAndPassword(firebaseAuth, email, password), //
    { provider: LoginActionProvider.EMAIL_PASSWORD }
  );

export const loginWithCustomToken = (token: string) =>
  createLoginAction(
    () => signInWithCustomToken(firebaseAuth, token), //
    { provider: LoginActionProvider.CUSTOM_TOKEN }
  );

export const loginWithApple = () =>
  createLoginAction(
    () => {
      const appleProvider = new OAuthProvider('apple.com');
      appleProvider.addScope('name');
      appleProvider.addScope('email');
      return signInWithPopup(firebaseAuth, appleProvider);
    }, //
    { provider: LoginActionProvider.APPLE }
  );

export const loginWithFacebook = () =>
  createLoginAction(
    () => signInWithPopup(firebaseAuth, new FacebookAuthProvider()), //
    { provider: LoginActionProvider.FACEBOOK }
  );

export const loginWithGoogle = () =>
  createLoginAction(
    () => signInWithPopup(firebaseAuth, new GoogleAuthProvider()), //
    { provider: LoginActionProvider.GOOGLE }
  );

export const loginAnonymously = () =>
  createLoginAction(
    async () => {
      // do not persist anonymous user session
      await setPersistence(firebaseAuth, inMemoryPersistence);
      return signInAnonymously(firebaseAuth);
    }, //
    { provider: LoginActionProvider.ANONYMOUS }
  );
