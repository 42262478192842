import React from 'react';

export default function DystopianIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_208_5876)">
        <g clipPath="url(#clip1_208_5876)">
          <path d="M56 15.0078H41.49V20.2599L52.3502 22.8414L56 15.0078Z" fill="#3B4EFF" />
          <path d="M56 26.6895L43.3023 19.6675L40.7606 24.2636L49.0151 31.7784L56 26.6895Z" fill="#3B4EFF" />
          <path
            d="M21.342 14.2449C18.4318 15.587 5.44756 13.1753 0 11.0662V23.1766L3.50163 20.7546L7.00325 23.1766L10.5049 20.7546L14.0065 23.1766L17.1109 20.7546L18.6753 23.1766L12.3199 29.4042L17.1109 33.2965C17.4368 32.1288 18.5697 29.5299 19.4575 27.8473C21.922 23.1766 28.2017 25.054 25.4032 31.89C23.1644 37.3588 9.47793 50.6203 1.01415 55L32.8227 55C49.8195 38.4447 46.674 19.8094 42.278 15.2653C50.8387 14.9376 55.5029 9.22023 55.9465 6.83614C56.3901 4.45205 53.9778 1.9117 52.3074 1C47.8711 8.59377 44.3768 10.5871 39.6196 11.0662C36.1271 11.4178 27.7898 9.51965 23.3827 7.62252C23.9151 9.27078 24.2522 12.9028 21.342 14.2449Z"
            fill="#22CC97"
          />
          <circle cx="29" cy="17" r="3" fill="#3B4EFF" />
          <path
            d="M42 55C42 53.6868 42.3363 52.3864 42.9896 51.1732C43.6429 49.9599 44.6005 48.8575 45.8076 47.9289C47.0148 47.0003 48.4479 46.2638 50.0251 45.7612C51.6023 45.2587 53.2928 45 55 45L55 55L42 55Z"
            fill="#22CC97"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_208_5876">
          <rect width="56" height="56" fill="white" />
        </clipPath>
        <clipPath id="clip1_208_5876">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
