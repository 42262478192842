// The following file is a rewritten version of the src/utils/pspdfkit.js for the new Listening Experience V2 screen
// We are rewriting it primarily for simplicity and type safety since the previous file was written using js

import assert from 'assert';

import type { Instance, Size, StandaloneConfiguration, ViewState } from 'pspdfkit';

import { SCROLLBAR_WIDTH_IN_PX } from 'components/newListeningExperience/shared/constants';
import { TOP_NAV_HEIGHT_IN_PX } from 'components/newListeningExperience/topnav/constants';
import { logError } from 'lib/observability';
import { getMaxZoomPercentage, getMinZoomPercentage } from 'modules/listening/features/settings/implementation/zoom';
import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import { isMobile } from 'utils/browser';

import { CDN_PSPDFKIT_BASE_URL, PSPDFKIT_KEY } from './constants';

async function importPSPDFKit() {
  return import(/* webpackChunkName: 'lib-pspdfkit' */ 'pspdfkit').then(module => module.default);
}

export const DEFAULT_PDF_INITIAL_ZOOM = 1.6;

export type RenderPageCallback = (context: CanvasRenderingContext2D, pageIndex: number, pageSize: Size) => unknown;

export type PageVisibilityChangedCallback = (visible: boolean) => unknown;

type PSPDFKitLib = Awaited<ReturnType<typeof importPSPDFKit>>;
type ThumbnailCacheKey = string; // `${pageIndex}-${height}`;

export class PSPDFKitFacade {
  // This contains the 'pspdfkit' module since one imports directly contributes to ~2MB bundle size
  // So we should reuse the module if it's already loaded
  private static _lib: PSPDFKitLib;
  // We use this variable to prevent double-loading of the library object
  private static _libPromise: Promise<PSPDFKitLib> | undefined;
  // Store last created instance for retrieval later
  private static _instanceForCurrentContainer: PSPDFKitFacade | undefined;

  // This is used to set the container for the next instance that is not headless
  // If this is undefined then the next instance will be created in headless mode
  // This is not ideal (there could be race-conditions due to not setting the _pdfContainerProperly), but I think it's ok for now
  // We will get rid of headed mode in a few weeks after the Reader API rewrite
  private static _pdfContainerForNextInstance: HTMLElement | undefined;

  private static _defaultConfiguration(lib: PSPDFKitLib): Omit<StandaloneConfiguration, 'container' | 'document'> {
    return {
      baseUrl: CDN_PSPDFKIT_BASE_URL,
      initialViewState: new lib.ViewState({
        currentPageIndex: 0,
        showToolbar: false,
        zoom: isMobile() ? getMinZoomPercentage() / 100 : lib.ZoomMode.FIT_TO_VIEWPORT
      }),
      licenseKey: PSPDFKIT_KEY,
      theme: lib.Theme.AUTO,
      styleSheets: ['/css/customPSPDFKit.css'],
      disableTextSelection: true,
      disableMultiSelection: true,
      minDefaultZoomLevel: getMinZoomPercentage() / 100,
      maxDefaultZoomLevel: getMaxZoomPercentage() / 100
    };
  }

  private static async _loadLib() {
    if (!PSPDFKitFacade._libPromise) {
      const loadLib = async () => {
        const lib = await importPSPDFKit();
        PSPDFKitFacade._lib = lib;
        await lib.preloadWorker({
          ...PSPDFKitFacade._defaultConfiguration(lib),
          // There two are not needed for preloading the worker
          document: undefined as unknown as string,
          container: undefined as unknown as HTMLElement
        });
        return lib;
      };
      PSPDFKitFacade._libPromise = loadLib();
    }

    return await PSPDFKitFacade._libPromise;
  }

  static async preload() {
    await PSPDFKitFacade._loadLib();
  }

  static async load({ contentPromise }: { contentPromise: Promise<ArrayBuffer | string> }) {
    // We are creating multiple bundles for the same PDF, we want to reuse the same instance in that case
    // This is not ideal, but probably does not make sense to investigate now since we will be rewriting this logic with Reader API
    if (this._instanceForCurrentContainer) {
      return this._instanceForCurrentContainer;
    }

    const [lib, document] = await Promise.all([PSPDFKitFacade._loadLib(), contentPromise]);

    let headless = false;
    let container: HTMLElement | undefined;
    if (this._pdfContainerForNextInstance) {
      container = this._pdfContainerForNextInstance;
    }

    if (!container) {
      // TS types are wrong for PSPDFKit because container is not needed in headless mode
      container = undefined as unknown as HTMLElement;
      headless = true;
    }

    const instance = await lib.load({
      ...this._defaultConfiguration(lib),
      headless,
      container,
      renderPageCallback: (...args) => facade._renderPageCallback(...args),
      document,
      disableTextSelection: true
    });

    const facade = new PSPDFKitFacade(instance);
    if (!headless) {
      facade._prepareDomOnInstanceLoad();
      usePlaybackStore.setState(() => ({
        currentPspdfKitFacade: facade
      }));
    }
    this._instanceForCurrentContainer = facade;
    return facade;
  }

  static setPdfContainerForNextInstance(container: HTMLElement) {
    PSPDFKitFacade._pdfContainerForNextInstance = container;
    PSPDFKitFacade.forgetInstanceForCurrentContainer();
  }

  static makeTheNextInstanceHeadless() {
    PSPDFKitFacade._pdfContainerForNextInstance = undefined;
    PSPDFKitFacade.forgetInstanceForCurrentContainer();
  }

  /**
   * We might want to get the facade instance in two scenarios:
   * 1. Users starts has the current pdf opened and then it will be assigned in `_instanceForCurrentContainer` or if it's not yet than we will get it via usePlaybackStore
   * 2. Users is uploading multiple files, then we will start the upload in series (we can store only one instance at a time in `_instanceForCurrentContainer`)
   */
  static async getInstanceForCurrentContainer() {
    return new Promise<PSPDFKitFacade>(resolve => {
      const unsubscribe = usePlaybackStore.subscribe(state => {
        if (state.currentPspdfKitFacade) {
          resolve(state.currentPspdfKitFacade);
          unsubscribe();
        }
      });
      const currentPspdfKitFacade = PSPDFKitFacade._instanceForCurrentContainer;
      if (currentPspdfKitFacade) {
        resolve(currentPspdfKitFacade);
        unsubscribe();
      }
    });
  }

  /**
   * This should be called when pdf stops being shown (user closes listening experience), so that the next opened pdf does not use stale instance
   */
  static forgetInstanceForCurrentContainer() {
    PSPDFKitFacade._instanceForCurrentContainer = undefined;
  }

  private _pageRenderListeners: RenderPageCallback[] = [];
  private _cleanUpFunctions: (() => void)[] = [];

  constructor(readonly instance: Instance) {}

  private _instanceHighlightContainer: HTMLElement | null = null;
  private _instancePostPageContentContainer: HTMLElement | null = null;
  private _instanceScrollElement: HTMLElement | null = null;
  private _thumbnailCache: Map<ThumbnailCacheKey, string> | null = null;

  private _prepareDomOnInstanceLoad = () => {
    const instanceScrollElement = this._querySelector('.PSPDFKit-Scroll', this.instance, true);
    const instanceZoomElement = this._querySelector('.PSPDFKit-Zoom', this.instance, true);

    instanceZoomElement.style.position = 'relative';

    const instanceHighlightContainer = document.createElement('div');
    instanceHighlightContainer.style.position = 'absolute';
    instanceHighlightContainer.style.top = '0';
    instanceHighlightContainer.style.left = '0';
    instanceHighlightContainer.style.width = '100%';
    instanceHighlightContainer.style.height = '100%';
    instanceHighlightContainer.id = 'speechify-highlight-container';

    instanceZoomElement.appendChild(instanceHighlightContainer);

    const instancePostPageContentContainer = document.createElement('div');
    instancePostPageContentContainer.style.position = 'absolute';
    instancePostPageContentContainer.style.top = '0';
    instancePostPageContentContainer.style.left = '0';
    instancePostPageContentContainer.id = 'speechify-post-page-content-container';

    // apply tailwind styling to the container
    const stylesheetLink = document.createElement('link');
    stylesheetLink.href = '/css';

    instanceZoomElement.appendChild(instancePostPageContentContainer);

    this._instanceHighlightContainer = instanceHighlightContainer;
    this._instanceScrollElement = instanceScrollElement;
    this._instancePostPageContentContainer = instancePostPageContentContainer;

    this.containerElement.style.setProperty('--speechify-nav-height', `${TOP_NAV_HEIGHT_IN_PX}px`);
    this.containerElement.style.setProperty('--content-margin-top', `${TOP_NAV_HEIGHT_IN_PX + 12}px`);
    this.containerElement.style.setProperty('--scrollbar-width', `${SCROLLBAR_WIDTH_IN_PX}px`);

    this._thumbnailCache = new Map();
  };

  private _querySelector = (selector: string, instance?: Instance, throwError?: boolean): HTMLElement => {
    const element = (instance ?? this.instance).contentDocument.querySelector(selector);

    if (!element && throwError) {
      throw new Error(`PSPDFKit _onInstanceLoad.querySelector('${selector}') not found`);
    }

    return element as HTMLElement;
  };

  public get instanceHighlightContainer() {
    if (!this._instanceHighlightContainer) {
      throw new Error('PSPDFKit instanceHighlightContainer not found');
    }

    return this._instanceHighlightContainer;
  }

  public get instancePostPageContentContainer() {
    if (!this._instancePostPageContentContainer) {
      throw new Error('PSPDFKit instancePostPageContentContainer not found');
    }
    return this._instancePostPageContentContainer;
  }

  public get instanceScroller() {
    if (!this._instanceScrollElement) {
      throw new Error('PSPDFKit instanceScroller not found');
    }
    return this._instanceScrollElement;
  }

  private _renderPageCallback: RenderPageCallback = (...args) => {
    this._pageRenderListeners.forEach(listener => listener(...args));
  };

  openSearchUI = () => {
    this.instance.setViewState(viewState => viewState.set('interactionMode', this.lib.InteractionMode.SEARCH));
  };

  hideSearchUI = () => {
    if (this.instance.viewState.interactionMode === this.lib.InteractionMode.SEARCH) {
      this.instance.setViewState(viewState => viewState.set('interactionMode', null));
    }
  };

  unload() {
    this._pageRenderListeners = [];
    this._cleanUpFunctions.forEach(fn => fn());
    this.lib.unload(this.instance);
    this._cleanUpFunctions = [];
    this._thumbnailCache = null;
  }

  private get lib(): PSPDFKitLib {
    assert(PSPDFKitFacade._lib !== undefined, 'PSPDFKit library is not loaded');
    return PSPDFKitFacade._lib;
  }

  get containerElement(): HTMLElement {
    const element = document.querySelector('.PSPDFKit-Container') as HTMLElement;
    return element;
  }

  get shadowRoot() {
    return this.containerElement?.shadowRoot;
  }

  elementsFromPoint = (x: number, y: number) => {
    return this.shadowRoot?.elementsFromPoint(x, y) ?? [];
  };

  getCustomIdFromPoint = (x: number, y: number) => {
    const elements = this.elementsFromPoint(x, y);
    const fragment = elements.find(element => element.hasAttribute('data-speechify-custom-id'));
    if (fragment) {
      return fragment.getAttribute('data-speechify-custom-id');
    }
    return null;
  };

  // Will return null if `pageIndex` is too far from the current page index, in which PSPDFKit hasn't even considered loading it yet.
  getPageElementSync = (pageIndex: number) => {
    if (!this.containerElement) return null;
    return this.shadowRoot?.querySelector(`[data-page-index="${pageIndex}"][data-page-is-loaded]`);
  };

  pageElementToContentLayerElement = (pageElement: Element) => {
    const contentLayerElement = pageElement.querySelector('.PSPDFKit-Content-Layer');
    if (contentLayerElement) {
      return contentLayerElement;
    }
    return pageElement;
  };

  getPageContainerForInsertingCustomOverlaySync = (pageIndex: number) => {
    const pageElement = this.getPageElementSync(pageIndex);
    if (!pageElement) {
      return null;
    }
    return this.pageElementToContentLayerElement(pageElement);
  };

  isPageLoaded = (pageIndex: number) => {
    const pageElement = this.getPageElementSync(pageIndex);
    if (!pageElement) {
      return false;
    }
    const isLoaded = pageElement.getAttribute('data-page-is-loaded') === 'true';
    if (!isLoaded) return false;

    const hasTextLine = pageElement.querySelector('.PSPDFKit-Content-Layer') !== null;
    return hasTextLine;
  };

  addPageVisibilityListener = (pageIndex: number, callback: PageVisibilityChangedCallback) => {
    const pageRenderedListener = () => {
      const visible = this.isPageLoaded(pageIndex);
      callback(visible);
    };
    const cleanUp = this.addPageRenderedListener(pageRenderedListener);
    pageRenderedListener();
    return () => {
      cleanUp?.();
    };
  };

  addPageIndexListener = (listener: (pageIndex: number) => void) => {
    this.instance.addEventListener('viewState.currentPageIndex.change', listener);
    const pageIndexListener = () => this.instance.removeEventListener('viewState.currentPageIndex.change', listener);

    this._cleanUpFunctions.push(pageIndexListener);

    return () => {
      pageIndexListener();
      this._cleanUpFunctions = this._cleanUpFunctions.filter(fn => fn !== pageIndexListener);
    };
  };

  addZoomListener = (listener: (zoom: number) => void) => {
    this.instance.addEventListener('viewState.zoom.change', listener);
    const cleanUpZoomListener = () => this.instance.removeEventListener('viewState.zoom.change', listener);

    this._cleanUpFunctions.push(cleanUpZoomListener);

    return () => {
      cleanUpZoomListener();
      this._cleanUpFunctions = this._cleanUpFunctions.filter(fn => fn !== cleanUpZoomListener);
    };
  };

  addIsSearchActiveListener = (listener: (isSearchActive: boolean) => void) => {
    const instance = this.instance;
    if (!instance) {
      logError(new Error('PSPDFKitFacade.addIsSearchActiveListener() error: PSPDFKit instance not found'));
      return;
    }
    const viewStateListener = (current: ViewState, prev: ViewState) => {
      if (current?.interactionMode === prev?.interactionMode) {
        return;
      }
      listener(current.interactionMode === this.lib.InteractionMode.SEARCH);
    };
    instance.addEventListener('viewState.change', viewStateListener);

    const cleanUpIsSearchActiveListener = () => instance.removeEventListener('viewState.change', viewStateListener);

    this._cleanUpFunctions.push(cleanUpIsSearchActiveListener);

    return () => {
      cleanUpIsSearchActiveListener();
      this._cleanUpFunctions = this._cleanUpFunctions.filter(fn => fn !== cleanUpIsSearchActiveListener);
    };
  };

  addPageRenderedListener = (listener: RenderPageCallback) => {
    const instance = this.instance;
    if (!instance) {
      logError(new Error('PSPDFKitFacade.addPageRenderedListener() error: PSPDFKit instance not found'));
      return;
    }

    this._pageRenderListeners.push(listener);

    return () => {
      this._pageRenderListeners = this._pageRenderListeners.filter(fn => fn !== listener);
    };
  };

  getCurrentPageIndex = () => {
    return this.instance.viewState.currentPageIndex;
  };

  addCustomOverlayItem = ({ id, pageIndex, left, top, node }: { id: string; node: Node; pageIndex: number; left: number; top: number }) => {
    const PSPDFKit = this.lib;

    const item = new PSPDFKit.CustomOverlayItem({
      id,
      pageIndex,
      node,
      position: new PSPDFKit.Geometry.Point({
        x: left,
        y: top
      })
    });

    this.instance.setCustomOverlayItem(item);
  };

  goToPage = (pageIndex: number) => {
    const instance = this.instance;
    if (!instance) {
      throw new Error('PSPDFKit instance not found');
    }
    const newState = instance.viewState.set('currentPageIndex', pageIndex);
    instance.setViewState(newState);
  };

  getZoomInPercentage = () => {
    const instance = this.instance;
    if (!instance) {
      throw new Error('PSPDFKit instance not found');
    }
    const zoomLevel = instance.currentZoomLevel * 100;
    return Math.ceil(Math.round(zoomLevel));
  };

  setZoom = (zoomPercentage: number) => {
    const instance = this.instance;
    if (!instance) {
      throw new Error('PSPDFKit instance not found');
    }
    instance.setViewState(state => state.set('zoom', zoomPercentage / 100));
  };

  getThumbnail = async ({ height }: { height: number }, pageIndex: number) => {
    const instance = this.instance;
    if (!instance) {
      throw new Error('PSPDFKit instance not found');
    }

    const cacheKey = `${pageIndex}-${height}`;
    if (this._thumbnailCache?.has(cacheKey)) {
      return this._thumbnailCache.get(cacheKey)!;
    }

    const result = await instance.renderPageAsImageURL({ height }, pageIndex);
    this._thumbnailCache?.set(cacheKey, result);
    return result;
  };

  getTotalPageCount = () => {
    const instance = this.instance;
    if (!instance) {
      throw new Error('PSPDFKit instance not found');
    }

    return instance.totalPageCount;
  };

  getDocumentOutline = () => {
    const instance = this.instance;
    if (!instance) {
      throw new Error('PSPDFKit instance not found');
    }

    return instance.getDocumentOutline();
  };

  getTextBetween = async (
    pageIndex: number,
    boxes: {
      left: number;
      top: number;
      width: number;
      height: number;
    }[]
  ) => {
    const PSPDFKit = this.lib;
    const rects = boxes.map(
      box =>
        new PSPDFKit.Geometry.Rect({
          left: box.left,
          top: box.top,
          width: box.width,
          height: box.height
        })
    );
    const rectList = PSPDFKit.Immutable.List(rects);
    return await this.instance.getTextFromRects(pageIndex, rectList);
  };
}
