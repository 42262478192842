import { PlatformClient } from '@speechifyinc/platform-public';

import { auth } from 'lib/speechify';

export abstract class PlatformServiceFacade {
  constructor(protected client: PlatformClient) {}
  static get singleton(): PlatformServiceFacade {
    throw new Error('Method not implemented! Use derived class');
  }

  protected async getHeaders(): Promise<Record<string, string>> {
    const idToken = await auth.currentUser?.getIdToken();
    return { Authorization: `Bearer ${idToken}` };
  }
}
