import { createIndexedDbStore } from 'lib/zustand';
import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { ChatMessage, Quiz } from 'modules/ask-ai/types';
import { ListenerAction, QuizDialogState, SummaryDialogState } from 'modules/ask-ai/types';
import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import { ContentMetaType, PlaybackInfo } from 'modules/sdk/lib';

import { getAskAI } from './api';

type AskAiState = {
  autoPlay: boolean;
  currentItemId: string | null;
  currentlyPlayingMessageId: string | null;
  currentlyPlayingMessagePlayButtonInView: boolean | null;
  isCreatingQuiz: boolean;
  isLoading: boolean;
  isRetakingQuiz: boolean;
  isStreaming: boolean;
  isSummarizing: boolean;
  messages: Record<string, ChatMessage[]>;
  playbackInfo: PlaybackInfo | null;
  quizCompletionTrigger: number;
  renderPlayButtonInHeader: boolean;
  userInteracted: boolean;
};

type PersistedAskAiState = Pick<AskAiState, 'autoPlay' | 'messages'>;

const DefaultAskAiState: AskAiState = {
  autoPlay: true,
  currentItemId: null,
  currentlyPlayingMessageId: null,
  currentlyPlayingMessagePlayButtonInView: null,
  isCreatingQuiz: false,
  isLoading: false,
  isRetakingQuiz: false,
  isStreaming: false,
  isSummarizing: false,
  messages: {},
  playbackInfo: null,
  quizCompletionTrigger: 0,
  renderPlayButtonInHeader: false,
  userInteracted: false
};

export const useAskAiStore = createIndexedDbStore<AskAiState, PersistedAskAiState>(
  () => {
    return DefaultAskAiState;
  },
  {
    storageName: 'speechifyAskAiSettings',
    version: 1,
    partialize: ({ autoPlay, messages }: PersistedAskAiState) => ({ autoPlay, messages })
  }
);

const answerQuizQuestion = async (quizId: string, questionKey: string, answerIndex: number, method: 'keyboard' | 'mouse') => {
  const { askAi } = await getAskAI();

  const currentListenableContent = usePlaybackStore.getState().currentListenableContent;

  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, {
    action: 'answer_quiz',
    quizId,
    questionKey,
    answerIndex,
    fileType: currentListenableContent?.metaType ?? ContentMetaType.UNKNOWN,
    method
  });

  await askAi.answerQuizQuestion(quizId, questionKey, answerIndex);
};

const cleanUpAskAi = async (isLogout: boolean = false) => {
  useAskAiStore.setState(s => {
    return {
      ...DefaultAskAiState,
      autoPlay: s.autoPlay,
      messages: isLogout ? {} : s.messages
    };
  });

  const { askAi } = await getAskAI();
  askAi.destroy();
};

const createQuiz = async (state: Omit<QuizDialogState, 'open'>): Promise<Quiz> => {
  const { askAi } = await getAskAI();

  const currentListenableContent = usePlaybackStore.getState().currentListenableContent;

  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, {
    action: 'generate_quiz',
    allPages: state.allPages,
    pageIndexes: state.pageIndexes,
    questionCount: state.questionCount,
    fileType: currentListenableContent?.metaType ?? ContentMetaType.UNKNOWN
  });

  setIsCreatingQuiz(true);

  const quiz = await askAi.createQuiz(state.questionCount, state.allPages ? undefined : state.pageIndexes.map(p => p + 1));

  setIsCreatingQuiz(false);
  setUserInteracted(true);

  return quiz;
};

const deleteChat = async () => {
  const { askAi } = await getAskAI();
  await askAi.deleteChat();

  useAskAiStore.setState(s => {
    return {
      ...DefaultAskAiState,
      autoPlay: s.autoPlay
    };
  });

  setUserInteracted(true);
};

const fetchAskAiChatsForCurrentDocument = async (itemId: string) => {
  const { askAi } = await getAskAI();

  const { messages: persistedMessages } = useAskAiStore.getState();
  const persistedItemMessages = persistedMessages[itemId] || [];
  const since = getLatestSinceFromMessages(persistedItemMessages);

  try {
    useAskAiStore.setState({ currentItemId: itemId, isLoading: since === 0 });

    askAi.setMessagesChangeListener((action, updatedMessages) => {
      useAskAiStore.setState(s => ({
        messages: {
          ...s.messages,
          [itemId]: mergeMessages(s.messages[itemId] || [], (updatedMessages?.filter(Boolean) as ChatMessage[]) || [], action)
        }
      }));
    });

    await askAi.initializeChatForDocument(itemId, since);
  } finally {
    useAskAiStore.setState({ isLoading: false });
  }
};

const getLatestSinceFromMessages = (messages: ChatMessage[], fallbackSince: number = 0): number => {
  if (messages.length === 0) {
    return fallbackSince;
  }

  const latestMessage = messages[messages.length - 1];
  const latestSince = latestMessage.createdAt._seconds;

  return latestSince + 1;
};

const getMessageById = (messageId: string): ChatMessage | undefined => {
  const currentItemId = useAskAiStore.getState().currentItemId;

  if (!currentItemId) {
    return;
  }

  const messages = useAskAiStore.getState().messages[currentItemId] || [];
  return messages.find(msg => msg.id === messageId);
};

const initializeAskAiStore = async () => {
  await useAskAiStore.waitForInitialHydration();
};

const mergeMessages = (oldMessages: ChatMessage[], newMessages: ChatMessage[], action: ListenerAction): ChatMessage[] => {
  if (action === 'create-chat' || action === 'delete-chat') {
    return [];
  }

  if (action === 'init') {
    return sortMessages(newMessages);
  }

  if (action === 'delete-summary-loading-messages') {
    return sortMessages(oldMessages.filter(msg => msg.type !== 'summary_loading'));
  }

  if (action === 'delete-messages' || action === 'delete-quiz' || action === 'delete-quizzes') {
    const idsToDelete = new Set(newMessages.map(msg => msg.id));
    return sortMessages(oldMessages.filter(msg => !idsToDelete.has(msg.id)));
  }

  let oldMessagesToMap = oldMessages;

  if (action === 'update-messages-and-delete-temp-messages') {
    oldMessagesToMap = oldMessages.filter(msg => !msg.id.endsWith('temp'));
  }

  // else upsert (create or update) and sort
  const mergedMessagesMap = new Map<string, ChatMessage>(oldMessagesToMap.map(msg => [msg.id, msg]));

  newMessages.forEach(newMessage => {
    if (mergedMessagesMap.has(newMessage.id)) {
      const messageToUpdate = mergedMessagesMap.get(newMessage.id);
      mergedMessagesMap.set(newMessage.id, { ...messageToUpdate, ...newMessage });
    } else {
      mergedMessagesMap.set(newMessage.id, newMessage);
    }
  });

  return sortMessages(Array.from(mergedMessagesMap.values()));
};

const sortMessages = (messages: ChatMessage[]): ChatMessage[] => {
  return messages.sort((a, b) => {
    const secondsDiff = a.createdAt._seconds - b.createdAt._seconds;

    if (secondsDiff !== 0) {
      return secondsDiff;
    }

    return a.createdAt._nanoseconds - b.createdAt._nanoseconds;
  });
};

const refreshQuiz = async (quizId: string): Promise<void> => {
  const { askAi } = await getAskAI();
  await askAi.refreshQuiz(quizId);
};

const retakeQuiz = async (quizId: string): Promise<Quiz> => {
  const { askAi } = await getAskAI();

  const currentListenableContent = usePlaybackStore.getState().currentListenableContent;

  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, {
    action: 'retake_quiz',
    quizId,
    fileType: currentListenableContent?.metaType ?? ContentMetaType.UNKNOWN
  });

  setIsRetakingQuiz(true);

  const quizPromise = askAi.retakeQuiz(quizId);
  const timerPromise = new Promise<void>(r => setTimeout(r, 2000));

  await Promise.race([quizPromise.then(() => timerPromise), timerPromise.then(() => quizPromise)]);

  setIsRetakingQuiz(false);
  setUserInteracted(true);

  return quizPromise;
};

const regenerate = async (messageId: string) => {
  const messageToRegenerate = getMessageById(messageId);
  if (!messageToRegenerate) return;

  const { askAi } = await getAskAI();

  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, {
    action: 'summary_regenerate'
  });

  setIsStreaming(true);
  setIsSummarizing(true);

  await askAi.regenerateMessage(messageToRegenerate);

  setIsStreaming(false);
  setIsSummarizing(false);
  setUserInteracted(true);
};

const sendPrompt = async (prompt: string) => {
  const { askAi } = await getAskAI();

  setUserInteracted(true);
  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, { action: 'chat', query: prompt });

  await askAi.sendMessage(prompt);
};

const setAutoPlay = (autoPlay: boolean, source: 'chat' | 'quiz' = 'chat') => {
  logAnalyticsEvent(AnalyticsEventKey.documentAskAiAutoplayResponses, {
    state: autoPlay ? 'on' : 'off',
    source
  });

  useAskAiStore.setState({ autoPlay: autoPlay });
};

const setCurrentlyPlayingMessagePlayButtonInView = (value: boolean | null) => {
  useAskAiStore.setState({ currentlyPlayingMessagePlayButtonInView: value });
};

const setIsCreatingQuiz = (isCreatingQuiz: boolean) => {
  useAskAiStore.setState({ isCreatingQuiz });
};

const setIsRetakingQuiz = (isRetakingQuiz: boolean) => {
  useAskAiStore.setState({ isRetakingQuiz });
};

const setIsStreaming = (isStreaming: boolean) => {
  useAskAiStore.setState({ isStreaming });
};

const setIsSummarizing = (isSummarizing: boolean) => {
  useAskAiStore.setState({ isSummarizing });
};

const setPlaybackInfo = (playbackInfo: PlaybackInfo | null, messageId: string | null) => {
  // reset previous playback info
  const state = useAskAiStore.getState();

  if (state.currentlyPlayingMessageId !== messageId && state.playbackInfo) {
    state.playbackInfo?.controls.sdkPlaybackControls.restart();
    state.playbackInfo?.controls.sdkPlaybackControls.pause();
  }

  useAskAiStore.setState({
    playbackInfo,
    currentlyPlayingMessageId: messageId
  });
};

const setQuizCompletionTrigger = () => {
  useAskAiStore.setState({ quizCompletionTrigger: Date.now().valueOf() });
};

const setUserInteracted = (userInteracted: boolean) => {
  useAskAiStore.setState({ userInteracted });
};

const summarizeCurrentPage = async (currentPageIndex: number) => {
  const { askAi } = await getAskAI();

  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, {
    action: 'generate_summary',
    mode: 'paragraph',
    length: 'short',
    pageIndexes: [currentPageIndex + 1],
    allPages: false
  });

  setIsSummarizing(true);

  await askAi.summarizePages([currentPageIndex + 1], 'short', 'paragraph', () => {
    setIsStreaming(true);
  });

  setIsStreaming(false);
  setIsSummarizing(false);
  setUserInteracted(true);
};

const summarizeDocument = async (length: SummaryDialogState['length'], mode: SummaryDialogState['mode']) => {
  const { askAi } = await getAskAI();

  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, {
    action: 'generate_summary',
    mode,
    length,
    pageIndexes: [],
    allPages: true
  });

  setIsSummarizing(true);

  await askAi.summarizeDocument(length, mode, () => {
    setIsStreaming(true);
  });

  setIsStreaming(false);
  setIsSummarizing(false);
  setUserInteracted(true);
};

const summarizePages = async (pageIndexes: number[], length: SummaryDialogState['length'], mode: SummaryDialogState['mode']) => {
  const { askAi } = await getAskAI();

  logAnalyticsEvent(AnalyticsEventKey.documentAskAi, {
    action: 'generate_summary',
    mode,
    length,
    pageIndexes,
    allPages: false
  });

  setIsSummarizing(true);

  await askAi.summarizePages(
    pageIndexes.map(p => p + 1), // sdk expects pageNumbers from 1 base instead of 0
    length,
    mode,
    () => {
      setIsStreaming(true);
    }
  );

  setIsStreaming(false);
  setIsSummarizing(false);
  setUserInteracted(true);
};

const triggerQuizCompletion = () => {
  setQuizCompletionTrigger();
};

const toggleAutoPlay = (source: 'chat' | 'quiz' = 'chat') => {
  useAskAiStore.setState(s => {
    const toggledValue = !s.autoPlay;

    logAnalyticsEvent(AnalyticsEventKey.documentAskAiAutoplayResponses, {
      state: toggledValue ? 'on' : 'off',
      source
    });

    return { autoPlay: toggledValue };
  });
};

export const askAiStoreActions = {
  answerQuizQuestion,
  cleanUpAskAi,
  createQuiz,
  deleteChat,
  fetchChats: fetchAskAiChatsForCurrentDocument,
  initializeAskAiStore,
  refreshQuiz,
  regenerate,
  retakeQuiz,
  sendPrompt,
  setAutoPlay,
  setCurrentlyPlayingMessagePlayButtonInView,
  setPlaybackInfo,
  setUserInteracted,
  summarizeCurrentPage,
  summarizeDocument,
  summarizePages,
  toggleAutoPlay,
  triggerQuizCompletion
};
