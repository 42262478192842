import { subscriptionStoreSelectors } from '../stores/selectors';
import { useSubscriptionStore } from '../stores/subscriptionStore';

export const waitForSubscriptionStoreToLoad = (): Promise<void> => {
  if (!subscriptionStoreSelectors.getIsLoading()) return Promise.resolve();
  return new Promise(resolve => {
    const unsubscribe = useSubscriptionStore.subscribe(state => {
      if (!subscriptionStoreSelectors.getIsLoading(state)) return;
      unsubscribe();
      resolve();
    });
  });
};
