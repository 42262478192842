import React from 'react';

export default function HumorIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_208_1308)">
        <g clipPath="url(#clip1_208_1308)">
          <path
            d="M41.4579 20.7429C39.4547 30.6342 36.6568 44.3674 36.6568 44.3674C43.5523 46.4934 51.165 48.5552 53.8174 39.8441C54.1081 38.8894 53.5405 37.9051 52.599 37.574L52.4917 37.5363C51.4711 37.1774 50.3491 37.7219 49.6226 38.5236C48.948 39.268 47.9268 39.9547 46.5095 39.8022C43.6 39.489 41.3199 36.4637 41.4579 20.7429Z"
            fill="#FF9500"
          />
          <path
            d="M30 52C23.7334 52 24.8797 39.8443 27.7669 23.1838C29.3335 14.1434 33.6157 10.1883 36.1223 10.1883C39.2556 10.1883 40.8223 8.49327 42.3889 15.2736C43.6171 20.5891 40.9336 32.85 41.8085 42.9105C41.8441 43.3195 41.9387 43.7195 42.0811 44.1046C43.4964 47.933 40.6649 52 36.5832 52H30Z"
            fill="#FF9500"
          />
          <path
            d="M25.3134 16.9343C28.1763 7.9178 33.4328 9.04513 38 9.04509C35.4627 20.8792 32.9254 34.4037 29.8806 43.304C26.8358 52.2044 24.806 53 20.2388 53C17.8265 53 13.4324 53 9.82281 53C6.44805 53 3.48507 50.5948 3.20177 47.2319C2.9594 44.3549 3.12152 41.975 4.84127 44.504C6.50192 46.9461 9.25522 47.3171 11.5901 47.079C14.3727 46.7952 16.3398 44.4992 17.2245 41.8458C18.8054 37.1044 21.6458 28.4854 25.3134 16.9343Z"
            fill="#FFC044"
          />
          <path
            d="M7.64154 53H21C13.0839 53 10.6101 51.3333 9.12581 49.1111C7.7215 47.0086 6.64191 44.1734 6.28447 40.6071C6.14066 39.1722 4.98823 38 3.54614 38C2.44187 38 1.44774 38.692 1.12297 39.7475C-0.859843 46.1912 -1.0512 53 7.64154 53Z"
            fill="#FFD78A"
          />
          <path
            d="M40.6138 2.28735C40.8063 1.64574 40.3258 1 39.656 1H36.8198C36.3431 1 35.9327 1.33646 35.8392 1.80388L34 11H38L40.6138 2.28735Z"
            fill="#FFC044"
          />
          <path
            d="M37.5 52C31.6433 52 33.061 38.7369 33.061 22.1579C33.061 12.7632 35.1573 8.99998 37.5 8.99998C40.4284 8.99998 44 8.50006 43.3104 17C42.8787 22.3197 41.8279 30.6728 42.2386 39.3645C42.3411 41.5342 43.9397 43.3115 46.0627 43.7709L53.5199 45.3845C54.7842 45.658 55.4602 47.0418 54.8987 48.2072L54.7076 48.604C53.7075 50.6801 51.609 52 49.3045 52L37.5 52Z"
            fill="#FFC044"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_208_1308">
          <rect width="56" height="56" fill="white" />
        </clipPath>
        <clipPath id="clip1_208_1308">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
