import assert from 'assert';

import { FeatureNameEnum, SpeedRampEnabledByDefaultVariant } from 'config/constants/featureDefinitions';
import { getFeatureVariant } from 'hooks/useFeatureFlags';
import { createLocalStorageStore } from 'lib/zustand';
import { AnalyticsEventKey, AnalyticsEventProperty } from 'modules/analytics/analyticsTypes';
import { logAnalyticsEventOnSpeedChange, logAnalyticsEventOnSpeedRampChange } from 'modules/analytics/impl/playbackAnalytics';
import { usePlaybackStore } from 'modules/listening/stores/playback/playbackStore';
import backwardsCompatibility from 'modules/listening/utils/extension-compatibility';
import { MeasurementKey } from 'modules/profiling/measurementTypes';
import { profilingStoreActions } from 'modules/profiling/profilingStore';
import { SideBannerType, sideBannerActions } from 'modules/sideBanner/stores/sideBannerStore';
import { subscriptionStoreSelectors } from 'modules/subscription/stores/selectors';
import { waitForSubscriptionStoreToLoad } from 'modules/subscription/utils/waitForSubscriptionStoreToLoad';
import { addToast } from 'modules/toast/stores/actions';
import { ExtensionMessageEmitter } from 'utils/extension';
import { getLocalPreference } from 'utils/preferences';

import {
  BASE_FACTOR_SPEED_WPM,
  MAXIMUM_FREE_RELATIVE_SPEED,
  MAXIMUM_FREE_WPM,
  MAXIMUM_PREMIUM_RELATIVE_SPEED,
  MAXIMUM_PREMIUM_WPM,
  MINIMUM_RELATIVE_SPEED,
  MINIMUM_WPM
} from '../utils/constants';

type SpeedState = {
  currentSpeedInWPM: number;
  isInAutomaticIncreaseSpeedMode: boolean | undefined;
  showAutoIncreasedSpeedAnimation: boolean;
  wordsListenedSinceLastIncrease: number;
};

type SpeedChangeSource = AnalyticsEventProperty<AnalyticsEventKey.playerSpeedChanged>['source'];

let cleanUpPreviousWordsListenedListener: () => void = () => {};

const DEFAULT_SPEED_IN_WPM = 200;

export const useSpeedStore = createLocalStorageStore<SpeedState>(
  () => {
    return {
      currentSpeedInWPM: BASE_FACTOR_SPEED_WPM,
      isInAutomaticIncreaseSpeedMode: undefined,
      showAutoIncreasedSpeedAnimation: false,
      wordsListenedSinceLastIncrease: 0
    };
  },
  {
    storageName: 'speechifySpeedPreference',
    version: 1
  }
);

export const initializeSpeedStore = async () => {
  const { isInAutomaticIncreaseSpeedMode } = useSpeedStore.getState();

  const cleanupExtensionSetSpeedMessageListener = ExtensionMessageEmitter.on('setSpeed', ({ speed }) => {
    const currentSpeed = speedStoreSelectors.relativeSpeed(useSpeedStore.getState());
    if (speed !== currentSpeed) setSpeed(speed, true);
  });

  useSpeedStore.registerOnResetCleanup(cleanupExtensionSetSpeedMessageListener);

  if (!isInAutomaticIncreaseSpeedMode) {
    if (isInAutomaticIncreaseSpeedMode === undefined) {
      const variant = await getFeatureVariant(FeatureNameEnum.WEB_SPEED_RAMP_ENABLED_BY_DEFAULT);

      // true by default?
      if (variant === SpeedRampEnabledByDefaultVariant.ENABLED) {
        useSpeedStore.setState(s => ({ ...s, isInAutomaticIncreaseSpeedMode: true }));
        initializeAutomaticSpeedIncreaseMode();
      } else {
        useSpeedStore.setState(s => ({ ...s, isInAutomaticIncreaseSpeedMode: false }));
      }
    }

    return;
  }

  initializeAutomaticSpeedIncreaseMode();
};

// minutes needed to reach the next speed range
const SPEED_RANGES = [
  { min: 0.5, max: 1.0, time: 3 },
  { min: 1.1, max: 1.5, time: 4 },
  { min: 1.6, max: 2.0, time: 5 },
  { min: 2.1, max: 2.5, time: 10 },
  { min: 2.6, max: 3.0, time: 20 },
  { min: 3.1, max: 3.5, time: 20 },
  { min: 3.6, max: 4.0, time: 25 },
  { min: 4.1, max: 4.5, time: 25 }
];

const DEFAULT_WORDS_THRESHOLD = 600; // 3 minutes at base speed

const roundToNearest50 = (num: number): number => Math.round(num / 50) * 50;
const rountToOneDecimal = (num: number): number => Math.round(num * 10) / 10;

export const calculateWordsThreshold = (playbackSpeed: number): number => {
  const roundedSpeed = rountToOneDecimal(playbackSpeed);

  const range = SPEED_RANGES.find(r => roundedSpeed >= r.min && roundedSpeed <= r.max);

  if (!range) {
    return DEFAULT_WORDS_THRESHOLD;
  }

  const wordsPerMinute = BASE_FACTOR_SPEED_WPM * roundedSpeed;
  const words = Math.round(wordsPerMinute * range.time);

  return roundToNearest50(words);
};

const getPersistedWordCountSinceLastSpeedChange = () => Number.parseInt(localStorage.getItem('wordCountSinceLastSpeedChange') ?? '0', 10);
const saveWordCountSinceLastSpeedChange = (count: number) => localStorage.setItem('wordCountSinceLastSpeedChange', count.toString());

const initializeAutomaticSpeedIncreaseMode = () => {
  cleanUpPreviousWordsListenedListener?.();
  setWordsListenedSinceLastIncrease(getPersistedWordCountSinceLastSpeedChange());

  const { currentPlaybackInfo } = usePlaybackStore.getState();

  assert(currentPlaybackInfo, 'currentPlaybackInfo should be defined when initializeSpeedStore is called');

  let lastCount = 0;

  cleanUpPreviousWordsListenedListener = currentPlaybackInfo.addWordsListenedListener(({ count }) => {
    const { currentSpeedInWPM, wordsListenedSinceLastIncrease } = useSpeedStore.getState();

    const wordsConsumed = Math.abs(count - lastCount);

    if (wordsConsumed >= 20) {
      lastCount = count;
      setWordsListenedSinceLastIncrease(wordsListenedSinceLastIncrease + wordsConsumed);
      saveWordCountSinceLastSpeedChange(wordsListenedSinceLastIncrease + wordsConsumed);
    }

    if (wordsListenedSinceLastIncrease + wordsConsumed) {
      const playbackSpeed = currentSpeedInWPM / BASE_FACTOR_SPEED_WPM;
      const wordThreshold = calculateWordsThreshold(playbackSpeed);

      if (wordsListenedSinceLastIncrease + wordsConsumed >= wordThreshold) {
        setSpeedInWPM(currentSpeedInWPM + 10, 'autoIncrease');
      }
    }
  });
};

const clampSpeed = (speed: number) => {
  const maxSpeed = subscriptionStoreSelectors.getIsPremium() ? MAXIMUM_PREMIUM_RELATIVE_SPEED : MAXIMUM_FREE_RELATIVE_SPEED;
  return Math.max(MINIMUM_RELATIVE_SPEED, Math.min(speed, maxSpeed));
};

const clampSpeedInWPM = (speedInWPM: number) => {
  const maxWpm = subscriptionStoreSelectors.getIsPremium() ? MAXIMUM_PREMIUM_WPM : MAXIMUM_FREE_WPM;
  return Math.max(MINIMUM_WPM, Math.min(speedInWPM, maxWpm));
};

export const fetchInitialSpeedInWPM = async (): Promise<number> => {
  await waitForSubscriptionStoreToLoad();
  await profilingStoreActions.measureAction(() => useSpeedStore.waitForInitialHydration(), MeasurementKey.zustandIdbHydration, {
    storeName: 'speedStore'
  });

  const speedInWPM = useSpeedStore.getState().currentSpeedInWPM ?? DEFAULT_SPEED_IN_WPM;
  return clampSpeedInWPM(speedInWPM);
};

const onAutomaticIncreaseSpeedPreferenceChange = (isEnabled: boolean) => {
  logAnalyticsEventOnSpeedRampChange({ value: isEnabled });

  if (!isEnabled) {
    cleanUpPreviousWordsListenedListener();
    useSpeedStore.setState(s => ({ ...s, isInAutomaticIncreaseSpeedMode: false, wordsListenedSinceLastIncrease: 0 }));
    saveWordCountSinceLastSpeedChange(0);
    return;
  }

  useSpeedStore.setState(s => ({ ...s, isInAutomaticIncreaseSpeedMode: true }));
  initializeAutomaticSpeedIncreaseMode();
};

const roundToNearest005 = (num: number): number => {
  const scaled = num * 40;
  const rounded = Math.round(scaled);
  return rounded / 40;
};

const setSpeed = (speed: number, isChangeFromExtension = false) => {
  const { currentPlaybackInfo } = usePlaybackStore.getState();
  if (!currentPlaybackInfo) return;

  // min 0.5, max 4.5
  const currentSpeed = roundToNearest005(clampSpeed(speed));
  const currentSpeedInWPM = Math.round(currentSpeed * BASE_FACTOR_SPEED_WPM);

  useSpeedStore.setState(s => ({ ...s, currentSpeedInWPM, wordsListenedSinceLastIncrease: 0 }));
  currentPlaybackInfo.controls.setRelativeSpeed(currentSpeed);

  saveWordCountSinceLastSpeedChange(0);

  // legacy support
  if (!isChangeFromExtension) backwardsCompatibility.setPlaybackSpeed(currentSpeed);

  logAnalyticsEventOnSpeedChange({
    speed: currentSpeed,
    wpm: currentSpeedInWPM,
    source: 'speedUI'
  });
};

const setSpeedInWPM = (speedInWPM: number, source: SpeedChangeSource) => {
  const { currentPlaybackInfo } = usePlaybackStore.getState();
  if (!currentPlaybackInfo) return;

  const clampedSpeedInWPM = clampSpeedInWPM(speedInWPM);

  // auto increase? user already at max speed, upsell
  if (source === 'autoIncrease' && speedInWPM > clampedSpeedInWPM && clampedSpeedInWPM === MAXIMUM_FREE_WPM) {
    const usersCurrentSpeedInWPM = useSpeedStore.getState().currentSpeedInWPM;

    if (usersCurrentSpeedInWPM === MAXIMUM_FREE_WPM) {
      const alreadyShown = getLocalPreference('shownUnlimitedSpeedPopup') == 'true';

      if (!alreadyShown) {
        sideBannerActions.add({
          type: SideBannerType.UNLIMITED_SPEED
        });
      } else {
        // upsell already shown, another auto speed increase triggered, turn off auto increase setting.
        onAutomaticIncreaseSpeedPreferenceChange(false);
      }

      return;
    }
  }

  useSpeedStore.setState(s => ({
    ...s,
    currentSpeedInWPM: clampedSpeedInWPM,
    showAutoIncreasedSpeedAnimation: source === 'autoIncrease',
    wordsListenedSinceLastIncrease: 0
  }));

  currentPlaybackInfo.controls.setSpeed(clampedSpeedInWPM);

  saveWordCountSinceLastSpeedChange(0);

  setTimeout(() => {
    useSpeedStore.setState(s => ({ ...s, showAutoIncreasedSpeedAnimation: false }));
  }, 3000);

  logAnalyticsEventOnSpeedChange({
    speed: clampedSpeedInWPM / BASE_FACTOR_SPEED_WPM,
    wpm: clampedSpeedInWPM,
    source: source
  });
};

const incrementSpeed = (source: SpeedChangeSource) => {
  const currentSpeed = useSpeedStore.getState().currentSpeedInWPM;
  setSpeedInWPM(currentSpeed + 0.1 * BASE_FACTOR_SPEED_WPM, source);
};

const decrementSpeed = (source: SpeedChangeSource) => {
  const currentSpeed = useSpeedStore.getState().currentSpeedInWPM;
  setSpeedInWPM(currentSpeed - 0.1 * BASE_FACTOR_SPEED_WPM, source);
};

const switchToFreeSpeed = () => {
  const currentSpeedInWPM = useSpeedStore.getState().currentSpeedInWPM;
  const clampedSpeedInWPM = clampSpeedInWPM(currentSpeedInWPM);
  setSpeedInWPM(clampedSpeedInWPM, 'switchToFree');
};

const switchToNonPremiumSpeed = () => {
  const currentSpeedInWPM = useSpeedStore.getState().currentSpeedInWPM;
  const clampedSpeedInWPM = Math.max(MINIMUM_WPM, Math.min(currentSpeedInWPM, 400));
  setSpeedInWPM(clampedSpeedInWPM, 'switchToNonPremium');

  if (currentSpeedInWPM > 400) {
    addToast({
      title: 'Maximum speed for this voice is 400 wpm',
      description: '',
      type: 'info'
    });
  }
};

const setWordsListenedSinceLastIncrease = (wordsListened: number) => {
  useSpeedStore.setState(s => ({ ...s, wordsListenedSinceLastIncrease: wordsListened }));
};

export const speedStoreActions = {
  decrementSpeed,
  incrementSpeed,
  initializeSpeedStore,
  onAutomaticIncreaseSpeedPreferenceChange,
  setSpeed,
  setWordsListenedSinceLastIncrease,
  switchToFreeSpeed,
  switchToNonPremiumSpeed
};

export const speedStoreSelectors = {
  relativeSpeed: (state: SpeedState) => {
    const relativeSpeed = state.currentSpeedInWPM / BASE_FACTOR_SPEED_WPM;
    return Number(relativeSpeed.toFixed(2));
  },
  isInAutomaticIncreaseSpeedMode: (state: SpeedState) => state.isInAutomaticIncreaseSpeedMode,
  showAutoIncreasedSpeedAnimation: (state: SpeedState) => state.showAutoIncreasedSpeedAnimation
};
