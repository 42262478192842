import { PlatformClient } from '@speechifyinc/platform-public';

import { ErrorSource } from 'config/constants/errors';
import { logError } from 'lib/observability';
import { auth } from 'lib/speechify';
import { findTtsSubscription, hasStudioSubscription } from 'modules/subscription/utils/subscriptionUtils';

import { PlatformServiceFacade } from './_base';
import { PlatformSubscriptionEntitlements } from './types';

export class PlatformSubscriptionsFacade extends PlatformServiceFacade {
  private static _singleton: PlatformSubscriptionsFacade;
  constructor(client: PlatformClient) {
    super(client);
    PlatformSubscriptionsFacade._singleton = this;
  }

  private get paymentService() {
    // All platform services are lazy loaded
    return this.client.payment;
  }

  private get subscriptionService() {
    // All platform services are lazy loaded
    return this.paymentService.subscriptions;
  }

  private get oneClickService() {
    // All platform services are lazy loaded
    return this.paymentService.oneClick;
  }

  static override get singleton(): PlatformSubscriptionsFacade {
    return PlatformSubscriptionsFacade._singleton;
  }

  public async skipTrial() {
    await this.subscriptionService.skipTrial({}, { headers: await this.getHeaders() });
  }

  public async cancelSubscription(subscriptionId?: string) {
    await this.subscriptionService.cancelSubscription({ subscriptionId }, { headers: await this.getHeaders() });
  }

  public async extendTrial() {
    await this.subscriptionService.extendTrial({ headers: await this.getHeaders() });
  }

  public async willChargeUserForRenewal() {
    const status = await this.oneClickService.oneClickStatus({}, { headers: await this.getHeaders() });

    // Return null if the status is not available for back-compatibility with old lib/speechify api
    if (!status.isAvailable) return null;
    return status.willChargeUser;
  }

  public async renewCancelledSubscription() {
    await this.oneClickService.oneClickRenew({}, { headers: await this.getHeaders() });
  }

  public async getAllSubscriptions(): Promise<PlatformSubscriptionEntitlements> {
    return this.subscriptionService.getAllSubscriptions({ headers: await this.getHeaders() });
  }

  public async getSubscriptionAndEntitlements() {
    const { subscriptions, entitlements } = await this.getAllSubscriptions();

    return {
      subscription: findTtsSubscription(subscriptions),
      entitlements,
      hasStudioSubscription: hasStudioSubscription(subscriptions)
    };
  }

  public async getBillingDashboardUrl(id: string) {
    try {
      const { url } = await this.client.payment.portal.createPortal({ userId: auth.currentUser!.uid, extensionId: id }, { headers: await this.getHeaders() });
      return url;
    } catch (error) {
      logError(error as Error, ErrorSource.SUBSCRIPTION);
      return null;
    }
  }
}
