import { RecordType } from 'interfaces';

import { LibraryFilterType } from './filter';
import { LibraryShowFolderType } from './folder';
import { LibrarySortBy } from './sort';
import { LibrarySortOrder } from './sort';

export interface LibraryFilterAndSortOptions {
  filterType: LibraryFilterType;
  sortBy: LibrarySortBy;
  sortOrder: LibrarySortOrder;
}

export interface LibraryItemToAction {
  id: string;
  parentFolderId?: string;
}

export type LibraryFolderSubscriptionConfig = {
  sortBy: LibrarySortBy;
  sortOrder: LibrarySortOrder;
  filterType: LibraryFilterType;
  showFolderType: LibraryShowFolderType;
};

export const MAP_RECORD_TYPE_TO_LIBRARY_FILTER_TYPE = {
  [RecordType.PDF]: LibraryFilterType.PDF,
  [RecordType.EPUB]: LibraryFilterType.EPUB,
  [RecordType.SCAN]: LibraryFilterType.SCANS,
  [RecordType.TXT]: LibraryFilterType.TEXT,
  [RecordType.WEB]: LibraryFilterType.WEB_LINKS,
  [RecordType.FILE]: LibraryFilterType.PDF,
  [RecordType.DEFAULT]: LibraryFilterType.PDF,
  [RecordType.UNKNOWN]: LibraryFilterType.PDF
};

export * from './sort';
export * from './filter';
export * from './folder';
