import React from 'react';

export default function CrimeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15 27.5428V39.8785C15 42.1548 16.8452 44 19.1214 44V44C20.8505 44 22.3957 42.9207 22.991 41.2974L27.5 28.9999L32.9808 14.6653C33.5946 13.06 35.1351 11.9998 36.8537 11.9998V11.9998C39.1436 11.9998 41 13.8561 41 16.1461V28.4569"
        stroke="#FFC044"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M33 28C33 26.8954 33.8954 26 35 26H47C48.1046 26 49 26.8954 49 28V35H33V28Z" fill="#3B4EFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41 53.9999C48.1797 53.9999 54 48.9373 54 42.6922C54 36.4471 48.1797 31.3845 41 31.3845C33.8203 31.3845 28 36.4471 28 42.6922C28 48.9373 33.8203 53.9999 41 53.9999ZM41 49.6921C45.9706 49.6921 50 46.5581 50 42.6921C50 38.8261 45.9706 35.6921 41 35.6921C36.0294 35.6921 32 38.8261 32 42.6921C32 46.5581 36.0294 49.6921 41 49.6921Z"
        fill="#3B4EFF"
      />
      <path d="M23 28C23 29.1046 22.1046 30 21 30L9 30C7.89543 30 7 29.1046 7 28L7 21L23 21L23 28Z" fill="#3B4EFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 2.00009C7.8203 2.00009 2 7.06272 2 13.3078C2 19.5528 7.8203 24.6155 15 24.6155C22.1797 24.6155 28 19.5529 28 13.3078C28 7.06272 22.1797 2.00009 15 2.00009ZM15 6.30786C10.0294 6.30786 6 9.44187 6 13.3079C6 17.1739 10.0294 20.3079 15 20.3079C19.9706 20.3079 24 17.1739 24 13.3079C24 9.44187 19.9706 6.30786 15 6.30786Z"
        fill="#3B4EFF"
      />
    </svg>
  );
}
