import type { IUser } from 'interfaces';

import { EventEmitter, createEventEmitter } from 'lib/speechify/EventEmitter';

export const currentUserEmitter: EventEmitter<{
  onUserUpdate: {
    user: IUser | null;
  };
  onUserLogout: {};
}> = createEventEmitter({ events: ['onUserUpdate', 'onUserLogout'] });
