import React from 'react';

export default function HorrorIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.16667 16.7838C10.4074 6.67568 21.9815 3 28 3V54H3L3.00759 53.7816C3.4681 40.5252 3.94363 26.8367 7.16667 16.7838Z" fill="#3B4EFF" />
      <path d="M48.8333 16.7838C45.5926 6.67568 34.0185 3 28 3V54H53L52.9924 53.7816C52.5319 40.5252 52.0564 26.8367 48.8333 16.7838Z" fill="#3B4EFF" />
      <path
        d="M37.6535 34.7042C38.1965 32.9432 39.78 31.6962 41.6113 31.4903C45.6472 31.0365 47 29.6679 47 25.7277C47 18.9164 42.5532 7 28 7C13.4468 7 9 18.9164 9 25.7277C9 29.6679 10.3528 31.0365 14.3887 31.4903C16.22 31.6962 17.8035 32.9432 18.3465 34.7042C18.5433 35.3426 18.7473 35.979 18.9455 36.6164C20.7677 42.4745 20.7619 50 28 50C35.2381 50 35.2323 42.4745 37.0545 36.6164C37.2527 35.979 37.4567 35.3426 37.6535 34.7042Z"
        fill="#B5E8FF"
      />
      <path d="M16 22C19.7059 22 22.3412 18.88 23 16" stroke="#3B4EFF" strokeWidth="5" strokeLinecap="round" />
      <path d="M40 22C36.2941 22 33.6588 18.88 33 16" stroke="#3B4EFF" strokeWidth="5" strokeLinecap="round" />
      <path d="M22.2359 35.2381C20.9638 29.614 25.1169 28 28 28V47C24.8962 47 23.5661 41.1191 22.2359 35.2381L22.2359 35.2381Z" fill="#3B4EFF" />
      <path d="M33.7641 35.2381C35.0362 29.614 30.8831 28 28 28V47C31.1038 47 32.4339 41.1191 33.7641 35.2381L33.7641 35.2381Z" fill="#3B4EFF" />
    </svg>
  );
}
