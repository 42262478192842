import React from 'react';

export default function AdventureIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_208_2457)">
        <path d="M18.1641 6.34139L10.4863 5.53442L38.6568 40.3221L41.2022 38.2608L18.1641 6.34139Z" fill="#6BD4FE" />
        <path d="M9.67938 13.2116L10.4863 5.53382L38.6568 40.3214L36.1114 42.3827L9.67938 13.2116Z" fill="#3CC0FD" />
        <path
          d="M38.4538 34.8662L33.3629 38.9887L42.295 50.0189C43.4334 51.4248 45.4959 51.6415 46.9017 50.5031C48.3075 49.3647 48.5243 47.3022 47.3859 45.8964L38.4538 34.8662Z"
          fill="#3B4EFF"
        />
        <path d="M29.1203 42.4241L35.9081 36.9274L39.3436 41.1698L33.687 45.7504L29.1203 42.4241Z" fill="#3B4EFF" />
        <path d="M42.6964 31.4307L35.9086 36.9273L39.344 41.1697L45.0005 36.5892L42.6964 31.4307Z" fill="#3B4EFF" />
        <path d="M34.5339 35.2312L33.8219 42.0056L40.5964 42.7177L41.3084 35.9432L34.5339 35.2312Z" fill="#3B4EFF" />
        <path d="M35.4905 6.34188L43.1684 5.53491L14.9979 40.3226L12.4524 38.2613L35.4905 6.34188Z" fill="#6BD4FE" />
        <path d="M43.9756 13.2116L43.1686 5.53382L14.9981 40.3214L17.5436 42.3827L43.9756 13.2116Z" fill="#3CC0FD" />
        <path
          d="M15.2009 34.8665L20.2917 38.989L11.3596 50.0192C10.2212 51.425 8.15875 51.6418 6.75295 50.5034C5.34714 49.365 5.13037 47.3025 6.26877 45.8967L15.2009 34.8665Z"
          fill="#3B4EFF"
        />
        <path d="M24.5344 42.4243L17.7465 36.9276L14.3111 41.17L19.9676 45.7506L24.5344 42.4243Z" fill="#3B4EFF" />
        <path d="M10.9586 31.4318L17.7464 36.9284L14.311 41.1708L8.65444 36.5903L10.9586 31.4318Z" fill="#3B4EFF" />
        <path d="M19.1209 35.231L19.8329 42.0054L13.0585 42.7174L12.3465 35.943L19.1209 35.231Z" fill="#3B4EFF" />
      </g>
      <defs>
        <clipPath id="clip0_208_2457">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
