import { Readability } from '@mozilla/readability';
import { IRecord, ItemType } from 'interfaces';
import { uniq } from 'lodash';
import nookies from 'nookies';
import NProgress from 'nprogress';

import { logError } from 'lib/observability';
import { BASE_FACTOR_SPEED_WPM } from 'modules/speed/utils/constants';

import { isServerSide } from './environment';

export function base64ToBuffer(b64String: string): ArrayBuffer {
  return Buffer.from(b64String, 'base64');
}

export function bufferToBase64(buffer: ArrayBuffer) {
  return btoa(
    new Uint8Array(buffer).reduce((data, byte) => {
      return data + String.fromCharCode(byte);
    }, '')
  );
}

export function carryParams(route: string, extra?: { [key: string]: string | number }) {
  const existingURLParams = new URLSearchParams(window.location.search);

  // we want to have this param only once
  existingURLParams.delete('trigger');

  if (extra) {
    Object.entries(extra).forEach(([key, val]) => {
      if (val) {
        return existingURLParams.set(key, val.toString());
      } else {
        return existingURLParams.delete(key);
      }
    });
  }

  const params = existingURLParams.toString();
  return `${route}${params ? `?${params}` : ''}`;
}

export function descendantCount(
  allItems: $TSFixMe[] = [],
  parent: $TSFixMe,
  idProperty: string = 'id',
  parentIdProperty: string = 'parentFolderId',
  itemType: ItemType = ItemType.Record
): number {
  return descendantIds(allItems, parent, idProperty, parentIdProperty, itemType).length;
}

export function descendantIds(
  allItems: $TSFixMe[] = [],
  parent: $TSFixMe,
  idProperty: string = 'id',
  parentIdProperty: string = 'parentFolderId',
  itemType?: ItemType
): string[] {
  const ids: $TSFixMe = [];

  allItems.forEach(child => {
    if (isDescendant(allItems, parent, child, idProperty, parentIdProperty)) {
      if (itemType) {
        if (child.type === itemType) {
          ids.push(child.id);
        }
      } else {
        ids.push(child.id);
      }
    }
  });

  return uniq(ids);
}

export const doItemsMatch = (left: IRecord, right: IRecord) => {
  const idsMatch = left?.id === right?.id;
  const titlesMatch = left?.title === right?.title;
  const characterIndexMatch = left?.characterIndex === right?.characterIndex;

  return idsMatch && titlesMatch && characterIndexMatch;
};

export const getCookieByName = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const getHostname = (value: string) => {
  try {
    const url = new URL(value);
    return url.hostname.replace('www.', '');
  } catch (error) {
    logError(error);
    return value;
  }
};

export const getFolderDepth = (folders: IRecord[] = [], folderId: string) => {
  if (!folderId) return 0;

  // root
  const rootIds = folders.filter(folder => folder.parentFolderId === null).map(folder => folder.id);
  const isRoot = rootIds.filter(rootId => rootId === folderId)[0];
  if (isRoot) return 0;

  // first level
  // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const firstLevelIds = folders.filter(folder => rootIds.indexOf(folder.parentFolderId) > -1).map(folder => folder.id);
  const isFirstLevel = firstLevelIds.filter(firstLevelId => firstLevelId === folderId)[0];
  if (isFirstLevel) return 1;

  // second level
  // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const secondLevelIds = folders.filter(folder => firstLevelIds.indexOf(folder.parentFolderId) > -1).map(folder => folder.id);
  const isSecondLevel = secondLevelIds.filter(secondLevelId => secondLevelId === folderId)[0];
  if (isSecondLevel) return 2;
};

export const getInitials = (name: string, maxLength = 3) => {
  if (!name) return '';

  const chars = [...name.trim()];

  if (name.length <= maxLength) return name;

  const initials: string[] = [];

  for (const [idx, char] of chars.entries()) {
    if (char.toLowerCase() !== char || !chars[idx - 1] || /\s/.test(chars[idx - 1])) {
      initials.push(char);

      if (initials.length === maxLength) break;
    }
  }

  return initials.join('');
};

export const getMaxValueIndex = (array: number[]) => getValueIndex(array, (value, tracked) => value > tracked);

// returns the index of the smallest number in an array of numbers
function getValueIndex(array: number[], comparator: (value: number, tracked: number) => boolean) {
  if (array.length === 0) {
    return -1;
  }

  let tracked = array[0];
  let index = 0;

  for (let i = 1; i < array.length; i++) {
    if (comparator(array[i], tracked)) {
      index = i;
      tracked = array[i];
    }
  }

  return index;
}

export const humanFileSize = (size: number): string => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const sizeValue = (size / Math.pow(1024, i)).toFixed(2);
  return sizeValue + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

export const isDescendant = (
  allItems: $TSFixMe[] = [],
  parent: $TSFixMe,
  child: $TSFixMe,
  idProperty: string = 'id',
  parentIdProperty: string = 'parentFolderId',
  depth: number = 2,
  level: number = 0
): boolean => {
  if (!parent) {
    return false;
  }

  if (!child) {
    return false;
  }

  if (!child[parentIdProperty]) {
    return false;
  }

  if (level > depth) {
    return false;
  }

  if (child[parentIdProperty] === parent[idProperty]) {
    return true;
  }

  return isDescendant(
    allItems,
    parent,
    allItems.find(item => item[idProperty] === child[parentIdProperty]),
    idProperty,
    parentIdProperty,
    depth,
    level + 1
  );
};

export const isDescendantById = <T, TId>(
  items: T[],
  parentId: TId,
  childId: TId,
  idProperty: {
    [K in keyof T]: T[K] extends TId ? K : never;
  }[keyof T],
  parentIdProperty: {
    [K in keyof T]: T[K] extends TId | null ? K : never;
  }[keyof T],
  depth: number = 2,
  level: number = 0
): boolean => {
  if (level > depth) {
    return false;
  }

  const child = items.find(item => item[idProperty] === childId);

  if (!child || !child[parentIdProperty]) {
    return false;
  }

  if (child[parentIdProperty] === parentId) {
    return true;
  }

  return isDescendantById(items, parentId, child[parentIdProperty] as TId, idProperty, parentIdProperty, depth, level + 1);
};

export const isLibraryPage = () => {
  return !isServerSide() && new URL(document.location.href).pathname === '/';
};

export const isItemPage = () => {
  return !isServerSide() && new URL(document.location.href).pathname.indexOf('item') > -1;
};

export const isItemOrSharePage = () => {
  if (!isServerSide()) {
    const { pathname } = new URL(document.location.href);

    return pathname.indexOf('item') > -1 || pathname.indexOf('share') > -1;
  }
};

export const parseHTML = (html: string) => {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const reader = new Readability(doc, { charThreshold: 50 });

    return reader.parse();
  } catch (error) {
    logError(error);
  }
};

export const playbackSpeedToUpperTen = (playbackSpeed: number = 1.2) => {
  return Math.ceil(playbackSpeed * 20) / 20;
};

export const playbackSpeedToCategory = (playbackSpeed: number) => {
  if (playbackSpeed <= 1) return 'Slower';
  if (playbackSpeed <= 1.5) return 'Average';
  if (playbackSpeed <= 3) return 'Faster';

  return 'Speed Reader';
};

export const playbackSpeedToWPM = (playbackSpeed: number, baseFactorWPM: number = BASE_FACTOR_SPEED_WPM) => {
  return Math.round(playbackSpeed * baseFactorWPM);
};

export function setAppTokenCookie(token: string | null) {
  nookies.destroy({}, 'authsync', { domain: '.speechify.com' });
  if (token) {
    const date = new Date();
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

    document.cookie = 'lpobtoken=' + (token || '') + '; expires=' + date.toUTCString() + '; path=/; domain=.speechify.com;';
  } else {
    document.cookie = 'lpobtoken=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}

export function sortBy(key: string) {
  return (a: $TSFixMe, b: $TSFixMe) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
}

export function toHTMLDocument(body: string, title: string) {
  return `<!doctype html><html lang="en"><head><meta charset="utf-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>${title}</title></head><body>${body}</body></html>`;
}

export function enumFromStringValue<T>(enm: { [s: string]: T }, value: string): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value) ? (value as unknown as T) : undefined;
}

export const addDocumentElementClass = (className: string): void => {
  const root = document.documentElement;

  if (!root.classList.contains(className)) {
    root.classList.add(className);
  }
};

export const removeDocumentElementClass = (className: string, removeDelay: number = 0): NodeJS.Timeout | undefined => {
  const root = document.documentElement;

  if (root.classList.contains(className)) {
    if (removeDelay) {
      return setTimeout(() => {
        root.classList.remove(className);
      }, removeDelay);
    } else {
      root.classList.remove(className);
    }
  }
};

function wrapNProgressBarWithContainer(maxAttempts: number = 10, interval: number = 300): void {
  let attempts = 0;

  const attemptToWrap = () => {
    const bar = document.querySelector('#nprogress .bar') as HTMLElement | null;

    if (bar) {
      const container = document.createElement('div');
      container.className = 'bar-container';

      if (bar.parentNode) {
        bar.parentNode.insertBefore(container, bar);
        container.appendChild(bar);
      }
    } else {
      attempts += 1;

      if (attempts < maxAttempts) {
        setTimeout(attemptToWrap, interval);
      }
    }
  };

  attemptToWrap();
}

let isProgressInitialized = false;

export const showProgress = () => {
  if (!isProgressInitialized) {
    initNProgress();
    isProgressInitialized = true;
  }

  if (new URL(document.location.href).pathname === '/') {
    NProgress.set(0.01);

    NProgress.start();

    document.querySelector('#nprogress .bar')?.classList.add('active');
  }
};

export const hideProgress = () => {
  NProgress.set(0.99);

  setTimeout(() => {
    document.querySelector('#nprogress .bar')?.classList.remove('active');

    setTimeout(() => {
      NProgress.set(0.01);
    }, 250);
  }, 500);
};

const initNProgress = () => {
  NProgress.configure({ easing: 'ease', showSpinner: false, speed: 250 });
  wrapNProgressBarWithContainer();
};

// Utility function to ensure URLs have a protocol prefix
export function ensureFullUrl(input: string): string {
  // Trim whitespace and check if the URL starts with 'http://' or 'https://'
  const trimmedInput = input.trim();
  const hasProtocol = /^(http:\/\/|https:\/\/)/i.test(trimmedInput);

  return hasProtocol ? trimmedInput : `https://${trimmedInput}`;
}

export const sleep = (ms: number) => new Promise<void>(r => setTimeout(() => r(), ms));
