import type { IUser } from 'interfaces';

import { logError } from 'lib/observability';
import { isNewUser } from 'modules/auth/store/authStore/utils/isNewUser';
import { currentUserEmitter } from 'modules/auth/store/authUserEmitter';
import { bootIntercom } from 'modules/listening/utils/intercom';
import type { SDKSubscriptionFacade } from 'modules/sdk/lib';
import { canUpgrade, isExpired, isOnTrial, isPremium, isStripe } from 'modules/subscription/utils/checkers';

import { useSubscriptionStore } from '../subscriptionStore';

let cleanUpFunctions: (() => void)[] = [];

const cleanUp = () => {
  cleanUpFunctions.forEach(fn => fn());
  cleanUpFunctions = [];
};

export const initializeSubscriptionStore = (subscription: SDKSubscriptionFacade) => {
  cleanUp();

  const handler = (user: IUser | null) => {
    if (!user) {
      return;
    }

    if (!user.entitlements) {
      logError(new Error(`User without entitlements - ${user.uid}`));
    }

    const entitlements = subscription.mapSDKEntitlementsToEntitlementsInfo(user.entitlements);
    useSubscriptionStore.setState({
      entitlements,
      isNewUser: isNewUser(user),
      isLoading: false,
      isOnTrial: isOnTrial(user),
      isExpired: isExpired(user),
      isPremium: isPremium(user),
      isStripe: isStripe(user),
      canUpgrade: canUpgrade(user),
      firstPdfItemId: user.firstPdfDocument || undefined,
      mobileAppInstalled: user.usage?.stats.android != null || user.usage?.stats.iOS != null
    });
    if (isPremium(user)) {
      bootIntercom({ userId: user.uid, email: user.email, name: user.displayName });
    }
  };

  cleanUpFunctions.push(
    currentUserEmitter.on('onUserUpdate', ({ user }: { user: IUser | null }) => {
      handler(user);
    })
  );
};
