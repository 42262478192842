import { MultiplatformSDKInstance } from '../sdk';
import { SDKFacade } from './_base';

export class SDKAccountFacade extends SDKFacade {
  private static _singleton: SDKAccountFacade;
  constructor(sdk: MultiplatformSDKInstance) {
    super(sdk);
    SDKAccountFacade._singleton = this;
  }

  static override get singleton(): SDKAccountFacade {
    return SDKAccountFacade._singleton;
  }

  public getUserUsageData = () => {
    return this.sdk.promisify(this.sdk.client.ecosystemAdoptionService.getUserUsageData.bind(this.sdk.client.ecosystemAdoptionService))();
  };

  public getAccountSettings = () => {
    return this.sdk.promisify(this.sdk.client.accountSettingsService.getSettings.bind(this.sdk.client.accountSettingsService))();
  };

  public setAccountSettings = this.sdk.promisify(this.sdk.client.accountSettingsService.setSettings.bind(this.sdk.client.accountSettingsService));
}
