import { ExtensionSettings, IUser } from 'interfaces';

import * as extension from 'utils/extension';

import { useAuthStore } from '..';

export const setExtensionVoice = async (voice: ExtensionSettings['voice']) => {
  extension.setVoice(voice!);
  useAuthStore.setState(state => ({
    ...state,
    user: {
      ...state.user,
      extensionSettings: {
        ...state.user?.extensionSettings,
        voice: voice
      }
    } as IUser
  }));
};
