import React from 'react';

export default function ThrillerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_208_26)">
        <path
          d="M23.5646 38.9286C23.1252 40.4875 19.4818 55.5114 19.4818 55.5114L32.8194 57.2207C33.2749 55.1056 35.637 44.014 36.2961 41.676C36.7442 40.0864 41.0525 39.7755 42.9814 34.4303C43.8173 32.1138 47.6845 28.8342 50.5908 26.9007C53.4715 24.9843 54.2086 24.3719 53.9547 23.7209C53.4974 22.5488 52.8266 21.8953 51.6416 21.6108C48.8212 20.9336 44.6071 24.464 42.2216 26.645C41.961 26.5714 41.2833 26.3802 40.6577 26.2041C40.9257 23.7562 40.3505 19.8077 40.6801 18.6386C41.0097 17.4695 42.1696 14.8521 42.609 13.2934C43.0484 11.7347 43.8787 10.2863 42.9255 9.17624C41.9722 8.06617 39.5033 4.84657 37.2058 2.51633C34.9082 0.186101 32.8565 2.97281 33.9811 4.97238C35.1056 6.97195 37.4032 9.30219 37.6843 9.80208L36.0366 15.6472C35.9062 15.6106 35.4671 15.5711 34.7537 15.7062C34.0202 13.8168 33.9457 9.58943 32.9924 8.47943L29.1793 4.03934C27.2728 1.81933 25.6604 3.04735 25.1112 4.99571C24.5619 6.94407 28.4234 9.71519 28.8759 11.1046C28.8371 12.4398 29.211 15.4055 29.4028 16.7215L28.4011 17.2804C27.2399 15.4109 24.5141 11.3058 22.901 9.84148C20.8846 8.01114 19.5534 9.73905 17.6599 10.4672C15.7664 11.1953 15.8278 12.4746 17.1721 13.6948C18.5163 14.915 19.127 14.2459 20.5197 13.7972C21.1066 15.3087 23.3882 19.0927 24.4557 20.7957L23.845 21.4646C21.6822 20.1538 17.0999 17.5441 16.0736 17.5913C14.7908 17.6503 13.2333 19.7728 12.6292 20.8267C11.7988 22.2754 12.6422 23.775 14.316 23.8262C15.655 23.8672 16.1891 22.6715 16.4292 22.3186L21.4637 25.4203C21.4153 27.0893 21.3431 30.9388 21.4414 32.9855C21.5397 35.0323 24.0041 37.3697 23.5646 38.9286Z"
          fill="#3B4EFF"
        />
        <path
          d="M0.119873 59.1385C0.119873 55.2322 0.119873 53.2791 1.33339 52.0656C2.5469 50.8521 4.50003 50.8521 8.40628 50.8521H56V60H0.119873V59.1385Z"
          fill="#22CC97"
        />
        <path
          d="M5 51.5001C5 51.0196 5 50.7793 5.0138 50.5765C5.21683 47.5925 7.59235 45.217 10.5763 45.014C10.7791 45.0002 11.0194 45.0002 11.4999 45.0002L56 45V58L11.4999 58C11.0194 58 10.7791 58 10.5763 57.9862C7.59237 57.7832 5.21683 55.4076 5.0138 52.4237C5 52.2209 5 51.9806 5 51.5001Z"
          fill="#22CC97"
        />
        <rect x="9.25061" y="34.9395" width="6.06218" height="6.06218" rx="2.0716" fill="#22CC97" />
        <rect x="4.55963" y="30.8257" width="4.11358" height="4.11358" rx="1.40572" fill="#22CC97" />
      </g>
      <defs>
        <clipPath id="clip0_208_26">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
