import React from 'react';

export default function RomanceIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_208_8286)">
        <circle cx="29" cy="8" r="8" fill="#D9251A" />
        <path
          d="M31 31C31 29.8954 30.1046 29 29 29C27.8954 29 27 29.8954 27 31L31 31ZM27 52L27 54L31 54L31 52L27 52ZM27 31L27 52L31 52L31 31L27 31Z"
          fill="#26E2A8"
        />
        <path d="M27.6935 47.8988C27.8659 40.8974 10.9955 29.8662 2.52008 33.3649C3.72777 42.4542 21.7163 51.5488 27.6935 47.8988Z" fill="#26E2A8" />
        <path d="M30.5158 47.8988C30.3434 40.8974 47.2139 29.8662 55.6893 33.3649C54.4816 42.4542 36.493 51.5488 30.5158 47.8988Z" fill="#26E2A8" />
        <path
          d="M10 4.84766C10 2.89428 11.8371 1.4619 13.7315 1.93821L23.4384 4.3788C27.8837 5.49648 31 9.49329 31 14.077V34H25C16.7157 34 10 27.2843 10 19V4.84766Z"
          fill="#FF4B40"
        />
        <path
          d="M48 4.84766C48 2.89428 46.1629 1.4619 44.2685 1.93821L34.5616 4.3788C30.1163 5.49648 27 9.49329 27 14.077V34H33C41.2843 34 48 27.2843 48 19V4.84766Z"
          fill="#FF4B40"
        />
      </g>
      <defs>
        <clipPath id="clip0_208_8286">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
