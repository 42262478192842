import { Nullable } from 'utils/types';

export type APIMessageContent = {
  type: 'prompt' | 'reference' | 'response' | 'summary';

  prompt?: {
    type: 'text' | 'generateSummaryForDocument' | 'generateSummaryForPages';
    body?: string;
    options?: APISummaryOptions;
  };

  referenceEntity?: 'quiz';
  reference?: string;

  response?: {
    type: 'text' | 'summary';
    body: string;
    regenerationCount: number;
    feedback: null;
    options?: APISummaryOptions;
  };

  body?: string;
  options?: APISummaryOptions;

  feedback?: {
    type: 'positive' | 'negative';
    text: string | null;
  };
};

export type APIMessage = {
  id: string;
  chatId: string;
  message: APIMessageContent;
  createdAt: FirebaseTimestamp;
  updatedAt: FirebaseTimestamp;
  contextAssets?: string[];
};

export type APISummaryOptions = {
  length: SummaryLengthType;
  format: SummaryFormatType;
  pageIndexes?: number[];
};

export type ChatRecord = {
  id: string;
  subject: {
    type: string;
    source: {
      type: string;
      id: string;
      documentType: string;
    };
  };
  createdAt: string;
  lastActiveAt: string;
};

export type ChatMessage =
  | { id: string; createdAt: FirebaseTimestamp; type: 'reply'; message: ReplyMessage }
  | { id: string; createdAt: FirebaseTimestamp; type: 'summary'; message: SummaryMessage }
  | {
      id: string;
      createdAt: FirebaseTimestamp;
      type: 'summary_loading';
      options: {
        mode: SummaryFormatTuple;
        length: SummaryLengthTuple;
        pageIndexes?: number[];
      };
    }
  | { id: string; createdAt: FirebaseTimestamp; type: 'user'; message: UserMessage }
  | { id: string; createdAt: FirebaseTimestamp; type: 'quiz'; message: QuizMessage };

export type PartialChatMessageWithId = Partial<ChatMessage> & Required<Pick<ChatMessage, 'id'>>;

export interface ChatResponse {
  chatId: string;
  subject?: {
    type: string;
    source: {
      type: string;
      id: string;
      documentType: DocumentType;
    };
  };
  createdAt?: string;
  lastActiveAt?: string;
}

export interface CreateChatRequest {
  title: string;
  thumbnailImageUrl?: string | null;
  source?: {
    type: 'libraryItem';
    id: string;
    documentType: DocumentType;
  } | null;
}

export type DocumentType = 'PDF' | 'SCAN' | 'HTML' | 'EPUB' | 'TXT';

export type FirebaseTimestamp = {
  _nanoseconds: number;
  _seconds: number;
};

export type ListenerAction =
  | 'create-chat'
  | 'create-quiz'
  | 'delete-chat'
  | 'delete-messages'
  | 'delete-quiz'
  | 'delete-quizzes'
  | 'delete-summary-loading-messages'
  | 'init'
  | 'new-messages'
  | 'refresh-quiz'
  | 'regenerate-message'
  | 'retake-quiz'
  | 'update-messages'
  | 'update-messages-and-delete-temp-messages';

export type ReplyMessage = {
  body: string;
  streaming?: boolean;
};

export type SummaryDialogState = {
  allPages: boolean;
  length: 'short' | 'medium' | 'long';
  mode: 'paragraph' | 'keypoints';
  open: boolean;
  pageIndexes: number[];
};

export type SummaryFormatTuple = 'paragraph' | 'keypoints';
export type SummaryFormatType = 'Paragraph' | 'Outline';

export type SummaryLengthTuple = 'short' | 'medium' | 'long';
export type SummaryLengthType = 'Short' | 'Medium' | 'Long';

export type SummaryLoading = {
  createdAt: FirebaseTimestamp;
  id: string;
  options: { mode: SummaryFormatTuple; length: SummaryLengthTuple; pageIndexes?: number[] };
  type: 'summary_loading';
};

export type SummaryMessage = {
  body: string;
  feedback: Nullable<SummaryFeedback>;
  length?: SummaryLengthTuple;
  mode?: SummaryFormatTuple;
  pageIndexes?: number[] | null;
  setFeedback: (feedback: SummaryFeedback) => Promise<void>;
  streaming?: boolean;
};

export type SummaryFeedback = {
  type: SummaryFeedbackType;
  text: Nullable<string>;
};

export type SummaryFeedbackType = 'positive' | 'negative';

export type UserMessage = {
  body: string;
  loading: boolean;
};

export const whileCondition = true;

export interface Quiz {
  id: string;
  currentAttempt: number;
  createdAt: FirebaseTimestamp;
  libraryItemId: string;
  isArchived: boolean;
  subject: Record<string, unknown>;
  questions: {
    key: string;
    question: string;
    possibleAnswers: string[];
    correctAnswer: string;
  }[];
  attempts: {
    number: number;
    answers: {
      questionKey: string;
      answer: number;
      submittedAt: string;
    }[];
  }[];
}

export interface QuizQuestion {
  key: string;
  question: string;
  possibleAnswers: string[];
  correctAnswer: string;
}

export interface QuizMessage {
  id: string;
  questions: QuizQuestion[];
  attempts: {
    number: number;
    answers: {
      questionKey: string;
      answer: number;
      submittedAt: string;
    }[];
  }[];
}

export type QuizDialogState = {
  allPages: boolean;
  open: boolean;
  pageIndexes: number[];
  questionCount: number;
};
