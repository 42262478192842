import { createIndexedDbStore } from 'lib/zustand';
import type { VoiceInfo } from 'modules/sdk/lib';

import { SPEECHIFY_VOICE_STORE_NAME } from '../utils/constants';

export type VoiceStoreState = {
  isLoading: boolean;
  isPreviewPlaying: boolean;

  lastPlayingVoice: VoiceInfo | undefined;
  lastPremiumVoice: VoiceInfo | undefined;
  selectionHistory: VoiceInfo[];

  recommendedVoices: VoiceInfo[];
  allVoices: VoiceInfo[];
  personalVoices: VoiceInfo[];
};

type PersistedVoiceState = Omit<VoiceStoreState, 'isLoading' | 'isPreviewPlaying'>;

export const useVoiceStore = createIndexedDbStore<VoiceStoreState, PersistedVoiceState>(
  () => ({
    isLoading: true,
    isPreviewPlaying: false,

    lastPlayingVoice: undefined,
    lastPremiumVoice: undefined,

    recommendedVoices: [],
    selectionHistory: [],
    personalVoices: [],
    allVoices: []
  }),
  {
    storageName: SPEECHIFY_VOICE_STORE_NAME,
    version: 1,
    // TODO(albertusdev): Migrate initialVoice from redux persisted state/local storage
    partialize: (state: VoiceStoreState) => {
      const clonedState: Partial<VoiceStoreState> = { ...state };
      delete clonedState.isLoading;
      delete clonedState.isPreviewPlaying;
      return clonedState as PersistedVoiceState;
    }
  }
);
