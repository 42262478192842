import { setCustomAccountSetting } from 'utils/baseAccountSettings';
import { getSettings } from 'utils/extension';

import { setExtensionSettings } from './setExtensionSettings';
import { useAuthStore } from '..';

export const setExtensionInstalled = (installed: boolean) => {
  useAuthStore.setState(state => {
    if (!state.user || !installed) {
      return state;
    }

    return {
      ...state,
      user: {
        ...state.user,
        extensionInstalled: installed
      }
    };
  });

  if (!installed && !useAuthStore.getState().user?.extensionInstalled) {
    const fiveMinutes = 1000 * 60 * 5;
    const uid = useAuthStore.getState().user?.uid;

    if (uid) {
      const timeout = setTimeout(async () => {
        const extensionSettings = await getSettings();

        if (extensionSettings) {
          setExtensionInstalled(true);
          setExtensionSettings(extensionSettings);
          setCustomAccountSetting('extensionInstalled', 'true');
          clearTimeout(timeout);
        }
      }, fiveMinutes);
    }
  }
};
