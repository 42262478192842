export enum UpsellModalType {
  ContextualAskAI = 'ContextualAskAI',
  ContextualCloneVoice = 'ContextualCloneVoice',
  ContextualDownloadMP3v2 = 'ContextualDownloadMP3v2',
  ContextualImageOnlyPDFs = 'ContextualImageOnlyPDFs',
  ContextualIncreaseSpeed = 'ContexttualIncreaseSpeed',
  ContextualPremiumVoices = 'ContextualPremiumVoices',
  ContextualSkipHeadersFooters = 'ContextualSkipHeadersFooters',
  ContextualSupport = 'ContextualSupport',
  ContextualUnlimitedFiles = 'ContexttualUnlimitedFiles',

  Global = 'Global',

  FreeWordLimit = 'FreeWordLimit',
  TempWordLimit = 'TempWordLimit'
}
