import { useSettings } from 'modules/listening/stores/settingsStore/useSettings';
import { useUpdatedState } from 'utils/updatedState/useHelperState';

import { useListeningSettingsStore } from '../settingsStore';
import { legacyZoomActions } from './legacyZoom';
import { getReaderBasedZoomActions } from './readerBasedZoom';

const getActions = () => {
  const readerFacade = useSettings.getState().readerFacade;
  if (readerFacade) {
    return getReaderBasedZoomActions(readerFacade);
  }

  return legacyZoomActions;
};

export const getMinZoomPercentage = () => getActions().getMinZoomPercentage();
export const getMaxZoomPercentage = () => getActions().getMaxZoomPercentage();

export const increaseZoom = () => getActions().increaseZoom();
export const decreaseZoom = () => getActions().decreaseZoom();

export const onZoomChange = (zoomPercentage: number, { maxZoom, minZoom }: { maxZoom: number; minZoom: number }) =>
  getActions().onZoomChange(zoomPercentage, { maxZoom, minZoom });

export const syncExternalPSPDFKitZoomEvent = (zoomScale: number) => getActions().syncExternalPSPDFKitZoomEvent(zoomScale);

export const useZoomFraction = () => {
  const legacyZoomFraction = useListeningSettingsStore(state => state.zoomPercentage / 100);
  const readerFacade = useSettings(s => s.readerFacade);
  const readerBasedZoomFraction = useUpdatedState(readerFacade?.zoomFractionState);

  return readerBasedZoomFraction ?? legacyZoomFraction;
};
