import type { Subscription } from '@speechifyinc/multiplatform-sdk';

import type { PlatformSubscription } from 'lib/platform';

export type SubscriptionType = Subscription | PlatformSubscription;

export const isStudioSubscription = (subscription: SubscriptionType): boolean => {
  return (
    subscription.plan?.productTypes.includes('voiceover') ||
    subscription.plan?.productTypes.includes('dubbing') ||
    subscription.plan?.productTypes.includes('voicecloning')
  );
};

export const isTtsSubscription = (subscription?: SubscriptionType): boolean => {
  return subscription?.plan?.productTypes.includes('tts') ?? false;
};

export const hasStudioSubscription = (subscriptions?: SubscriptionType[]): boolean => {
  return subscriptions?.some(isStudioSubscription) ?? false;
};

export const findTtsSubscription = <T extends SubscriptionType>(subscriptions?: T[]): T | undefined => {
  return subscriptions?.find(isTtsSubscription);
};
