import type {
  BookPage,
  BookPageTextContentItem,
  ContentBundle,
  ContentCursor,
  ContentOverlayRange,
  CurrentWordAndSentenceOverlayEvent,
  CurrentWordAndSentenceOverlayHelper,
  RenderedContentOverlayProvider,
  ReadingBundle as SDKReadingBundle,
  SpeechSentence
} from '@speechifyinc/multiplatform-sdk';
import _, { range } from 'lodash';
import debounce from 'lodash/debounce';

import type { PSPDFKitFacade } from 'lib/pdf/pspdfkit';
import { isAutoScrollEnabled } from 'modules/listening/features/autoScroll/autoScrollStore';
import { getAutoScrollAnchor } from 'modules/listening/features/autoScroll/utils';
import { pdfStoreActions, usePdfStore } from 'modules/listening/features/reader/stores/pdfStore';
import { renderedContentStoreSelectors } from 'modules/listening/features/reader/stores/renderedContentStore';
import { SkipContentSettings } from 'modules/listening/features/settings/settings';
import { listeningSettingsStoreSelectors, useListeningSettingsStore } from 'modules/listening/features/settings/settingsStore';

import type { MultiplatformSDKInstance } from '../../sdk';
import { PlaybackInfo } from '../playback';
import { HighlightingInfo, OverlayInfo, PlaybackCursorPosition, SentenceMark } from './base';
import { getRelativeRect } from './utils';

export enum PDFTextItemType {
  DIGITAL_TEXT = 'DIGITAL_TEXT',
  IMAGE = 'IMAGE'
}

export type PDFTextContentItemInfo = {
  pageIndex: number;
  customId: string;
  item: BookPageTextContentItem;
  viewport: {
    width: number;
    height: number;
  };
  type: PDFTextItemType;
};

type PDFOverlayContentRef = PDFTextContentItemInfo;

export class PDFOverlayInfo extends OverlayInfo<PDFOverlayContentRef> {
  private _cleanUpFunctions: (() => void)[] = [];
  private _overlayProvider: RenderedContentOverlayProvider<PDFOverlayContentRef>;
  private _overlayHelper: CurrentWordAndSentenceOverlayHelper<PDFOverlayContentRef>;

  private _bookPageCacheByPageIndex: Map<number, BookPage> = new Map();
  private _bookPageTextContentItemCacheByPageIndex: Map<number, PDFTextContentItemInfo[]> = new Map();
  private _bookPageTextContentItemCacheByCustomId: Map<string, PDFTextContentItemInfo> = new Map();
  private _isCurrentlyAutoScrolling = false;

  constructor(
    public readonly sdk: MultiplatformSDKInstance,
    public readonly bundle: SDKReadingBundle,
    public readonly playbackInfo: PlaybackInfo,
    private readonly pspdfKitFacade: PSPDFKitFacade
  ) {
    super();
    this._overlayProvider = new sdk.sdkModule.RenderedContentOverlayProvider<PDFOverlayContentRef>();
    this._overlayHelper = new sdk.sdkModule.CurrentWordAndSentenceOverlayHelper<PDFOverlayContentRef>(bundle.playbackControls, this._overlayProvider);
    this.setupListeningSettingsListener();
  }

  setupListeningSettingsListener = () => {
    let prevSkipSettings: SkipContentSettings | null = null;
    const onSkipContentSettingsChangeHandler = debounce(state => {
      const currentSkipSettings = listeningSettingsStoreSelectors.getSkipContentSettings(state);

      if (prevSkipSettings === null) {
        prevSkipSettings = currentSkipSettings;
        return;
      }

      if (_.isEqual(currentSkipSettings, prevSkipSettings)) {
        return;
      }

      // update previous skip settings immediately to handle double-fire event
      prevSkipSettings = currentSkipSettings;

      // invalidate cache for all `bookViews`
      this._bookPageCacheByPageIndex.clear();
      this._bookPageTextContentItemCacheByPageIndex.clear();
      this._bookPageTextContentItemCacheByCustomId.clear();
      this._adjacentSentencesCache.clear();
      this._getContentsForPageIndexPromiseMap.clear();

      // start with pagesInView and refetch everything
      const pagesInView = usePdfStore.getState().pagesInView;
      pdfStoreActions.clearPagesInView();
      this.overlayProvider.clearAllRenderedContent();

      pagesInView.forEach(async pageIndex => {
        await this.addRenderedContent(pageIndex);
      });
    }, 250);

    this._cleanUpFunctions.push(useListeningSettingsStore.subscribe(onSkipContentSettingsChangeHandler));
  };

  get bookView() {
    const bundle = this.bundle;
    return (bundle.listeningBundle.contentBundle as ContentBundle.BookBundle).bookView;
  }

  get speechView() {
    return this.bundle.listeningBundle.contentBundle.speechView;
  }

  get pageCount() {
    return this.bookView.getMetadata().numberOfPages;
  }

  public getTextContentItemsFromCache = (pageIndex: number): PDFTextContentItemInfo[] => {
    return this._bookPageTextContentItemCacheByPageIndex.get(pageIndex) || [];
  };

  public getDigitalTextContentItemsFromCache = (pageIndex: number): PDFTextContentItemInfo[] => {
    return this.getTextContentItemsFromCache(pageIndex).filter(item => item.type === PDFTextItemType.DIGITAL_TEXT);
  };

  public getImageTextContentItemsFromCache = (pageIndex: number): PDFTextContentItemInfo[] => {
    return this.getTextContentItemsFromCache(pageIndex).filter(item => item.type === PDFTextItemType.IMAGE);
  };

  private getSdkBookPage = async (pageIndex: number) => {
    if (this._bookPageCacheByPageIndex.has(pageIndex)) {
      return this._bookPageCacheByPageIndex.get(pageIndex)!;
    }
    const { promisify } = this.sdk;
    const { bookView } = this;
    const [sdkBookPage] = await promisify(bookView.getPages.bind(bookView))([pageIndex]);
    this._bookPageCacheByPageIndex.set(pageIndex, sdkBookPage);
    return sdkBookPage;
  };

  private _getContentsForPageIndexPromise = async (pageIndex: number) => {
    const { promisify } = this.sdk;
    const sdkBookPage = await this.getSdkBookPage(pageIndex);
    const sdkTextContent = await promisify(sdkBookPage.getTextContent.bind(sdkBookPage))();
    return { sdkBookPage, sdkTextContent };
  };
  // Ensure that the promise is only created once for each page index and we reuse the promise instead of creating a new one
  private _getContentsForPageIndexPromiseMap = new Map<number, ReturnType<typeof this._getContentsForPageIndexPromise>>();

  public fetchTextContentItems = async (pageIndex: number) => {
    if (this._bookPageTextContentItemCacheByPageIndex.has(pageIndex)) {
      return this._bookPageTextContentItemCacheByPageIndex.get(pageIndex)!;
    }

    const promise = this._getContentsForPageIndexPromiseMap.get(pageIndex) || this._getContentsForPageIndexPromise(pageIndex);
    this._getContentsForPageIndexPromiseMap.set(pageIndex, promise);

    const { sdkBookPage, sdkTextContent } = await promise;

    const viewport = sdkBookPage.getMetadata().viewport;

    const customTextContents = sdkTextContent.map((item, index) => {
      if (item.textSourceType.name === 'DIGITAL_TEXT') {
        return {
          pageIndex: pageIndex,
          customId: `custom_${pageIndex}_${index}`,
          type: PDFTextItemType.DIGITAL_TEXT,
          viewport,
          item
        };
      }
      return {
        pageIndex: pageIndex,
        customId: `custom_${pageIndex}_${index}`,
        item,
        viewport,
        type: PDFTextItemType.IMAGE
      };
    });

    this._bookPageTextContentItemCacheByPageIndex.set(pageIndex, customTextContents);
    customTextContents.forEach(item => {
      this._bookPageTextContentItemCacheByCustomId.set(item.customId, item);
    });
    this._getContentsForPageIndexPromiseMap.delete(pageIndex);
    return customTextContents;
  };

  override get overlayProvider(): RenderedContentOverlayProvider<PDFOverlayContentRef> {
    return this._overlayProvider;
  }
  override get overlayHelper(): CurrentWordAndSentenceOverlayHelper<PDFOverlayContentRef> {
    return this._overlayHelper;
  }

  override overlayRangeToRects = (range: ContentOverlayRange<PDFOverlayContentRef>, baseRect: DOMRect): DOMRect[] => {
    const { startIndex, endIndex } = range;

    const ref = range.ref.value;
    const element = renderedContentStoreSelectors.getRenderedContent(ref.customId);

    if (!element) {
      return [];
    }

    const parent = element;

    const textNode = element.childNodes[0];
    const r = new Range();

    const actualStartIndex = Math.min(startIndex, textNode.textContent!.length);
    const actualEndIndex = Math.min(endIndex, textNode.textContent!.length);
    r.setStart(textNode, actualStartIndex);
    r.setEnd(textNode, actualEndIndex);

    return Array.from(r.getClientRects()).map(rect => {
      const intersectingRect = parent.getClientRects()[0];
      return getRelativeRect(baseRect, new DOMRect(rect.left, intersectingRect.top, rect.width, intersectingRect.height));
    });
  };

  // runtime-safe utility function to get [prevPage, currentPage, nextPage]
  private _getRelevantPages = (pageIndex: number): number[] => {
    const numberOfPages = this.bookView.getMetadata().numberOfPages;

    const prevPage = Math.max(0, pageIndex - 1);
    const nextPage = Math.min(numberOfPages - 1, pageIndex + 1);

    const newPageNumbersInView = new Set<number>(range(prevPage, nextPage + 1));

    const relevantPages = Array.from(newPageNumbersInView);

    // Ensure the current page is always included first
    return relevantPages.includes(pageIndex) ? [pageIndex, ...relevantPages.filter(page => page !== pageIndex)] : relevantPages;
  };

  private addRenderedContent = async (pageIndex: number) => {
    const overlayProvider = this.overlayProvider;
    const relevantPages = this._getRelevantPages(pageIndex);

    const { sdkModule: sdk } = this.sdk;
    const ObjectRef = sdk.ObjectRef;

    // popoulate the cache if not already
    if (!this._bookPageTextContentItemCacheByPageIndex.has(pageIndex)) {
      const textContents = await this.fetchTextContentItems(pageIndex);
      textContents.forEach(textContent => {
        overlayProvider.addRenderedContent(new ObjectRef(textContent), textContent.item.text);
      });
    }
    if (relevantPages.includes(pageIndex) && !usePdfStore.getState().pagesInView.includes(pageIndex)) {
      pdfStoreActions.addPagesInView([pageIndex]);
    }
  };

  addRenderedContentToOverlayProvider = async () => {
    let relevantPages: number[] = [];

    const getPageIndexFromCursor = (cursor: ContentCursor) => {
      return this.bookView.getPageIndex(cursor);
    };

    let currentScrollPageIndex = this.pspdfKitFacade.getCurrentPageIndex()!;
    let currentContentPageIndex: number | null = null;

    const addRenderedContentForPagesInView = () => {
      currentScrollPageIndex = this.pspdfKitFacade.getCurrentPageIndex()!;
      relevantPages = [...new Set([...this._getRelevantPages(currentScrollPageIndex)])];
      const readyPages = relevantPages.filter(pageIndex => this._bookPageTextContentItemCacheByPageIndex.has(pageIndex));
      const pendingPages = relevantPages.filter(pageIndex => !this._bookPageTextContentItemCacheByPageIndex.has(pageIndex));

      pdfStoreActions.updatePagesInView(readyPages);

      pendingPages.forEach(async pageIndex => {
        try {
          await this.addRenderedContent(pageIndex);
        } catch (e) {
          console.error('Error while fetching page', pageIndex, e);
        }
      });
    };

    const addRenderedContentForPagesInViewDebounced = debounce(addRenderedContentForPagesInView, 250);

    const removePageIndexListener = this.pspdfKitFacade.addPageIndexListener(pageIndex => {
      if (pageIndex !== currentScrollPageIndex) {
        currentScrollPageIndex = pageIndex;
        relevantPages = [...new Set([...this._getRelevantPages(currentScrollPageIndex)])];
        addRenderedContentForPagesInViewDebounced();
      }
    });

    const removePlaybackStateListener = this.playbackInfo.addStateListener(playbackState => {
      const playbackPageIndex = getPageIndexFromCursor(playbackState.latestPlaybackCursor);
      if (playbackPageIndex !== currentContentPageIndex) {
        currentContentPageIndex = playbackPageIndex;
        this.addRenderedContent(playbackPageIndex);
      }
    });

    try {
      addRenderedContentForPagesInView();
    } catch (e) {
      // ignore
      // TODO(overhaul): Handle this error properly
    }

    // TODO(albertusdev): Consider if we should eagerly load all pages one-by-one and build the rendered content cache
    // for better performance (with memory trade off) because calling SDK getTextContent can cause ~50ms delay per page even on a fast device
    // so loading the pages on-demand can cause highlighting and auto scroll delay if user do fast/quick scroll through the pages.
    this._cleanUpFunctions.push(removePlaybackStateListener);
    removePageIndexListener && this._cleanUpFunctions.push(removePageIndexListener);
  };

  // TODO(albertusdev): Consider refactoring this into abstract class which simply calls `highlightContainer.getClientRects()[0]`
  override getBaseRect(): DOMRect {
    return this.pspdfKitFacade.instanceHighlightContainer.getClientRects()[0];
  }

  override get scroller(): HTMLElement {
    return this.pspdfKitFacade.instanceScroller;
  }

  override get highlightContainer(): HTMLElement {
    return this.pspdfKitFacade.instanceHighlightContainer;
  }

  override getActiveSentenceTopPositionForAutoScroll = async (sentenceOverlayRanges: ContentOverlayRange<PDFOverlayContentRef>[]) => {
    if (sentenceOverlayRanges.length === 0) {
      return null;
    }

    const firstSentence = sentenceOverlayRanges[0];

    const activeSentencePageIndex = firstSentence.ref.value.pageIndex;

    await this.maybeScrollToPageIndex(
      activeSentencePageIndex,
      /*respectAutoScroll*/ false // this function is called for the initial open of the doc, where we want to force auto scroll to last position
    );

    const baseRect = this.getBaseRect();
    const rects = this.overlayRangeToRects(firstSentence, baseRect);
    if (rects.length === 0) {
      return null;
    }

    return rects[0].top;
  };

  private maybeScrollToPageIndex = async (pageIndex: number, respectAutoScroll = true) => {
    if (respectAutoScroll && !isAutoScrollEnabled()) return;
    if (respectAutoScroll && this._isCurrentlyAutoScrolling) {
      return;
    }

    const currentPageIndex = this.pspdfKitFacade.getCurrentPageIndex()!;
    if (currentPageIndex === pageIndex) {
      return;
    }

    const diff = Math.abs(currentPageIndex - pageIndex);
    if (diff > 1 || !this.pspdfKitFacade.isPageLoaded(pageIndex)) {
      this._isCurrentlyAutoScrolling = true;
      const _lastAutoScrollDelayPromise = new Promise(resolve => {
        this.pspdfKitFacade.goToPage(pageIndex);
        // wait a little bit for scroll animation to finish and React to render the overlay
        setTimeout(resolve, 500);
      });
      await _lastAutoScrollDelayPromise;
      this._isCurrentlyAutoScrolling = false;
    }
  };

  private _latestHighlightingEvent: CurrentWordAndSentenceOverlayEvent<PDFOverlayContentRef> | null = null;
  override listenToCurrentWordAndSentenceHighlighting = (callback: (currentWordAndSentence: HighlightingInfo) => void): (() => void) => {
    const overlayHelper = this.overlayHelper;

    let currentAbortController = new AbortController();

    const mapOverlayEventToCurrentWordAndSentence = async (event: CurrentWordAndSentenceOverlayEvent<PDFOverlayContentRef>) => {
      try {
        const abortController = currentAbortController.signal;
        const { sentenceOverlayRanges, wordOverlayRanges } = event;
        if (wordOverlayRanges.length === 0) {
          return;
        }
        const refValue = wordOverlayRanges[0].ref.value;
        const pageIndex = refValue.pageIndex;

        await this.maybeScrollToPageIndex(pageIndex);
        if (abortController.aborted) {
          throw new Error('Aborted in favor of new highlighting event');
        }

        const baseRect = this.getBaseRect();
        const wordRects = wordOverlayRanges.flatMap(wordRange => {
          const rects = this.overlayRangeToRects(wordRange, baseRect);
          return rects;
        });

        const sentencesRects = sentenceOverlayRanges.flatMap(sentenceRange => {
          const rects = this.overlayRangeToRects(sentenceRange, baseRect);
          return rects;
        });

        const result = {
          word: { rects: wordRects },
          sentence: { rects: sentencesRects }
        };

        return result;
      } catch (e) {
        return { word: { texts: [], rects: [] }, sentence: { texts: [], rects: [] } };
      }
    };

    const processLatestHighlightingEvent = async () => {
      if (!this._latestHighlightingEvent) return;
      const abortController = currentAbortController.signal;
      const highlightingInfo = await mapOverlayEventToCurrentWordAndSentence(this._latestHighlightingEvent);
      !abortController.aborted && highlightingInfo && callback(highlightingInfo);
    };

    const cleanUp = overlayHelper.addEventListener(event => {
      currentAbortController.abort();
      this._latestHighlightingEvent = event;
      currentAbortController = new AbortController();
      processLatestHighlightingEvent();
    });

    const cleanUpCustomZoomListener = this.customHighlightingEventEmitter.addEventListener('zoom', processLatestHighlightingEvent);

    this._cleanUpFunctions.push(cleanUp);
    this._cleanUpFunctions.push(cleanUpCustomZoomListener);

    return () => {
      cleanUp();
      this._cleanUpFunctions = this._cleanUpFunctions.filter(f => f !== cleanUp && f !== cleanUpCustomZoomListener);
    };
  };

  private _adjacentSentencesCache: Map<string, SpeechSentence[]> = new Map();
  private async getAdjacentSentences(textCustomId: string): Promise<SpeechSentence[]> {
    if (this._adjacentSentencesCache.has(textCustomId)) {
      return this._adjacentSentencesCache.get(textCustomId)!;
    }

    const { sdk, speechView } = this;
    const { promisify } = sdk;
    const getSpeech = promisify(speechView.getSpeech.bind(speechView));
    const { CursorQueryBuilder, SpeechQueryBuilder } = sdk.sdkModule;

    const textContentItem = this._bookPageTextContentItemCacheByCustomId.get(textCustomId);
    if (!textContentItem) {
      return [];
    }

    const cursorStart = CursorQueryBuilder.fromCursor(textContentItem.item.text.start).scanBackwardToSentenceStart(1);
    const cursorEnd = CursorQueryBuilder.fromCursor(textContentItem.item.text.end).scanForwardToSentenceEnd(1);
    const speechQuery = SpeechQueryBuilder.fromBounds(cursorStart, cursorEnd);
    const sentences = await getSpeech(speechQuery);

    this._adjacentSentencesCache.set(textCustomId, sentences.sentences);
    return sentences.sentences;
  }

  private sentenceToSentenceMark = (sentence: SpeechSentence): SentenceMark => {
    const overlayRanges = this.overlayProvider.getOverlayRanges(sentence.text);
    return {
      playFromHere: () => {
        return this.playbackInfo.playFromQuery(this.sdk.sdkModule.CursorQueryBuilder.fromCursor(sentence.text.start));
      },
      rects: overlayRanges.flatMap(range => this.overlayRangeToRects(range, this.getBaseRect()))
    };
  };

  private _clickToListenAbortController: AbortController | null = null;
  override getRelevantClickToListenSentenceMark = async (x: number, y: number): Promise<SentenceMark | null> => {
    // Abort previous click to listen request if exist
    if (this._clickToListenAbortController) {
      this._clickToListenAbortController.abort();
    }
    // Create a new AbortController for the new click to listen request
    this._clickToListenAbortController = new AbortController();
    const signal = this._clickToListenAbortController.signal;

    const customId = this.pspdfKitFacade.getCustomIdFromPoint(x, y);
    if (!customId) {
      return null;
    }

    if (signal.aborted) {
      return null;
    }

    const sentences = await this.getAdjacentSentences(customId);

    for (const sentence of sentences) {
      if (signal.aborted) {
        return null;
      }

      const overlayRanges = this.overlayProvider.getOverlayRanges(sentence.text);
      if (signal.aborted) {
        return null;
      }

      const rects = overlayRanges.flatMap(range => {
        if (signal.aborted) {
          return [];
        }
        return this.overlayRangeToRects(range, new DOMRect(0, 0, 0, 0));
      });

      if (rects.some(rect => x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom)) {
        return this.sentenceToSentenceMark(sentence);
      }
    }
    return null;
  };

  override getCurrentPlaybackCursorPosition = (): PlaybackCursorPosition => {
    if (!this._latestHighlightingEvent) {
      return 'notFound';
    }
    if (this._latestHighlightingEvent.sentenceOverlayRanges.length === 0) {
      return 'notFound';
    }
    const currentSentencePageIndex = this._latestHighlightingEvent.sentenceOverlayRanges[0].ref.value.pageIndex;

    const currentVisiblePageIndex = this.pspdfKitFacade.getCurrentPageIndex()!;
    if (currentSentencePageIndex < currentVisiblePageIndex) {
      return 'above';
    }
    if (currentSentencePageIndex > currentVisiblePageIndex) {
      return 'below';
    }

    // Simply check if the current sentence line is above or below the center of the screen
    const currentSentenceLineElement = renderedContentStoreSelectors.getRenderedContent(
      this._latestHighlightingEvent.sentenceOverlayRanges[0].ref.value.customId
    );
    if (!currentSentenceLineElement) return 'notFound';
    const rect = currentSentenceLineElement.getBoundingClientRect();
    const autoScrollAnchor = getAutoScrollAnchor();
    if (rect.top < autoScrollAnchor) return 'above';
    return 'below';
  };

  override destroy(): void {
    this._cleanUpFunctions.forEach(f => f());
    this._cleanUpFunctions = [];
    this._bookPageCacheByPageIndex.clear();
    this._bookPageTextContentItemCacheByCustomId.clear();
    this._bookPageTextContentItemCacheByPageIndex.clear();
    this._adjacentSentencesCache.clear();
    this._getContentsForPageIndexPromiseMap.clear();
    super.destroy();
  }
}
