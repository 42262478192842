import React from 'react';

export default function FantasyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_208_2473)">
        <path
          d="M36.6876 43.9891C22.058 52.4355 6.36835 52.6489 1.6438 44.4657C-3.08076 36.2826 4.94888 22.8016 19.5785 14.3552C34.2081 5.90881 48.1825 6.63945 54.6223 13.8786C59.3469 22.0617 51.3172 35.5427 36.6876 43.9891Z"
          fill="#3B4EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.3282 13.3124C54.452 13.4965 54.5698 13.6853 54.6815 13.8787C59.4061 22.0619 51.3764 35.5428 36.7468 43.9893C29.9034 47.9403 22.828 50.0898 16.7515 50.4211C11.266 45.5718 7.80707 38.4823 7.80707 30.5849C7.80707 27.6993 8.26886 24.9216 9.12256 22.3216C12.0328 19.426 15.5816 16.6972 19.6377 14.3554C31.9264 7.26049 44.9631 5.97466 51.6207 10.5949C52.5872 11.4343 53.4923 12.3427 54.3282 13.3124Z"
          fill="#B5E8FF"
        />
        <path
          d="M15.4539 49.1796C12.9512 46.6769 10.976 43.6978 9.64491 40.4184C8.31387 37.1389 7.65409 33.6258 7.7046 30.087C7.75512 26.5481 8.51491 23.0552 9.93902 19.8151C11.3631 16.575 13.4226 13.6535 15.9957 11.2233C18.5687 8.79313 21.603 6.90371 24.9191 5.66682C28.2352 4.42992 31.7657 3.87069 35.3017 4.02219C38.8377 4.1737 42.3074 5.03288 45.5055 6.54889C48.7037 8.0649 51.5652 10.207 53.921 12.8483L34.1687 30.4647L15.4539 49.1796Z"
          fill="#B5E8FF"
        />
        <ellipse cx="27.1945" cy="24.3795" rx="14.0213" ry="13.9082" fill="#22CC97" />
        <ellipse cx="27.1945" cy="24.3794" rx="6.13484" ry="6.08534" fill="#3B4EFF" />
      </g>
      <defs>
        <clipPath id="clip0_208_2473">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
