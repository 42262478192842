import { IndividualSettingToggleImplementation } from '../../settings';

let timeoutId: number | undefined = undefined;
const toggleDarkModeSetting: IndividualSettingToggleImplementation<'darkMode'> = value => {
  document.body.classList.add('theme-switching');
  window.clearTimeout(timeoutId);
  timeoutId = window.setTimeout(() => {
    document.body.classList.remove('theme-switching');
  }, 250);

  if (value) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
};

export default toggleDarkModeSetting;
