import React from 'react';

export default function MysteryIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2 24H38L42 42H2V24Z" fill="#B5E8FF" />
      <path d="M25 27C25 32.5228 23.2105 37 16.5 37C9.78947 37 8 32.5228 8 27C13.2619 27.0001 17.7143 27.0001 25 27Z" fill="#3B4EFF" />
      <path d="M2 3L18 8L33.7647 3L38 25H2V3Z" fill="#3B4EFF" />
      <path d="M36.4599 17L38 25H2V17H36.4599Z" fill="#FFC044" />
      <path d="M44 27C44 32.5228 42.2105 37 35.5 37C28.7895 37 27 32.5228 27 27C32.2619 27.0001 36.7143 27.0001 44 27Z" fill="#3B4EFF" />
      <path d="M2 24H47.6281C49.4065 24 51.051 24.9446 51.947 26.4806L54 30H2V24Z" fill="#3B4EFF" />
      <path d="M2 39H54L43 54H2V39Z" fill="#3B4EFF" />
    </svg>
  );
}
