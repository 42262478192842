import {
  AutoHideInterfaceIcon,
  AutoScrollIcon,
  BracesIcon,
  BracketsIcon,
  CitationIcon,
  ClickToListenIcon,
  DarkModeIcon,
  FootersIcon,
  HeadersIcon,
  ParenthesesIcon,
  TextHighlightingIcon,
  UrlIcon
} from 'components/newListeningExperience/settings/icons';

import { DisplaySettings, SettingKey, SkipContentSettings } from './settings';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type SettingItemConfig<K extends SettingKey> = {
  // Note: this is abstracted as function instead of hard coded constants to support the need to add localization supoprt down the line.
  settingDisplayName: () => string;
  icon: JSX.Element;
};

export const displaySettingsUIConfigs: {
  [K in keyof DisplaySettings]: SettingItemConfig<K>;
} = {
  textHighlighting: {
    settingDisplayName: () => 'Text Highlighting',
    icon: <TextHighlightingIcon />
  },
  autoScroll: {
    settingDisplayName: () => 'Auto Scroll',
    icon: <AutoScrollIcon />
  },
  clickToListen: {
    settingDisplayName: () => 'Click to Listen',
    icon: <ClickToListenIcon />
  },
  autoHideInterface: {
    settingDisplayName: () => 'Auto-Hide Player',
    icon: <AutoHideInterfaceIcon />
  },
  darkMode: {
    settingDisplayName: () => 'Dark Mode',
    icon: <DarkModeIcon />
  }
};

export const autoSkipContentSettingsUIConfigs: {
  [K in keyof Partial<SkipContentSettings>]: SettingItemConfig<K>;
} = {
  headers: {
    settingDisplayName: () => 'Headers',
    icon: <HeadersIcon />
  },
  footers: {
    settingDisplayName: () => 'Footers',
    icon: <FootersIcon />
  },
  footnotes: {
    settingDisplayName: () => 'Footnotes',
    icon: <FootersIcon /> // TODO(overhaul): Request Icon from designer for skip foot notes.
  },
  braces: {
    settingDisplayName: () => 'Braces',
    icon: <BracesIcon />
  },
  citations: {
    settingDisplayName: () => 'Citations',
    icon: <CitationIcon />
  },
  parentheses: {
    settingDisplayName: () => 'Parentheses',
    icon: <ParenthesesIcon />
  },
  brackets: {
    settingDisplayName: () => 'Brackets',
    icon: <BracketsIcon />
  },
  urls: {
    settingDisplayName: () => 'URLs',
    icon: <UrlIcon />
  }
};
