import { emitUserUpdate } from './emitUserUpdate';
import { useAuthStore } from '..';

export const setFileUploaded = (fileUploaded: boolean) => {
  useAuthStore.setState(state => {
    if (!state.user) return state;
    return {
      user: {
        ...state.user,
        fileUploaded
      }
    };
  });
  emitUserUpdate();
};
