import type { Subscription } from '@speechifyinc/multiplatform-sdk';

import { emitUserUpdate } from './emitUserUpdate';
import { useAuthStore } from '..';

export const setSubscription = (subscription: Subscription) => {
  useAuthStore.setState(state => {
    if (!state.user) return state;
    return {
      user: {
        ...state.user,
        subscription
      }
    };
  });
  emitUserUpdate();
};
