import { User as FirebaseUser } from 'firebase/auth';
import { IUser } from 'interfaces';
import { isEqual } from 'lodash';
import nookies from 'nookies';

import { currentUserEmitter } from 'modules/auth/store/authUserEmitter';
import { getSDK } from 'modules/sdk/lib';
import { getCustomAccountSetting, refreshAccountSettings } from 'utils/baseAccountSettings';
import * as extension from 'utils/extension';
import { getSettings } from 'utils/extension';
import { bootIntercom } from 'utils/intercom';

import { useAuthStore } from '../index';
import { isUserAnonymous } from '../utils/isUserAnonymous';
import { initSubscriptionAndEntitlements } from '../utils/subscription';
import { performAuthStoreCleanupFunctions } from './cleanupAuth';
import { setBillingDashboardUrl } from './setBillingDashboardUrl';
import { setExtensionInstalled } from './setExtensionInstalled';
import { setExtensionSettings } from './setExtensionSettings';
import { setMobileAppInstalled } from './setMobileAppInstalled';

const inferUserFieldsFromFirebaseUser = (firebaseUser: FirebaseUser): Partial<IUser> => {
  return {
    displayName: firebaseUser.displayName,
    email: firebaseUser.email,
    uid: firebaseUser.uid,
    metadata: {
      creationTime: firebaseUser.metadata.creationTime,
      lastSignInTime: firebaseUser.metadata.lastSignInTime
    },
    emailVerified: firebaseUser.emailVerified,
    isAnonymous: isUserAnonymous(firebaseUser)
  };
};

type Options = {
  force?: boolean;
};

export const setUser = async (firebaseUser: FirebaseUser | null, { force = false }: Options = {}) => {
  if (!firebaseUser) {
    return;
  }

  // Set firebaseUser early so that multiple calls to setUser don't cause issues
  const previousFirebaseUser = useAuthStore.getState().firebaseUser;
  useAuthStore.setState({ firebaseUser });

  const partialUser = inferUserFieldsFromFirebaseUser(firebaseUser);

  // Don't repeat all initialization logic if the user hasn't changed
  if (!force && previousFirebaseUser && firebaseUser === previousFirebaseUser && isEqual(partialUser, inferUserFieldsFromFirebaseUser(previousFirebaseUser))) {
    return;
  }

  performAuthStoreCleanupFunctions();

  const sdk = await getSDK();

  // eslint-disable-next-line prefer-const
  let [{ subscription, entitlements, hasStudioSubscription }, extensionSettings] = await Promise.all([
    initSubscriptionAndEntitlements(firebaseUser),
    getSettings()
  ]);

  if (!extensionSettings?.voice?.name) {
    const landingPageVoiceName = nookies.get().lpvoice;

    if (landingPageVoiceName) {
      const voice = { name: landingPageVoiceName };
      extension.setVoice(voice);

      extensionSettings = {
        ...extensionSettings,
        voice
      };
    }
  }

  if (!extensionSettings?.playbackSpeed) {
    const landingPageSpeed = Number(nookies.get().lppbspeed);

    if (landingPageSpeed) {
      extension.setPlaybackSpeed(landingPageSpeed);

      extensionSettings = {
        ...extensionSettings,
        playbackSpeed: landingPageSpeed
      };
    }
  }

  bootIntercom(
    {
      ...partialUser,
      subscription
    } as IUser,
    {}
  );

  await refreshAccountSettings();

  const [
    mobileAppInstalled,
    fileUploaded,
    redirectGoogleDoc,
    extensionPinned,
    extensionInstalled,
    clickedStartListening,
    hasSetDailyListeningGoal,
    firstPdfDocument
  ] = await Promise.all([
    getCustomAccountSetting('mobileAppInstalled'),
    getCustomAccountSetting('fileUploaded'),
    getCustomAccountSetting('redirectGoogleDoc'),
    getCustomAccountSetting('extensionPinned'),
    getCustomAccountSetting('extensionInstalled'),
    getCustomAccountSetting('clickedStartListening'),
    getCustomAccountSetting('hasSetDailyListeningGoal'),
    getCustomAccountSetting('FIRST_PDF_DOCUMENT')
  ]);

  const user: IUser = {
    ...partialUser,
    redirectGoogleDoc: Boolean(redirectGoogleDoc),
    extensionPinned: Boolean(extensionPinned),
    fileUploaded: Boolean(fileUploaded),
    extensionInstalled: Boolean(extensionInstalled),
    clickedStartListening: Boolean(clickedStartListening),
    mobileAppInstalled: Boolean(mobileAppInstalled),
    hasSetDailyListeningGoal: Boolean(hasSetDailyListeningGoal),
    hasStudioSubscription,
    firstPdfDocument,
    entitlements,
    extensionSettings,
    subscription
  };

  useAuthStore.setState({
    isLoading: false,
    firebaseUser,
    user
  });

  // section after this is the translation from the previous redux auth middleware

  if (user.uid) {
    setBillingDashboardUrl();

    const state = useAuthStore.getState();
    if (!state.user?.mobileAppInstalled) {
      const data = await sdk.account.getUserUsageData();
      const mobileAppInstalled = !!(data?.android?.lastVersion || data?.ios?.lastVersion);
      setMobileAppInstalled(mobileAppInstalled);
    }

    // extension settings
    const extensionSettings = await getSettings();

    if (extensionSettings) {
      setExtensionInstalled(true);
      setExtensionSettings(extensionSettings);
    } else {
      setExtensionInstalled(false);
    }
  }

  currentUserEmitter.emit('onUserUpdate', { user: user as IUser });

  return user;
};
