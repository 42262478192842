import type { IUser } from 'interfaces';
import type { SpeechifyPersistedStoreState } from 'store';

import type { User } from 'lib/speechify/auth';
import { createIndexedDbStore, createNonPersistentStore } from 'lib/zustand/store';

export type AuthStoreState = {
  isLoading: boolean;
  firebaseUser: User | null;
  user: IUser | null;
  cleanupFunctions: (() => void)[];
};

export type PersistedAuthStoreState = Omit<AuthStoreState, 'cleanupFunctions'>;

const getPersisted = () =>
  createIndexedDbStore<AuthStoreState, PersistedAuthStoreState>(
    () => ({
      isLoading: true,
      firebaseUser: null,
      user: null,
      cleanupFunctions: []
    }),
    {
      storageName: 'speechify-auth-store',
      version: 1,
      backfillStateFromReduxPersist: (reduxState: SpeechifyPersistedStoreState, defaultState: AuthStoreState) => ({
        ...defaultState,
        user: reduxState.auth?.user || null,
        isLoading: false
      }),
      partialize: state => {
        // do not persist cleanup functions
        const { cleanupFunctions, ...rest } = state;
        return rest;
      }
    }
  );

const getNonPersisted = () =>
  createNonPersistentStore<AuthStoreState>(
    () => ({
      isLoading: true,
      firebaseUser: null,
      user: null,
      cleanupFunctions: []
    }),
    {
      isListeningScreenStore: false
    }
  );

export const useAuthStore: ReturnType<typeof getPersisted> | ReturnType<typeof getNonPersisted> =
  typeof window !== 'undefined' ? getPersisted() : getNonPersisted();
