import { getSDK } from 'modules/sdk/lib';
import { setCustomAccountSetting } from 'utils/baseAccountSettings';

import { useAuthStore } from '..';

export const setMobileAppInstalled = (installed: boolean) => {
  useAuthStore.setState(state => {
    if (!state.user || !installed) {
      return state;
    }

    return {
      ...state,
      user: {
        ...state.user,
        mobileAppInstalled: installed
      }
    };
  });
  if (!installed && !useAuthStore.getState().user?.mobileAppInstalled) {
    const fiveMinutes = 1000 * 60 * 5;
    const uid = useAuthStore.getState().user?.uid;

    if (uid) {
      setTimeout(async () => {
        const sdk = await getSDK();
        const data = await sdk.account.getUserUsageData();
        const mobileAppInstalled = !!(data?.android?.lastVersion || data?.ios?.lastVersion);
        setMobileAppInstalled(mobileAppInstalled);
        setCustomAccountSetting('mobileAppInstalled', 'true');
      }, fiveMinutes);
    }
  }
};
