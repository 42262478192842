import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { ReaderFacade } from 'modules/sdk/lib/facade/ReaderFacade';

import { NAV_HEIGHT, SIDE_PADDING } from '../../reader/components/book/constants';
import { A4_HEIGHT, A4_WIDTH, MAX_ZOOM_PERCENTAGE, MIN_ZOOM_PERCENTAGE, ZOOM_STEP_PERCENTAGE } from './constants';

const getMinZoomPercentage = () => MIN_ZOOM_PERCENTAGE;
const getMaxZoomPercentage = () => MAX_ZOOM_PERCENTAGE;

const increaseZoom = (readerFacade: ReaderFacade) => () => {
  onZoomChange(readerFacade)(readerFacade.zoomFractionState.state * 100 + ZOOM_STEP_PERCENTAGE, {
    maxZoom: getMaxZoomPercentage(),
    minZoom: getMinZoomPercentage()
  });
};

const decreaseZoom = (readerFacade: ReaderFacade) => () => {
  onZoomChange(readerFacade)(readerFacade.zoomFractionState.state * 100 - ZOOM_STEP_PERCENTAGE, {
    maxZoom: getMaxZoomPercentage(),
    minZoom: getMinZoomPercentage()
  });
};

const onZoomChange =
  (readerFacade: ReaderFacade) =>
  (zoomPercentage: number, { maxZoom, minZoom }: { maxZoom: number; minZoom: number }) => {
    zoomPercentage = Math.min(maxZoom, Math.max(minZoom, zoomPercentage));
    zoomPercentage = Math.ceil(Math.round(zoomPercentage));
    readerFacade.setZoomFraction(zoomPercentage / 100);

    logAnalyticsEvent(AnalyticsEventKey.listeningSettingsZoomChanged, {
      zoomPercentage
    });
  };

export const getReaderBasedZoomActions = (readerFacade: ReaderFacade) => ({
  syncExternalPSPDFKitZoomEvent: () => {}, // noop
  getMinZoomPercentage,
  getMaxZoomPercentage,
  increaseZoom: increaseZoom(readerFacade),
  decreaseZoom: decreaseZoom(readerFacade),
  onZoomChange: onZoomChange(readerFacade)
});

export const getDefaultZoomFraction = () => {
  const screenHeightWithoutNav = window.innerHeight - NAV_HEIGHT;
  const screenWidth = window.innerWidth - SIDE_PADDING * 2;

  const maximumHorizontalZoom = screenWidth / A4_WIDTH;

  const smallestRatio = 0.9;
  const largestRatio = 1.4;

  const smallestHeight = screenHeightWithoutNav * smallestRatio;
  const smallestZoom = smallestHeight / A4_HEIGHT;
  const largestHeight = screenHeightWithoutNav * largestRatio;
  const largestZoom = largestHeight / A4_HEIGHT;

  let targetZoom = 1;
  targetZoom = Math.min(targetZoom, largestZoom);
  targetZoom = Math.max(targetZoom, smallestZoom);

  // We never should force the user to scroll horizontally, this won't be exact since most PDFs are not exact A4 size but it should be close enough
  targetZoom = Math.min(targetZoom, maximumHorizontalZoom);

  return targetZoom;
};
