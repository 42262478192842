import type {
  BoundaryMap,
  LiveQueryView,
  ContentType as SDKContentType,
  LibraryItem as SDKLibraryItemType,
  Result as SDKResultType,
  SearchResult as SDKSearchResult
} from '@speechifyinc/multiplatform-sdk';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

import { WebAppImportFlow, WebAppImportType } from 'components/library/import/constants';
import { ErrorSource } from 'config/constants/errors';
import { RecordType } from 'interfaces/library';
import { logError } from 'lib/observability';
import { LibraryFilterAndSortOptions, LibraryFilterType, LibrarySortBy, LibrarySortOrder } from 'modules/library/constants';
import { ILibraryItem } from 'modules/library/types/item';
import { getRequiredStringEnv } from 'utils/safeEnvParsing';

import { SDKContentSubType } from '../analytics/contentSubType';
import type { MultiplatformSDKInstance } from '../sdk';
import { SDKFacade } from './_base';
import { ContentMetaType, ListenableContent } from './listenableContent';

function unreachable(value: never) {
  return value;
}

export type LibraryFolderSubscription = {
  hasMoreItems: boolean;
  loadMoreItems: () => Promise<boolean>;
  cleanup: () => void;
  getCurrentItems: () => Promise<ILibraryItem[]>;
};

export type LoadMoreItemsResult = {
  items: ILibraryItem[];
  hasMoreItems: boolean;
  loadMoreItems: () => Promise<LoadMoreItemsResult>;
} | void;

export class SDKLibraryFacade extends SDKFacade {
  private static _singleton: SDKLibraryFacade;
  constructor(sdk: MultiplatformSDKInstance) {
    super(sdk);
    SDKLibraryFacade._singleton = this;
    SDKLibraryFacade.resolveInstance(SDKLibraryFacade._singleton);
  }

  static override get singleton(): SDKLibraryFacade {
    return SDKLibraryFacade._singleton;
  }

  private static resolveInstance: (instance: SDKLibraryFacade) => void = () => {};
  private static instancePromise = new Promise<SDKLibraryFacade>(resolve => {
    SDKLibraryFacade.resolveInstance = resolve;
  });

  static get waitForInstance(): Promise<SDKLibraryFacade> {
    return SDKLibraryFacade.instancePromise;
  }

  public getItem = this.sdk.promisify(this.sdk.client.libraryService.getItem.bind(this.sdk.client.libraryService));

  public getChildrenItems = this.sdk.promisify(this.sdk.client.libraryService.getChildrenItems.bind(this.sdk.client.libraryService));

  public getCount = this.sdk.promisify(this.sdk.client.libraryService.getCount.bind(this.sdk.client.libraryService));

  public getRootFolder = this.sdk.promisify(this.sdk.client.libraryService.getRootFolder.bind(this.sdk.client.libraryService));

  public getTopItems = this.sdk.promisify(this.sdk.client.libraryService.getTopItems.bind(this.sdk.client.libraryService));

  public search = this.sdk.promisify(this.sdk.client.libraryService.search.bind(this.sdk.client.libraryService));

  public getTopLevelArchivedItems = this.sdk.promisify(this.sdk.client.libraryService.getTopLevelArchivedItems.bind(this.sdk.client.libraryService));

  public addDefaultLibraryItems = this.sdk.promisify(this.sdk.client.libraryService.addDefaultLibraryItems.bind(this.sdk.client.libraryService));

  public archiveItem = this.sdk.promisify(this.sdk.client.libraryService.archiveItem.bind(this.sdk.client.libraryService));

  public createFolder = this.sdk.promisify(this.sdk.client.libraryService.createFolder.bind(this.sdk.client.libraryService));

  public deleteAllArchivedItems = this.sdk.promisify(this.sdk.client.libraryService.deleteAllArchivedItems.bind(this.sdk.client.libraryService));

  public deleteItem = this.sdk.promisify(this.sdk.client.libraryService.deleteItem.bind(this.sdk.client.libraryService));

  public moveItem = this.sdk.promisify(this.sdk.client.libraryService.moveItem.bind(this.sdk.client.libraryService));

  public restoreItem = this.sdk.promisify(this.sdk.client.libraryService.restoreItem.bind(this.sdk.client.libraryService));

  public updateItem = this.sdk.promisify(this.sdk.client.libraryService.updateItem.bind(this.sdk.client.libraryService));

  public updateItemCoverImagePath = async (itemId: string, coverImagePath: string) => {
    const { UpdateLibraryItemParams } = this.sdk.sdkModule;
    await this.updateItem(itemId, new UpdateLibraryItemParams(null, coverImagePath, null));
  };

  public addFolderToRootFolder = async (folderName: string) => {
    const rootFolder = await this.getRootFolder();
    const newFolder = await this.createFolder(folderName, rootFolder);
    return newFolder;
  };

  // TODO(albertusdev): Consolidate this into ContentMetaType from ListenableContent
  private contentTypeToRecordType = (contentType: SDKContentType): RecordType => {
    const { ContentType } = this.sdk.sdkModule;
    switch (contentType) {
      case ContentType.SCAN:
        return RecordType.SCAN;
      case ContentType.EPUB:
        return RecordType.EPUB;
      case ContentType.PDF:
        return RecordType.PDF;
      case ContentType.DOCX:
        return RecordType.DEFAULT;
      case ContentType.HTML:
        return RecordType.WEB;
      case ContentType.TXT:
        return RecordType.TXT;
      default:
        return RecordType.DEFAULT;
    }
  };

  public isRoot = (folderId: string): boolean => {
    return folderId === 'LIBRARY_ROOT_FOLDER_ID';
  };

  private getAnalyticsProperties(analyticsProperties: BoundaryMap<string>) {
    return Object.fromEntries(
      analyticsProperties.entries().map(pair => {
        const key = pair.first;
        const value = pair.second;
        return [key, value];
      })
    );
  }

  public toILibraryItem = (item: SDKLibraryItemType): ILibraryItem => {
    const { LibraryItem, LibraryImportProgress, ItemStatus } = this.sdk.sdkModule;
    const base = {
      id: item.uri.id,
      dateAdded: new Date(item.createdAt),
      dateDeleted: item.removedAt ? new Date(item.removedAt) : undefined
    };
    if (item instanceof LibraryItem.Folder) {
      return {
        ...base,
        coverImagePath: '',
        imgAlt: '',
        title: item.title,
        progress: undefined,
        dateListened: undefined,
        recordType: RecordType.DEFAULT,
        isFolder: true,
        itemsCount: item.childrenCount,
        parentFolderId: this.isRoot(item.parentFolderId) ? '' : item.parentFolderId,
        analyticsProperties: this.getAnalyticsProperties(item.analyticsProperties)
      };
    }
    if (item instanceof LibraryItem.Content) {
      return {
        ...base,
        coverImagePath: item.coverImageUrl || '',
        imgAlt: item.title,
        title: item.title,
        progress: item.listenProgressPercent ? Math.round(item.listenProgressPercent * 100) : undefined,
        dateListened: item.lastListenedAt ? new Date(item.lastListenedAt) : undefined,
        recordType: this.contentTypeToRecordType(item.contentType),
        sourceURL: item.sourceStoredUrl ?? item.sourceUrl ?? '',
        wordCount: item.totalWords || 0,
        parentFolderId: this.isRoot(item.parentFolderId) ? '' : item.parentFolderId,
        isError: item.status === ItemStatus.FAILED,
        isProcessing: item.status === ItemStatus.PROCESSING,
        analyticsProperties: this.getAnalyticsProperties(item.analyticsProperties)
      };
    }
    if (item instanceof LibraryItem.DeviceLocalContent) {
      return {
        ...base,
        imgAlt: item.title,
        title: item.title,
        coverImagePath: item.coverImageUrl || '',
        progress: item.libraryImportProgress === LibraryImportProgress.Finished ? 100 : 0,
        dateListened: undefined,
        recordType: item.contentType ? this.contentTypeToRecordType(item.contentType) : RecordType.UNKNOWN,
        isLocalContent: true,
        parentFolderId: this.isRoot(item.parentFolderId) ? '' : item.parentFolderId,
        analyticsProperties: this.getAnalyticsProperties(item.analyticsProperties)
      };
    }
    return {
      ...base,
      coverImagePath: '',
      imgAlt: '',
      title: '',
      progress: undefined,
      dateListened: undefined,
      recordType: RecordType.UNKNOWN
    };
  };

  public subscribeToFolder = async (
    options: {
      folderId: string;
      foldersOnTop?: boolean;
    } & LibraryFilterAndSortOptions,
    callbacks: {
      onItemsChanged: (items: ILibraryItem[]) => void;
      onHasMoreItemsChanged: (hasMoreItems: boolean) => void;
      onError?: (error: Error) => void;
    }
  ): Promise<LibraryFolderSubscription> => {
    try {
      const { Result } = this.sdk.sdkModule;
      if (!options.foldersOnTop) {
        return this._subscribeToFolderDefault(options, callbacks);
      }

      const cleanupState = {
        isCleanedUp: false
      };

      const { FolderReferenceFactory, FilterAndSortOptions, FilterType } = this.sdk.sdkModule;

      const folderSubscription = await this.getChildrenItems(
        FolderReferenceFactory.fromId(options.folderId),
        new FilterAndSortOptions(FilterType.FOLDERS, this.translateSortBy(options.sortBy), this.translateSortOrder(options.sortOrder))
      );

      const filterType = options.filterType === LibraryFilterType.ANY ? LibraryFilterType.RECORDS : options.filterType;

      const fileSubscription = await this.getChildrenItems(
        FolderReferenceFactory.fromId(options.folderId),
        new FilterAndSortOptions(this.translateFilterType(filterType), this.translateSortBy(options.sortBy), this.translateSortOrder(options.sortOrder))
      );

      const updateCombinedItems = () => {
        callbacks.onItemsChanged([...folderItems, ...fileItems]);
      };

      let folderItems: ILibraryItem[] = [];
      let fileItems: ILibraryItem[] = [];
      let hasMoreFolders = true;
      let hasMoreFiles = true;

      // Set up the change listener
      const folderDestructor = folderSubscription.addChangeListener(result => {
        if (cleanupState.isCleanedUp) {
          logError(new Error('folderSubscription still active after cleanup'), ErrorSource.LIBRARY);
          return;
        }
        if (result instanceof Result.Success) {
          const view = (result as SDKResultType.Success<LiveQueryView<SDKLibraryItemType>>).value;
          view.getCurrentItems(items => {
            folderItems = items.map(this.toILibraryItem);
            updateCombinedItems();
          });
        } else {
          const error = (result as SDKResultType.Failure).error;
          if (callbacks.onError) {
            callbacks.onError(new Error(`Encountered error while subscribing to folder ${options.folderId}: ${error}`));
          }
        }
      });

      const fileDestructor = fileSubscription.addChangeListener(result => {
        if (cleanupState.isCleanedUp) {
          logError(new Error('fileSubscription still active after cleanup'), ErrorSource.LIBRARY);
          return;
        }
        if (result instanceof Result.Success) {
          const view = (result as SDKResultType.Success<LiveQueryView<SDKLibraryItemType>>).value;
          view.getCurrentItems(items => {
            fileItems = items.map(this.toILibraryItem);
            updateCombinedItems();
          });
        } else {
          const error = (result as SDKResultType.Failure).error;
          if (callbacks.onError) {
            callbacks.onError(new Error(`Encountered error while subscribing to folder ${options.folderId}: ${error}`));
          }
        }
      });

      const loadMoreItems = async () => {
        if (hasMoreFolders) {
          const folderResult = await this._loadMoreItems(folderSubscription);
          hasMoreFolders = folderResult;
          if (!hasMoreFolders) {
            // Start loading files when no more folders
            const fileResult = await this._loadMoreItems(fileSubscription);
            hasMoreFiles = fileResult;
          }
        } else if (hasMoreFiles) {
          const fileResult = await this._loadMoreItems(fileSubscription);
          hasMoreFiles = fileResult;
        }
        return hasMoreFolders || hasMoreFiles;
      };

      folderSubscription.getCurrentItems(items => {
        folderItems = items.map(this.toILibraryItem);
        fileSubscription.getCurrentItems(items => {
          fileItems = items.map(this.toILibraryItem);
          loadMoreItems().then(hasMoreItems => {
            callbacks.onHasMoreItemsChanged(hasMoreItems);
            updateCombinedItems();
          });
        });
      });

      return {
        hasMoreItems: false,
        cleanup: () => {
          cleanupState.isCleanedUp = true;
          folderDestructor();
          fileDestructor();
          folderSubscription.destroy();
          fileSubscription.destroy();
        },
        loadMoreItems,
        getCurrentItems: async () => {
          return new Promise<ILibraryItem[]>(resolve => {
            folderSubscription.getCurrentItems(items => {
              folderItems = items.map(this.toILibraryItem);
              fileSubscription.getCurrentItems(items => {
                fileItems = items.map(this.toILibraryItem);
                updateCombinedItems();
                resolve([...folderItems, ...fileItems]);
              });
            });
          });
        }
      };
    } catch (error) {
      if (callbacks.onError) {
        callbacks.onError(error as Error);
      }
      logError(new Error(`Encountered error while subscribing to folder ${options.folderId}: ${error}`), ErrorSource.LIBRARY);
      return {
        hasMoreItems: false,
        cleanup: () => {},
        loadMoreItems: () => Promise.resolve(false),
        getCurrentItems: () => Promise.resolve([])
      };
    }
  };

  private async _loadMoreItems(view: LiveQueryView<SDKLibraryItemType>): Promise<boolean> {
    const { Result } = this.sdk.sdkModule;
    return new Promise<boolean>(resolve => {
      view.getCurrentItems(previousItems => {
        view.loadMoreItems(result => {
          if (result instanceof Result.Success) {
            const _view = (result as SDKResultType.Success<LiveQueryView<SDKLibraryItemType>>).value;
            _view.getCurrentItems(items => {
              resolve(items.length > previousItems.length);
            });
          } else {
            resolve(false);
          }
        });
      });
    });
  }

  private _subscribeToFolderDefault = async (
    options: {
      folderId: string;
    } & LibraryFilterAndSortOptions,
    callbacks: {
      onItemsChanged: (items: ILibraryItem[]) => void;
      onHasMoreItemsChanged: (hasMoreItems: boolean) => void;
      onError?: (error: Error) => void;
    }
  ): Promise<LibraryFolderSubscription> => {
    try {
      const { Result } = this.sdk.sdkModule;

      const cleanupState = {
        isCleanedUp: false
      };
      const { FolderReferenceFactory, FilterAndSortOptions } = this.sdk.sdkModule;
      const view = await this.getChildrenItems(
        FolderReferenceFactory.fromId(options.folderId),
        new FilterAndSortOptions(this.translateFilterType(options.filterType), this.translateSortBy(options.sortBy), this.translateSortOrder(options.sortOrder))
      );

      // Set up the change listener
      const destructor = view.addChangeListener(result => {
        if (cleanupState.isCleanedUp) {
          logError(new Error('subscription default view still active after cleanup'), ErrorSource.LIBRARY);
          return;
        }
        if (result instanceof Result.Success) {
          const view = (result as SDKResultType.Success<LiveQueryView<SDKLibraryItemType>>).value;
          view.getCurrentItems(items => {
            callbacks.onItemsChanged(items.map(this.toILibraryItem));
          });
        } else {
          const error = (result as SDKResultType.Failure).error;
          if (callbacks.onError) {
            callbacks.onError(new Error(`Encountered error while subscribing to folder ${options.folderId}: ${error}`));
          }
        }
      });

      const loadMoreItems = () => {
        return new Promise<boolean>(resolve => {
          view.getCurrentItems(previousItems => {
            view.loadMoreItems(result => {
              if (result instanceof Result.Success) {
                const _view = (result as SDKResultType.Success<LiveQueryView<SDKLibraryItemType>>).value;
                _view.getCurrentItems(items => {
                  resolve(items.length > previousItems.length);
                });
              } else {
                resolve(false);
              }
            });
          });
        });
      };

      view.getCurrentItems(items => {
        loadMoreItems().then(hasMoreItems => {
          callbacks.onHasMoreItemsChanged(hasMoreItems);
          callbacks.onItemsChanged(items.map(this.toILibraryItem));
        });
      });

      return {
        hasMoreItems: false,
        cleanup: () => {
          cleanupState.isCleanedUp = true;
          destructor();
          view.destroy();
        },
        loadMoreItems,
        getCurrentItems: () => {
          return new Promise<ILibraryItem[]>(resolve => {
            view.getCurrentItems(items => {
              callbacks.onItemsChanged(items.map(this.toILibraryItem));
              resolve(items.map(this.toILibraryItem));
            });
          });
        }
      };
    } catch (error) {
      if (callbacks.onError) {
        callbacks.onError(error as Error);
      }
      logError(new Error(`Encountered error while subscribing to folder ${options.folderId}: ${error}`), ErrorSource.LIBRARY);
      return {
        hasMoreItems: false,
        cleanup: () => {},
        loadMoreItems: () => Promise.resolve(false),
        getCurrentItems: () => Promise.resolve([])
      };
    }
  };

  public getFolderItems = async (
    options: {
      folderId: string;
      foldersOnTop?: boolean;
    } & LibraryFilterAndSortOptions
  ) => {
    const { FolderReferenceFactory, FilterAndSortOptions, FilterType } = this.sdk.sdkModule;
    if (!options.foldersOnTop) {
      const view = await this.getChildrenItems(
        FolderReferenceFactory.fromId(options.folderId),
        new FilterAndSortOptions(this.translateFilterType(options.filterType), this.translateSortBy(options.sortBy), this.translateSortOrder(options.sortOrder))
      );
      return new Promise<ILibraryItem[]>(resolve => {
        view.getCurrentItems(items => {
          view.destroy();
          resolve(items.map(this.toILibraryItem));
        });
      });
    } else {
      const folderView = await this.getChildrenItems(
        FolderReferenceFactory.fromId(options.folderId),
        new FilterAndSortOptions(FilterType.FOLDERS, this.translateSortBy(options.sortBy), this.translateSortOrder(options.sortOrder))
      );
      const filterType = options.filterType === LibraryFilterType.ANY ? LibraryFilterType.RECORDS : options.filterType;
      const fileView = await this.getChildrenItems(
        FolderReferenceFactory.fromId(options.folderId),
        new FilterAndSortOptions(this.translateFilterType(filterType), this.translateSortBy(options.sortBy), this.translateSortOrder(options.sortOrder))
      );
      let folderItems: ILibraryItem[] = [];
      let fileItems: ILibraryItem[] = [];
      return new Promise<ILibraryItem[]>(resolve => {
        folderView.getCurrentItems(items => {
          folderItems = items.map(this.toILibraryItem);
          fileView.getCurrentItems(items => {
            fileItems = items.map(this.toILibraryItem);
            folderView.destroy();
            fileView.destroy();
            resolve([...folderItems, ...fileItems]);
          });
        });
      });
    }
  };

  public async shareItem(itemId: string): Promise<string> {
    const item = await this.getItem(itemId);
    if (!item) {
      throw new Error('Item not found');
    }

    const shareId = `${itemId}-${Date.now()}`;
    const db = getFirestore();

    try {
      await setDoc(doc(db, 'sharedItems', shareId), {
        itemId,
        createdAt: new Date(),
        type: 'share'
      });

      return `${getRequiredStringEnv('NEXT_PUBLIC_WEBAPP_URL')}/shared/${shareId}`;
    } catch (error) {
      logError(error as Error, ErrorSource.LIBRARY);
      throw new Error('Failed to share item');
    }
  }

  public getItemAndWaitUntilListenable = async (itemId: string): Promise<SDKLibraryItemType> => {
    const SDKContent = this.sdk.sdkModule.LibraryItem.Content;

    const item = await this.getItem(itemId);
    if (item instanceof SDKContent) {
      if (!item.isInListenableState) {
        await new Promise<void>(resolve => {
          const interval = setInterval(async () => {
            const updatedItem = (await this.getItem(itemId)) as SDKLibraryItemType.Content;
            if (updatedItem.isInListenableState) {
              clearInterval(interval);
              resolve();
            }
          }, 1000);
        });
      }
      return item;
    }
    return item;
  };

  // ContentSubType is actually a string, but for easier and consolidated tracking we created enums here.
  public webAppImportFlowToSDKContentSubType = (importFlow: WebAppImportType) => {
    switch (importFlow) {
      case WebAppImportType.FILE_UPLOAD:
        return SDKContentSubType.LOCAL_FILES;
      case WebAppImportType.WEB_LINK:
        return SDKContentSubType.WEB_LINK;
      case WebAppImportType.TEXT:
        return SDKContentSubType.TYPE_OR_PASTE_TEXT;
      case WebAppImportType.GOOGLE_DRIVE:
        return SDKContentSubType.GOOGLE_DRIVE;
      case WebAppImportType.DROPBOX:
        return SDKContentSubType.DROPBOX;
      case WebAppImportType.ONE_DRIVE:
        return SDKContentSubType.ONE_DRIVE;
      case WebAppImportType.CANVAS:
        return SDKContentSubType.CANVAS;
      case WebAppImportType.AI_STORY:
        return SDKContentSubType.AI_STORY;
      default:
        return unreachable(importFlow);
    }
  };

  public listenableContentToSDKContentType = (listenableContent: ListenableContent) => {
    const metaType = listenableContent.metaType;
    const { ContentType } = this.sdk.sdkModule;
    switch (metaType) {
      case ContentMetaType.PDF:
        return ContentType.PDF;
      case ContentMetaType.DOCX:
        return ContentType.DOCX;
      case ContentMetaType.TXT:
        return ContentType.TXT;
      case ContentMetaType.HTML:
        return ContentType.HTML;
      case ContentMetaType.EPUB:
        return ContentType.EPUB;
      case ContentMetaType.SCAN:
        return ContentType.SCAN;
      case ContentMetaType.UNKNOWN:
        logError(
          new Error(
            `webAppImportFlowToSDKContentType error: Falling back to SDK ContentType.TXT due to unknown meta type for listenableContent: ${listenableContent.constructor.name}`
          ),
          ErrorSource.FILE_IMPORT
        );
        return ContentType.TXT;
      default:
        return unreachable(metaType);
    }
  };

  public webAppImportFlowToSDKImportFlow = (webAppImportFlow: WebAppImportFlow) => {
    const { ImportFlow } = this.sdk.sdkModule;
    switch (webAppImportFlow) {
      case WebAppImportFlow.PLUS_BUTTON_MODAL:
        return ImportFlow.PlusButtonModal;
      case WebAppImportFlow.PLUS_BUTTON_MODAL_DRAG_AND_DROP:
        return ImportFlow.PlusButtonModalDragAndDrop;
      case WebAppImportFlow.PLUS_BUTTON_MODAL_SELECT_FILES:
        return ImportFlow.PlusButtonModalSelectFiles;
      case WebAppImportFlow.LIBRARY_DRAG_AND_DROP:
        return ImportFlow.LibraryDragAndDrop;
      case WebAppImportFlow.LIBRARY_SELECT_FILES:
        return ImportFlow.LibrarySelectFiles;
      case WebAppImportFlow.LIBRARY_SCREEN_SUGGESTIONS:
        return ImportFlow.LibraryScreenSuggestions;
      case WebAppImportFlow.PILL_PLAYER:
        return ImportFlow.PillPlayer;
      case WebAppImportFlow.LISTENING_SCREEN:
        return ImportFlow.ListeningScreen;
      default:
        return unreachable(webAppImportFlow);
    }
  };

  public searchItems = async (query: string): Promise<LoadMoreItemsResult> => {
    if (!query) return;
    const { SearchRequest, FilterType } = this.sdk.sdkModule;

    const bindLoadMoreItems = async (searchResults: SDKSearchResult) => {
      try {
        const result = await this.sdk.promisify(searchResults.loadMoreItems.bind(searchResults))();
        return {
          items: result.items.map(this.toILibraryItem),
          hasMoreItems: result.hasMore,
          loadMoreItems: () => bindLoadMoreItems(result)
        };
      } catch (error) {
        logError(error as Error, ErrorSource.LIBRARY);
        return;
      }
    };

    try {
      const searchResults = await this.search(new SearchRequest(query, [FilterType.RECORDS.Companion.all()]));
      return {
        items: searchResults.items.map(this.toILibraryItem),
        hasMoreItems: searchResults.hasMore,
        loadMoreItems: () => bindLoadMoreItems(searchResults)
      };
    } catch (error) {
      logError(error as Error, ErrorSource.LIBRARY);
      return;
    }
  };

  public getLibraryFolders = async (folderId: string): Promise<ILibraryItem[]> => {
    const { FolderReferenceFactory, FilterAndSortOptions, FilterType, SortOrder, SortBy } = this.sdk.sdkModule;
    const view = await this.getChildrenItems(
      FolderReferenceFactory.fromId(folderId),
      new FilterAndSortOptions(FilterType.FOLDERS, SortBy.ALPHABETICAL, SortOrder.ASC)
    );
    return new Promise(resolve => {
      view.getCurrentItems(items => {
        resolve(items.map(this.toILibraryItem));
        view.destroy();
      });
    });
  };

  public fetchTotalNumbersOfRecordItemsOwnedByCurrentUser = async () => {
    const { FilterType } = this.sdk.sdkModule;
    const count = await this.getCount(FilterType.RECORDS.Companion.all());
    return count;
  };

  private translateSortBy(sortBy: LibrarySortBy) {
    const { SortBy } = this.sdk.sdkModule;
    switch (sortBy) {
      case LibrarySortBy.DATE_ADDED:
        return SortBy.DATE_ADDED;
      case LibrarySortBy.ALPHABETICAL:
        return SortBy.ALPHABETICAL;
      case LibrarySortBy.RECENTLY_LISTENED:
        return SortBy.RECENTLY_LISTENED;
      default:
        return unreachable(sortBy);
    }
  }

  private translateSortOrder(sortOrder: LibrarySortOrder) {
    const { SortOrder } = this.sdk.sdkModule;
    switch (sortOrder) {
      case LibrarySortOrder.ASC:
        return SortOrder.ASC;
      case LibrarySortOrder.DESC:
        return SortOrder.DESC;
      default:
        return unreachable(sortOrder);
    }
  }

  private translateFilterType(filterType: LibraryFilterType) {
    const { RecordType, FilterType } = this.sdk.sdkModule;
    switch (filterType) {
      case LibraryFilterType.ANY:
        return FilterType.ANY;
      case LibraryFilterType.RECORDS:
        return FilterType.RECORDS.Companion.all();
      case LibraryFilterType.FOLDERS:
        return FilterType.FOLDERS;
      case LibraryFilterType.TEXT:
        return FilterType.RECORDS.Companion.ofTypes([RecordType.TXT]);
      case LibraryFilterType.PDF:
        return FilterType.RECORDS.Companion.ofTypes([RecordType.PDF]);
      case LibraryFilterType.EPUB:
        return FilterType.RECORDS.Companion.ofTypes([RecordType.EPUB]);
      case LibraryFilterType.WEB_LINKS:
        return FilterType.RECORDS.Companion.ofTypes([RecordType.WEB]);
      case LibraryFilterType.SCANS:
        return FilterType.RECORDS.Companion.ofTypes([RecordType.SCAN]);
      default:
        return unreachable(filterType);
    }
  }
}
