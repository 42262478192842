import type { User as FirebaseUser } from 'firebase/auth';

import { firebaseAuth } from 'lib/firebase/firebase.client';

import { setUser } from './setUser';

let cleanUpFunctions: (() => void)[] = [];

const cleanUp = () => {
  cleanUpFunctions.forEach(fn => fn());
  cleanUpFunctions = [];
};

export const initializeAuthStore = ({ setAudioServerUser }: { setAudioServerUser: (user: FirebaseUser) => void }) => {
  cleanUp();

  firebaseAuth.authStateReady().then(() => {
    setUser(firebaseAuth.currentUser);
  });

  const cleanup = firebaseAuth.onAuthStateChanged(user => {
    setUser(user);
    user && setAudioServerUser(user);
  });

  cleanUpFunctions.push(cleanup);

  return cleanup;
};
