import { IRecord } from 'interfaces';

import { WebAppImportType } from 'components/library/import/constants';
import { NonPersistentStore, createNonPersistentStore } from 'lib/zustand';
import { replaceUrlWithoutAnyEffect } from 'utils/navigation';
import { Nullable } from 'utils/types';

import { ContentMetaType, ContentType } from './content.types';

type ItemState = {
  record: IRecord | undefined;
  id: string | undefined;
  parentFolderId: string | undefined;
};

export abstract class BaseListenableContent {
  public readonly useItem: NonPersistentStore<ItemState>;

  abstract get title(): string;
  abstract get metaType(): ContentMetaType;
  abstract get contentType(): ContentType;
  abstract get wordCount(): Nullable<number>;

  // Import type is only defined for recently imported content.
  abstract get importType(): Nullable<WebAppImportType>;

  constructor() {
    this.useItem = createNonPersistentStore<ItemState>(
      () => ({
        record: undefined,
        id: undefined,
        parentFolderId: undefined
      }),
      { isListeningScreenStore: false }
    );

    this.registerBeforeUnloadHandler();
  }

  isPDF = (): boolean => this.metaType === ContentMetaType.PDF;

  updateTitle(title: string) {
    this.useItem.setState(state => {
      if (!state.record) return state;
      return {
        ...state,
        record: {
          ...state.record,
          title
        }
      };
    });
  }

  onItemIdReady(itemId: string): void {
    this.useItem.setState({ id: itemId });
    // Also start loading item record if it's not available yet
    if (this.useItem.getState().record) return;

    // We need to lazy load SDKLibraryFacade and libraryItemToIRecord to prevent circular import (it breaks storybook)
    import('modules/sdk/lib/facade/library').then(({ SDKLibraryFacade }) => {
      import('lib/speechify').then(({ libraryItemToIRecord }) => {
        SDKLibraryFacade.singleton.getItem(itemId).then(item => {
          const record = libraryItemToIRecord(item);
          const parentFolderId = SDKLibraryFacade.singleton.isRoot(item.parentFolderId) ? '' : item.parentFolderId;
          this.useItem.setState(state => {
            if (state.record) return { ...state, parentFolderId };
            return { ...state, record, parentFolderId };
          });
        });
      });
    });
  }

  subscribeToAutoUpdateUrlWhenItemIdReady() {
    const unsubscribe = this.useItem.subscribe(state => {
      if (!state.id) return;
      // replace client side URL with /item/{itemId}
      replaceUrlWithoutAnyEffect(`/item/${state.id}${state.parentFolderId ? `?folder=${state.parentFolderId}` : ''}`);
      unsubscribe();
    });
    return () => unsubscribe();
  }

  registerBeforeUnloadHandler() {
    if (this.useItem.getState().id) return;

    const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
      // Show a confirmation dialog showing data is not saved when upload is not finished yet.
      event.preventDefault();
    };
    window.addEventListener('beforeunload', beforeUnloadHandler);
    const unsubscribe = this.useItem.subscribe(state => {
      if (!state.id) return;
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      unsubscribe();
    });
  }
}
