export const ROOT_FOLDER_ID = 'root';

export enum LibraryShowFolderType {
  TOP = 'TOP',
  MIXED = 'MIXED'
}

export const SHOW_FOLDER_OPTIONS = [
  { value: LibraryShowFolderType.TOP, label: 'On Top' },
  { value: LibraryShowFolderType.MIXED, label: 'Mixed with Files' }
];
