import { emitUserUpdate } from './emitUserUpdate';
import { useAuthStore } from '..';

export const setHasSetDailyListeningGoal = (hasSetDailyListeningGoal: boolean) => {
  useAuthStore.setState(state => {
    if (!state.user) return state;
    return {
      user: {
        ...state.user,
        hasSetDailyListeningGoal
      }
    };
  });
  emitUserUpdate();
};
