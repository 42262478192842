import { clearBillingDashboardUrl } from './clearBillingDashboardUrl';
import { createAnonymousUser } from './createAnonymousUser';
import { deleteUser } from './deleteUser';
import * as loginActions from './login';
import { logout } from './logout';
import { sendPasswordResetEmail } from './sendPasswordResetEmail';
import { setBillingDashboardUrl } from './setBillingDashboardUrl';
import { setClickedStartListening } from './setClickedStartListening';
import { setEntitlements } from './setEntitlements';
import { setExtensionInstalled } from './setExtensionInstalled';
import { setExtensionPinned } from './setExtensionPinned';
import { setExtensionPlaybackSpeed } from './setExtensionPlaybackSpeed';
import { setExtensionSettings } from './setExtensionSettings';
import { setExtensionVoice } from './setExtensionVoice';
import { setFileUploaded } from './setFileUploaded';
import { setGoogleDocsRedirect } from './setGoogleDocsRedirect';
import { setHasSetDailyListeningGoal } from './setHasSetDailyListeningGoal';
import { setMobileAppInstalled } from './setMobileAppInstalled';
import { setSubscription } from './setSubscription';
import { setUser } from './setUser';
import * as signUpActions from './signUp';
import { updateProfile } from './updateProfile';

export const authStoreActions = {
  clearBillingDashboardUrl,
  createAnonymousUser,
  ...loginActions,
  logout,
  deleteUser,
  sendPasswordResetEmail,
  setBillingDashboardUrl,
  setClickedStartListening,
  setEntitlements,
  setExtensionInstalled,
  setExtensionPinned,
  setExtensionPlaybackSpeed,
  setExtensionSettings,
  setExtensionVoice,
  setFileUploaded,
  setGoogleDocsRedirect,
  setHasSetDailyListeningGoal,
  setMobileAppInstalled,
  setSubscription,
  setUser,
  ...signUpActions,
  updateProfile
};
