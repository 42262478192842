import { useAuthStore } from '..';

export const setGoogleDocsRedirect = (redirectGoogleDoc: boolean) => {
  useAuthStore.setState(state => {
    if (!state.user) return state;
    return {
      user: {
        ...state.user,
        redirectGoogleDoc
      }
    };
  });
};
