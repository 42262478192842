export enum TeamProductType {
  Freemium = 'teams.freemium',
  Premium = 'teams.premium'
}

export interface Team {
  adminEmail: string;
  adminUserId: string;
  displayName: string;
  expiresAt: number;
  iconUrl?: string;
  id: string;
  inviteUrl?: string;
  isAdmin: boolean;
  numberOfLicenses: number;
  productId: TeamProductType;
  creationTime: number;
}

export enum TeamMemberActionType {
  Remove = 'remove'
}
