import { sendPasswordResetEmail as firebaseSendPasswordResetEmail } from 'firebase/auth';

import { firebaseAuth } from 'lib/firebase/firebase.client';

import { createAuthStoreAsyncAction } from '../utils/createAuthStoreAsyncAction';

export const sendPasswordResetEmail = createAuthStoreAsyncAction(async (email: string) => {
  const response = await firebaseSendPasswordResetEmail(firebaseAuth, email);
  return response;
});
