import { EmailAuthProvider, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, createUserWithEmailAndPassword, linkWithCredential } from 'firebase/auth';

import { firebaseAuth } from 'lib/firebase/firebase.client';
import { SPAM_EMAIL_DOMAINS } from 'store/auth/constants';

import { SignUpActionProvider, createPopupBasedSignUpAction, createSignUpAction } from '../utils/createSignUpAction';

export const signUp = ({ email, password }: { email: string; password: string }) =>
  createSignUpAction(
    async () => {
      const emailDomain = email?.split('@')[1] || '';

      if (SPAM_EMAIL_DOMAINS.has(emailDomain.toLowerCase())) {
        throw { message: 'Invalid email address' };
      }

      const currentUser = firebaseAuth.currentUser;

      let result;
      if (currentUser && (currentUser.isAnonymous || !currentUser.email)) {
        // Convert anonymous user to permanent user
        const credential = EmailAuthProvider.credential(email, password);
        result = await linkWithCredential(currentUser, credential);
      } else {
        // Create new user
        result = await createUserWithEmailAndPassword(firebaseAuth, email, password);
      }
      return result;
    },
    { provider: SignUpActionProvider.EMAIL_PASSWORD }
  );

export const signUpWithApple = () => createPopupBasedSignUpAction(() => new OAuthProvider('apple.com'), SignUpActionProvider.APPLE);

export const signUpWithFacebook = () => createPopupBasedSignUpAction(() => new FacebookAuthProvider(), SignUpActionProvider.FACEBOOK);

export const signUpWithGoogle = () => createPopupBasedSignUpAction(() => new GoogleAuthProvider(), SignUpActionProvider.GOOGLE);
