import { logError } from 'lib/observability';

import { SubscriptionStoreState, useSubscriptionStore } from '../subscriptionStore';

export const getIsPremium = (state?: SubscriptionStoreState) => {
  const isPremium = (state ?? useSubscriptionStore.getState()).isPremium;

  if (isPremium === undefined) {
    logError(new Error('subscriptionStore.isPremium was accessed before we initialized it'));
    return false;
  }

  return isPremium;
};
