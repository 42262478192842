import { useEffect, useState } from 'react';

import { UpdatedState } from './UpdatedState';

export function useUpdatedState<T>(updatedState: UpdatedState<T>): T;
export function useUpdatedState<T>(updatedState: UpdatedState<T> | undefined): T | undefined;
export function useUpdatedState<T>(updatedState: UpdatedState<T> | undefined): T | undefined {
  const [state, setState] = useState(updatedState?.state);
  useEffect(() => {
    setState(updatedState?.state);
    updatedState?.subscribe(state => setState(state));
  }, [updatedState]);
  return state;
}
