import { ErrorSource } from 'config/constants/errors';
import { FeatureNameEnum, getFeatureVariant } from 'hooks/useFeatureFlags';
import { logError } from 'lib/observability';
import { useAuthStore } from 'modules/auth/store/authStore';
import { setEntitlements } from 'modules/auth/store/authStore/actions/setEntitlements';
import { getSubscriptionAndEntitlements } from 'modules/auth/store/authStore/utils/subscription';
import { SDKSubscriptionFacade } from 'modules/sdk/lib';

export const logHdWordsListened = async (numberOfWords: number) => {
  try {
    await SDKSubscriptionFacade.singleton.logHdWordsListened(numberOfWords);
  } catch (error) {
    const currentHdWordsLeft = useAuthStore.getState().user?.entitlements?.hdWordsLeft;
    // this can be hitting cache issues
    if (currentHdWordsLeft && currentHdWordsLeft <= 100) {
      const entitlements = useAuthStore.getState().user?.entitlements;
      if (!entitlements) return;

      setEntitlements(
        'hashCode' in entitlements
          ? entitlements.copy(undefined, Math.max(0, entitlements.hdWordsLeft - numberOfWords))
          : {
              ...entitlements,
              hdWordsLeft: Math.max(0, entitlements.hdWordsLeft - numberOfWords)
            }
      );
    } else {
      logError(error as Error, ErrorSource.SUBSCRIPTION);
    }
  }

  const isPlatformSdkSubscriptionsEnabled = await getFeatureVariant(FeatureNameEnum.PLATFORM_SDK_SUBSCRIPTIONS);
  if (!isPlatformSdkSubscriptionsEnabled) return;

  const { entitlements } = await getSubscriptionAndEntitlements();
  if (entitlements) setEntitlements(entitlements);
};
