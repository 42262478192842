export enum ErrorSource {
  AUTH = 'auth',
  EXTENSION_COMPATIBILITY = 'extension_compatibility',
  FILE_IMPORT = 'file_import',
  LIBRARY = 'library',
  PERSONAL_VOICES = 'personal_voices',
  PLAYBACK = 'playback',
  READING_EXPERIENCE = 'reading_experience',
  SUBSCRIPTION = 'subscription',
  SUMMARIZATION = 'summarization',
  TEXT_IMPORT = 'text_import',
  AI_IMPORT = 'ai_import',
  FILE_UPSELL = 'file_upsell'
}

export enum LibraryErrorOperation {
  ADD_INITIAL_FILES = 'add_initial_files',
  ADD_FOLDER = 'add_folder',
  ADD_TEXT_DOCUMENT = 'add_text_document',
  ADD_WEB_LINK = 'add_web_link',
  ARCHIVE_ITEM = 'archive_item',
  ARCHIVE_ITEMS = 'archive_items',
  DELETE_ITEM = 'delete_item',
  DELETE_ALL_ARCHIVED_ITEMS = 'delete_all_archived_items',
  DELETE_ARCHIVED_ITEM = 'delete_archived_item',
  DOWNLOAD_PAGE = 'download_page',
  FETCH_ITEM = 'fetch_item',
  FETCH_PAGES = 'fetch_pages',
  FETCH_SHARED_ITEM = 'fetch_shared_item',
  MOVE_ITEM = 'move_item',
  MOVE_ITEMS = 'move_items',
  RESTORE_ITEM = 'restore_item',
  SEARCH = 'search',
  SET_NAME = 'set_name',
  SET_PROGRESS = 'set_progress',
  SUBSCRIBE_FOR_UPDATES = 'subscribe_for_updates'
}
