/**
 * Need this separately from Kotlin Destructible because that one also has 'readonly __doNotUseIt: __doNotImplementIt;' property.
 */
export type Destructible = {
  destroy(): void;
};

export abstract class BaseDestructible implements Destructible {
  private _cleanupFns: (() => void)[] = [];
  protected onDestroy(fn: () => void) {
    this._cleanupFns.push(fn);
  }
  destroy() {
    this._cleanupFns.reverse().forEach(fn => fn());
  }
}
