import DocsIcon from 'assets/icons/docs';
import { AllFilesIcon, EPUBIcon, PDFsIcon, ScansIcon, TextDocsIcon, WebLinksIcon } from 'assets/icons/library/FilterIcons';

import { SelectOption } from '../types/item';

export enum LibraryFilterType {
  ANY = 'ANY',
  RECORDS = 'RECORDS',
  FOLDERS = 'FOLDERS',
  // TOP_LEVEL_ARCHIVED_ITEMS = 'TOP_LEVEL_ARCHIVED_ITEMS',
  // LISTENING_IN_PROGRESS_AND_NOT_STARTED = 'LISTENING_IN_PROGRESS_AND_NOT_STARTED',
  // LISTENING_IN_PROGRESS = 'LISTENING_IN_PROGRESS',
  // LISTENING_NOT_STARTED = 'LISTENING_NOT_STARTED',
  // LISTENING_FINISHED = 'LISTENING_FINISHED',
  // AVAILABLE_OFFLINE = 'AVAILABLE_OFFLINE'
  TEXT = 'TEXT',
  PDF = 'PDF',
  EPUB = 'EPUB',
  WEB_LINKS = 'WEB_LINKS',
  SCANS = 'SCANS'
}

export enum LibraryIntegrationFilterType {
  GDOC = 'GDOC',
  ANY = 'ANY',
  TEXT = 'TEXT',
  PDF = 'PDF',
  EPUB = 'EPUB',
  WEB_LINKS = 'WEB_LINKS',
  SCANS = 'SCANS'
}

export const FILTER_OPTIONS: SelectOption<LibraryFilterType>[] = [
  { value: LibraryFilterType.ANY, label: 'All Files', icon: <AllFilesIcon className="text-icn-txt-sec" /> },
  { value: LibraryFilterType.PDF, label: 'PDFs', icon: <PDFsIcon className="text-icn-txt-sec" /> },
  { value: LibraryFilterType.TEXT, label: 'Text Docs', icon: <TextDocsIcon className="text-icn-txt-sec" /> },
  { value: LibraryFilterType.EPUB, label: 'ePubs', icon: <EPUBIcon className="text-icn-txt-sec" /> },
  { value: LibraryFilterType.WEB_LINKS, label: 'Web Links', icon: <WebLinksIcon className="text-icn-txt-sec" /> },
  { value: LibraryFilterType.SCANS, label: 'Scans', icon: <ScansIcon className="text-icn-txt-sec" /> }
];

export const INTEGRATION_FILTER_OPTIONS: SelectOption<LibraryIntegrationFilterType>[] = [
  { value: LibraryIntegrationFilterType.ANY, label: 'All Files', icon: <AllFilesIcon className="text-icn-txt-sec" /> },
  { value: LibraryIntegrationFilterType.PDF, label: 'PDFs', icon: <PDFsIcon className="text-icn-txt-sec" /> },
  { value: LibraryIntegrationFilterType.TEXT, label: 'Text Docs', icon: <TextDocsIcon className="text-icn-txt-sec" /> },
  { value: LibraryIntegrationFilterType.GDOC, label: 'Google Docs', icon: <DocsIcon className="text-icn-txt-sec" /> },
  { value: LibraryIntegrationFilterType.EPUB, label: 'ePubs', icon: <EPUBIcon className="text-icn-txt-sec" /> },
  { value: LibraryIntegrationFilterType.WEB_LINKS, label: 'Web Links', icon: <WebLinksIcon className="text-icn-txt-sec" /> },
  { value: LibraryIntegrationFilterType.SCANS, label: 'Scans', icon: <ScansIcon className="text-icn-txt-sec" /> }
];

export const INTEGRATION_FILTER_OPTIONS_MAP_MIME_TYPES: Record<LibraryIntegrationFilterType, string | undefined> = {
  [LibraryIntegrationFilterType.ANY]: undefined,
  [LibraryIntegrationFilterType.PDF]: 'application/pdf',
  [LibraryIntegrationFilterType.TEXT]: 'text/plain',
  [LibraryIntegrationFilterType.GDOC]: 'application/vnd.google-apps.document',
  [LibraryIntegrationFilterType.EPUB]: 'application/epub+zip',
  [LibraryIntegrationFilterType.WEB_LINKS]: 'text/html',
  [LibraryIntegrationFilterType.SCANS]: 'image/jpeg'
};

export const INTEGRATION_FILTER_OPTIONS_MAP_MIME_TYPES_REVERSED: Record<string, LibraryIntegrationFilterType | undefined> = {
  [INTEGRATION_FILTER_OPTIONS_MAP_MIME_TYPES[LibraryIntegrationFilterType.ANY] ?? '']: LibraryIntegrationFilterType.ANY,
  [INTEGRATION_FILTER_OPTIONS_MAP_MIME_TYPES[LibraryIntegrationFilterType.PDF] ?? '']: LibraryIntegrationFilterType.PDF,
  [INTEGRATION_FILTER_OPTIONS_MAP_MIME_TYPES[LibraryIntegrationFilterType.TEXT] ?? '']: LibraryIntegrationFilterType.TEXT,
  [INTEGRATION_FILTER_OPTIONS_MAP_MIME_TYPES[LibraryIntegrationFilterType.GDOC] ?? '']: LibraryIntegrationFilterType.GDOC,
  [INTEGRATION_FILTER_OPTIONS_MAP_MIME_TYPES[LibraryIntegrationFilterType.EPUB] ?? '']: LibraryIntegrationFilterType.EPUB,
  [INTEGRATION_FILTER_OPTIONS_MAP_MIME_TYPES[LibraryIntegrationFilterType.WEB_LINKS] ?? '']: LibraryIntegrationFilterType.WEB_LINKS,
  [INTEGRATION_FILTER_OPTIONS_MAP_MIME_TYPES[LibraryIntegrationFilterType.SCANS] ?? '']: LibraryIntegrationFilterType.SCANS
};
