import { createNonPersistentStore } from 'lib/zustand';
import { SkipSettingsFacade } from 'modules/sdk/lib/facade/bookReader/SkipSettingsFacade';
import { ReaderFacade } from 'modules/sdk/lib/facade/ReaderFacade';

type SettingsStore = {
  skipSettingsFacade: SkipSettingsFacade | undefined;
  readerFacade: ReaderFacade | undefined;
};

export const useSettings = createNonPersistentStore<SettingsStore>(() => ({ skipSettingsFacade: undefined, readerFacade: undefined }), {
  isListeningScreenStore: true
});
